import { persistDevParam } from "@/helpers/devMode";

import adminRoutes from "@/router/admin";
import authRoutes from "@/router/auth";
import baseRoutes from "@/router/base";
import communityRoutes from "@/router/community";
import loanableRoutes from "@/router/loanables";
import loanRoutes from "@/router/loans";
import profileRoutes from "@/router/profile";
import registerRoutes from "@/router/register";

import NotFound from "@/views/NotFound.vue";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  ...adminRoutes,
  ...authRoutes,
  ...baseRoutes,
  ...communityRoutes,
  ...loanableRoutes,
  ...loanRoutes,
  ...profileRoutes,
  ...registerRoutes,
  {
    path: "*",
    component: NotFound,
    meta: {},
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // If we navigate using back, forward.
    if (savedPosition) {
      // This doesn't work too well when we load new data. Ideally this would hook
      // up somehow with the data route guards, or the form mixin, to only navigate once
      // data is loaded
      return savedPosition;
    }

    // If we navigate to a new page, go to top
    if (to.matched.length >= 1 && to.path !== from.path) {
      // If we navigate from to the same route, but with a different path param, check if
      // the route config wants to keep the existing scrolled position
      if (to.matched[0].path === from.matched[0]?.path && to.matched[0].meta.keepScroll) {
        return undefined;
      }

      return { x: 0, y: 0 };
    }

    // If we stay on the same page, do not navigate. (e.g. if we change the query params)
    return undefined;
  },
});

let resolveNewRouteRendered = null;

// Suppress errors when redirecting in navigation guards
if (process.env.NODE_ENV === "development") {
  const originalPush = router.push;
  router.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
  };

  const originalReplace = router.replace;
  router.replace = function replace(location) {
    return originalReplace.call(this, location).catch((err) => err);
  };
}

router.beforeEach((to, from, next) => {
  // function returning a promise that resolves once the new route is rendered
  const go = async () => {
    return new Promise((resolve) => {
      resolveNewRouteRendered = resolve;
      // This calls next, which continues the navigation to the next page
      persistDevParam(to, from, next);
    });
  };

  // If fancy new api is not available or we do not change page, do plain old transition.
  if (!document.startViewTransition || to.path === from.path) {
    go();
    return;
  }

  // Start fancy transition. See https://developer.chrome.com/docs/web-platform/view-transitions/
  document.startViewTransition(go);
});

router.afterEach(() => {
  if (resolveNewRouteRendered) {
    // Route is fully rendered only on tick following VueRouter's afterEach hook. This
    // is similar to how callbacks passed to the `next` param in components'
    // `beforeRouteEnter` hook are called.
    router.app.$nextTick(resolveNewRouteRendered);
  }
});

export default router;
