var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"loanable-details-box"},[_c('b-card',{attrs:{"no-body":""}},[(_vm.loanable && _vm.loanable.images && _vm.loanable.images.length > 0)?_c('image-carousel',{staticClass:"card-img-top",attrs:{"images":_vm.loanable.images,"aspect-ratio":"16 / 10","preferred-sizes":['loan', 'thumbnail', 'original']}}):_vm._e(),_c('b-card-body',[_c('b-card-title',{class:{ archived: !!_vm.loanable.deleted_at, 'mb-2': !_vm.loanable.deleted_at }},[(_vm.showAdminLinks && _vm.showLoanableLink)?_c('router-link',{attrs:{"to":`/loanables/${_vm.loanable.id}`}},[_vm._v(_vm._s(_vm.loanable.name))]):_c('span',[_vm._v(_vm._s(_vm.loanable.name))])],1),_vm._t("subtitle"),(_vm.loanable.details)?_c('toggle-link',{staticClass:"d-block mb-3 text-muted",attrs:{"collapse-id":"loanable-details"}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t("loanable.sidebar.details")))+" "+_vm._s(_vm.prettyType))]):_vm._e(),_c('b-card-text',[(_vm.loanable.details)?_c('b-collapse',{attrs:{"id":"loanable-details","role":"tabpanel","accordion":"loanable-details"}},[_c('description-list',{attrs:{"loanable":_vm.loanable,"details-only":""}})],1):_vm._e(),_c('hr'),_c('dl',{staticClass:"sidebar-list"},[(_vm.loanable.library)?[_c('dt',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$t("loanable.fields.library"))))]),_c('dd',[_c('library-blurb',{attrs:{"library":_vm.loanable.library}})],1)]:_vm._e(),_c('dt',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$t("loanable.resource_people"))))]),_c('dd',[_c('user-role-list',{attrs:{"user-roles":_vm.loanable.merged_user_roles,"show-admin-links":_vm.showAdminLinks,"show-contacts-only":_vm.showContactsOnly}})],1)],2),_vm._t("center"),(
            _vm.loanable.comments ||
            _vm.loanable.instructions ||
            _vm.loanable.location_description ||
            _vm.loanable.position
          )?[_c('hr'),_c('dl',{staticClass:"mb-0 sidebar-list"},[(_vm.loanable.comments)?[_c('dt',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$t("loanable.fields.comments"))))]),_c('dd',{staticClass:"user_text"},[_vm._v(_vm._s(_vm.loanable.comments))])]:_vm._e(),(
                _vm.showAllInstructions && _vm.showInstructions && _vm.loanable.trusted_borrower_instructions
              )?[_c('dt',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$t("loanable.fields.trusted_borrower_instructions"))))]),_c('dd',{staticClass:"user_text"},[_vm._v(_vm._s(_vm.loanable.trusted_borrower_instructions))])]:_vm._e(),(_vm.mainInstructions)?[_c('dt',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$t("loanable.fields.instructions"))))]),_c('dd',{staticClass:"user_text"},[_vm._v(_vm._s(_vm.mainInstructions))])]:_vm._e(),(_vm.loanable.location_description || _vm.loanable.position)?_c('dt',[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t("loanable.fields.position")))+" ")]):_vm._e(),_c('dd',[_c('div',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.loanable.location_description))]),(_vm.loanable.position)?_c('forms-map-input',{attrs:{"value":_vm.loanable.position,"loanable":_vm.loanable,"disabled":"","bounded":"","allow-fullscreen":""}}):_vm._e()],1)],2)]:_vm._e()],2)],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }