var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loaded && _vm.loading)?_c('layout-loading'):(_vm.loaded)?_c('div',[(_vm.library.id)?_c('h1',[_vm._v(_vm._s(_vm.library.name))]):_c('h1',{staticClass:"font-italic"},[_vm._v("Nouvelle flotte")]),_c('loanable-user-role-form',{ref:"editRoleModal",attrs:{"role":_vm.editedRole,"library":_vm.library},on:{"saved":function($event){return _vm.$refs.userRolesTable.refresh()}}}),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ passes, changed }){return [_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"8"}},[_c('forms-validated-input',{attrs:{"name":"name","rules":{ required: true },"label":_vm._f("capitalize")(_vm.$t('fields.name')),"type":"text"},model:{value:(_vm.library.name),callback:function ($$v) {_vm.$set(_vm.library, "name", $$v)},expression:"library.name"}}),_c('forms-validated-input',{attrs:{"name":"phone_number","label":_vm._f("capitalize")(_vm.$t('fields.phone_number')),"type":"text","mask":"(###) ###-####"},model:{value:(_vm.library.phone_number),callback:function ($$v) {_vm.$set(_vm.library, "phone_number", $$v)},expression:"library.phone_number"}}),(_vm.isNew)?_c('forms-validated-input',{attrs:{"type":"relation","name":"owner_user_id","query":_vm.roleUserQuery,"value":_vm.library.owner_user_id,"object-value":_vm.editedRole.user,"rules":{ required: true },"label":_vm._f("capitalize")(_vm.$t('roles.owner')),"mode":"eager"},on:{"relation":function($event){_vm.editedRole.user = $event;
              _vm.library.owner_user_id = _vm.editedRole.user.id;}}}):_vm._e()],1),_c('b-col',{attrs:{"md":"4"}},[_c('forms-validated-input',{attrs:{"type":"image","name":"avatar","label":_vm.capitalize(_vm.$t('fields.avatar')),"preview-aspect-ratio":"1 / 1"},model:{value:(_vm.library.avatar),callback:function ($$v) {_vm.$set(_vm.library, "avatar", $$v)},expression:"library.avatar"}})],1)],1),(!_vm.isNew)?[_c('paginated-table',{ref:"userRolesTable",attrs:{"label":"Droits de gestion","data-dusk":"roles-table","endpoint":`/libraries/${_vm.library.id}/roles`,"columns":_vm.userRolesColumns,"show-generate-csv":false},scopedSlots:_vm._u([{key:"head-buttons",fn:function(){return [(_vm.canManageRoles)?_c('icon-button',{attrs:{"role":"add","data-dusk":"add-role-button"},on:{"click":_vm.addRole}},[_vm._v("Accorder un rôle")]):_vm._e()]},proxy:true},{key:"cell(actions)",fn:function({ item }){return [(_vm.canEditRole)?_c('icon-button',{attrs:{"role":"edit","size":"sm"},on:{"click":() => _vm.editRole(item)}}):_vm._e(),(_vm.canRemoveRole(item))?_c('validated-button',{attrs:{"action":async () => _vm.removeUserRole(item),"question":`Êtes-vous sûr de vouloir retirer ${item.user.full_name}?`,"label":"retirer","icon":"trash","size":"sm","variant":"ghost-danger","show-button-label":false}}):_vm._e()]}}],null,true)}),_c('paginated-table',{ref:"communitiesTable",attrs:{"data-dusk":"communities-table","label":"Partage","endpoint":`/libraries/${_vm.library.id}/communities`,"columns":_vm.communitiesColumns,"show-generate-csv":false},on:{"row-clicked":(item) => (_vm.communityLink(item) ? _vm.$router.push(_vm.communityLink(item)) : null)},scopedSlots:_vm._u([{key:"head-buttons",fn:function(){return [(_vm.canManageCommunities)?_c('relation-input',{attrs:{"placeholder":"Ajouter une communauté","value":null,"reset-after-select":"","min-search-length":0,"query":{
                slug: 'communities',
                value: 'id',
                text: 'name',
                params: {
                  for: 'profile',
                  '!libraries.id': _vm.library.id,
                },
              },"name":"add-community","data-dusk":"add-community-input"},on:{"input":_vm.addCommunity}}):_vm._e()]},proxy:true},{key:"cell(actions)",fn:function({ item }){return [(_vm.canManageCommunities)?_c('validated-button',{attrs:{"action":async () => _vm.removeCommunity(item),"question":`Êtes-vous sûr de vouloir arrêter le partage dans ${item.name}?`,"label":"retirer","icon":"trash","variant":"ghost-danger","size":"sm","show-button-label":false}}):_c('div')]}}],null,true)}),_c('paginated-table',{ref:"loanablesTable",attrs:{"data-dusk":"loanables-table","label":"véhicules","endpoint":`/libraries/${_vm.library.id}/loanables`,"columns":_vm.loanablesColumns,"show-generate-csv":false},on:{"row-clicked":(item) => _vm.$router.push(`/loanables/${item.id}`)},scopedSlots:_vm._u([{key:"head-buttons",fn:function(){return [(_vm.canManageLoanables)?_c('relation-input',{ref:"loanableInput",attrs:{"placeholder":"Ajouter un véhicule","value":null,"reset-after-select":"","min-search-length":0,"query":{
                slug: 'loanables',
                value: 'id',
                text: 'name',
                params: {
                  for: 'owner',
                  '!library.id': _vm.library.id,
                  fields:
                    'merged_user_roles.role,merged_user_roles.user.id,merged_user_roles.user.full_name',
                },
              },"name":"add-loanable","data-dusk":"add-loanable-input"},on:{"input":_vm.addLoanable}}):_vm._e()]},proxy:true},{key:"cell(actions)",fn:function({ item }){return [_c('icon-button',{attrs:{"role":"edit","size":"sm","to":_vm.loanableLink(item),"data-dusk":"edit-loanable-button"}}),(_vm.canManageLoanables)?_c('validated-button',{attrs:{"action":async () => _vm.removeLoanable(item),"question":`Êtes-vous sûr de vouloir arrêter de retirer ${item.name} de la flotte?`,"label":"retirer","icon":"trash","variant":"ghost-danger","show-button-label":false,"size":"sm"}}):_c('div')]}}],null,true)})]:_vm._e(),_c('form-buttons',[_c('icon-button',{attrs:{"role":"save","data-dusk":"save-fleet-button","onclick":async () => passes(_vm.save),"disabled":_vm.loading || !changed}},[_vm._v("Enregistrer")])],1)],2)]}}])})],1):_c('div',[_vm._v("Erreur de chargement")])
}
var staticRenderFns = []

export { render, staticRenderFns }