<script>
import ValidatedButton from "@/components/Admin/ValidatedButton.vue";
import RelationInput from "@/components/Forms/RelationInput.vue";
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import LoanableUserRoleForm from "@/components/Loanable/UserRoleForm.vue";
import FormButtons from "@/components/shared/FormButtons.vue";
import IconButton from "@/components/shared/IconButton.vue";
import PaginatedTable from "@/components/shared/PaginatedTable.vue";
import { Column } from "@/components/shared/PaginatedTableColumns";
import { capitalize } from "@/helpers/filters";
import { communityLink, loanableLink, userLink } from "@/helpers/links";
import { getOwner } from "@/helpers/permissions/loanables";
import { isGlobalAdmin } from "@/helpers/permissions/users";
import libraries from "@/locales/models/libraries";
import { del, get, post, put } from "@/requests/server";

export default {
  name: "LibraryForm",
  components: {
    LoanableUserRoleForm,
    RelationInput,
    IconButton,
    FormButtons,
    PaginatedTable,
    ValidatedButton,
    FormsValidatedInput,
  },
  props: {
    id: {
      type: [String, Number],
      required: false,
      default: null,
    },
  },
  i18n: {
    messages: {
      fr: {
        ...libraries.fr,
      },
    },
  },
  data() {
    return {
      library: {},
      loading: false,
      loaded: !this.id || this.id === "new", // if no id, we are loaded
      editedRole: {
        user: null,
        role: null,
      },
      userRolesColumns: [
        Column.withRelation(
          "user.full_name",
          "Membre",
          {
            relation: "users",
            label: "full_name",
            field: "id",
            params: {
              "library_roles.ressource_id": this.id,
            },
          },
          "user_id",
          {
            urlFct: (userRole) => userLink(userRole.user),
          }
        ),
        Column.withSelect("role", "Rôle", [
          { value: null, label: "Tous" },
          { value: "owner", label: this.$t("roles.owner") },
          { value: "manager", label: this.$t("roles.manager") },
        ]),
        Column.withRelation(
          "granted_by_user.full_name",
          "Accordé par",
          {
            relation: "users",
            label: "full_name",
            field: "id",
            params: {
              for: "profile",
            },
          },
          "granted_by_user_id",
          {
            urlFct: (userRole) => userLink(userRole.granted_by_user),
          }
        ),
        new Column("pays_loan_price", "Paie le tarif", "boolean"),
        new Column("pays_loan_insurance", "Paie l'assurance", "boolean"),
        new Column("show_as_contact", "Contact", "boolean"),
      ],
      communitiesColumns: [
        Column.withRelation(
          "name",
          "Communauté",
          {
            relation: "communities",
            label: "name",
            field: "id",
            params: { for: "profile", "libraries.id": this.id },
          },
          "id",
          {
            urlFct: communityLink,
          }
        ),
      ],
      loanablesColumns: [
        Column.withRelation(
          "name",
          "Nom",
          {
            relation: "loanables",
            label: "name",
            field: "id",
            params: { for: "profile", "library.id": this.id },
          },
          "id",
          { urlFct: (loanable) => `/loanables/${loanable.id}` }
        ),
        Column.withSelect("type", "Type", [
          { value: null, label: "Tous" },
          { value: "car", label: "Auto" },
          { value: "trailer", label: "Remorque" },
          { value: "bike", label: "Vélo" },
        ]),
        Column.withSelect("availability_status", "Statut d'affichage", [
          { value: null, label: "Tous" },
          {
            value: "no_approved_communities",
            label: "Propriétaire sans communauté",
            variant: "warning",
          },
          { value: "wrong_type", label: "Type non partagé", variant: "danger" },
          { value: "no_availabilities", label: "Aucune disponibilité", variant: "warning" },
          { value: "unpublished", label: "Non publié", variant: "secondary" },
          { value: "has_availabilities", label: "Affiché", variant: "success" },
        ]),
      ],
    };
  },
  computed: {
    isNew() {
      return !this.library.id && (!this.id || this.id === "new");
    },
    user() {
      return this.$store.state.user;
    },
    isGlobalAdmin() {
      return isGlobalAdmin(this.user);
    },
    libraryOwner() {
      return this.library.user_roles?.find((userRole) => userRole.role === "owner")?.user;
    },
    isLibraryOwner() {
      return this.libraryOwner.id === this.user.id;
    },
    canManageCommunities() {
      return this.isGlobalAdmin || this.isLibraryOwner;
    },
    canManageRoles() {
      return this.isGlobalAdmin || this.isLibraryOwner;
    },
    canManageLoanables() {
      return this.isGlobalAdmin || this.isLibraryOwner;
    },
    canEditRole() {
      // Not allowing editing self role, since there are no additional options to edit currently.
      // We allow removing self role (see method canRemoveRole).
      return this.isGlobalAdmin || this.isLibraryOwner;
    },
    canEditPaidAmounts() {
      return this.isGlobalAdmin || this.isLibraryOwner;
    },
    canChangeOwner() {
      return this.isGlobalAdmin;
    },
    roleUserQuery() {
      const query = {
        slug: "users",
        value: "id",
        text: "full_name",
        details: "email",
        params: {
          fields: "id,full_name",
        },
      };

      if (this.library?.id) {
        // For existing libraries, do not return users which already have a role in the library
        query.params["!library_roles.ressource_id"] = this.id;
      }
      return query;
    },
  },
  mounted() {
    if (!this.loaded) {
      this.loadLibrary();
    }
  },
  methods: {
    loanableLink,
    communityLink,
    capitalize,
    canRemoveRole(role) {
      if (role.role === "owner") {
        // Must always have an owner.
        return false;
      }
      return this.isGlobalAdmin || this.isLibraryOwner || role.user.id === this.user.id;
    },
    async loadLibrary() {
      this.loading = true;
      const { data } = await get("/libraries/" + this.id, {
        notifications: { action: "chargement de la flotte" },
        cleanupCallback: () => (this.loading = false),
      });
      this.loaded = true;
      this.library = data;
    },
    addRole() {
      this.editedRole = {
        id: null,
        user: null,
        role: "manager",
        pays_loan_price: true,
        pays_loan_insurance: true,
      };
      this.$refs.editRoleModal.show();
    },
    editRole(userRole) {
      this.editedRole = {
        ...userRole,
      };

      this.$refs.editRoleModal.show();
    },
    async removeUserRole(userRole) {
      await del(`/loanables/roles/${userRole.id}`, {
        notifications: { action: "suppression du rôle utilisateur" },
      });
      this.$refs.userRolesTable.refresh();
    },
    async save() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      if (this.isNew) {
        const { data } = await post("/libraries", this.library, {
          notifications: { action: "Enregistrement de la flotte", onSuccess: true },
          cleanupCallback: () => (this.loading = false),
        });
        this.library = data;
        this.editedRole.user = null;
        this.$router.replace(this.$route.fullPath.replace("new", data.id));
      } else {
        const { data } = await put("/libraries/" + this.id, this.library, {
          notifications: { action: "Enregistrement de la flotte", onSuccess: true },
          cleanupCallback: () => (this.loading = false),
        });
        this.library = data;
      }
      this.$refs.observer.reset();
    },
    async addCommunity(community) {
      const { data } = await post(
        `/libraries/${this.id}/communities/${community.id}`,
        {},
        {
          notifications: { action: "ajout de la communauté", onSuccess: true },
        }
      );
      this.library = data;
      this.$refs.communitiesTable.refresh();
    },
    async removeCommunity(community) {
      const { data } = await del(`/libraries/${this.id}/communities/${community.id}`, {
        notifications: { action: "retrait de la communauté", onSuccess: true },
      });
      this.library = data;
      this.$refs.communitiesTable.refresh();
    },
    async addLoanable(loanable) {
      if (!loanable) {
        return;
      }
      const loanableOwnerUser = getOwner(loanable);

      if (loanableOwnerUser.id !== this.libraryOwner.id) {
        const confirm = await this.$bvModal.msgBoxConfirm(
          `Êtes vous sûr de vouloir changer le propriétaire du véhicule '${loanable.name}' de ${loanableOwnerUser.full_name} à ${this.libraryOwner.full_name} (propriétaire de cette flotte)?`
        );
        if (!confirm) {
          return;
        }
      }

      const { data } = await post(
        `/libraries/${this.id}/loanables/${loanable.id}`,
        {},
        {
          notifications: { action: "ajout du véhicule", onSuccess: true },
        }
      );
      this.library = data;
      this.$refs.loanableInput.clearSearch();
      this.$refs.loanablesTable.refresh();
    },
    async removeLoanable(loanable) {
      const { data } = await del(`/libraries/${this.id}/loanables/${loanable.id}`, {
        notifications: { action: "retrait du véhicule", onSuccess: true },
      });
      this.library = data;
      this.$refs.loanablesTable.refresh();
    },
  },
};
</script>

<template>
  <layout-loading v-if="!loaded && loading" />
  <div v-else-if="loaded">
    <h1 v-if="library.id">{{ library.name }}</h1>
    <h1 v-else class="font-italic">Nouvelle flotte</h1>

    <loanable-user-role-form
      ref="editRoleModal"
      :role="editedRole"
      :library="library"
      @saved="$refs.userRolesTable.refresh()"
    />
    <validation-observer ref="observer" v-slot="{ passes, changed }">
      <b-form>
        <b-row>
          <b-col md="8">
            <forms-validated-input
              v-model="library.name"
              name="name"
              :rules="{ required: true }"
              :label="$t('fields.name') | capitalize"
              type="text"
            />
            <forms-validated-input
              v-model="library.phone_number"
              name="phone_number"
              :label="$t('fields.phone_number') | capitalize"
              type="text"
              mask="(###) ###-####"
            />
            <forms-validated-input
              v-if="isNew"
              type="relation"
              name="owner_user_id"
              :query="roleUserQuery"
              :value="library.owner_user_id"
              :object-value="editedRole.user"
              :rules="{ required: true }"
              :label="$t('roles.owner') | capitalize"
              mode="eager"
              @relation="
                editedRole.user = $event;
                library.owner_user_id = editedRole.user.id;
              "
            />
          </b-col>
          <b-col md="4">
            <forms-validated-input
              v-model="library.avatar"
              type="image"
              name="avatar"
              :label="capitalize($t('fields.avatar'))"
              preview-aspect-ratio="1 / 1"
            >
            </forms-validated-input>
          </b-col>
        </b-row>
        <template v-if="!isNew">
          <paginated-table
            ref="userRolesTable"
            label="Droits de gestion"
            data-dusk="roles-table"
            :endpoint="`/libraries/${library.id}/roles`"
            :columns="userRolesColumns"
            :show-generate-csv="false"
          >
            <template #head-buttons>
              <icon-button
                v-if="canManageRoles"
                role="add"
                data-dusk="add-role-button"
                @click="addRole"
                >Accorder un rôle</icon-button
              >
            </template>
            <template #cell(actions)="{ item }">
              <icon-button v-if="canEditRole" role="edit" size="sm" @click="() => editRole(item)">
              </icon-button>
              <validated-button
                v-if="canRemoveRole(item)"
                :action="async () => removeUserRole(item)"
                :question="`Êtes-vous sûr de vouloir retirer ${item.user.full_name}?`"
                label="retirer"
                icon="trash"
                size="sm"
                variant="ghost-danger"
                :show-button-label="false"
              />
            </template>
          </paginated-table>

          <paginated-table
            ref="communitiesTable"
            data-dusk="communities-table"
            label="Partage"
            :endpoint="`/libraries/${library.id}/communities`"
            :columns="communitiesColumns"
            :show-generate-csv="false"
            @row-clicked="
              (item) => (communityLink(item) ? $router.push(communityLink(item)) : null)
            "
          >
            <template #head-buttons>
              <relation-input
                v-if="canManageCommunities"
                placeholder="Ajouter une communauté"
                :value="null"
                reset-after-select
                :min-search-length="0"
                :query="{
                  slug: 'communities',
                  value: 'id',
                  text: 'name',
                  params: {
                    for: 'profile',
                    '!libraries.id': library.id,
                  },
                }"
                name="add-community"
                data-dusk="add-community-input"
                @input="addCommunity"
              />
            </template>
            <template #cell(actions)="{ item }">
              <validated-button
                v-if="canManageCommunities"
                :action="async () => removeCommunity(item)"
                :question="`Êtes-vous sûr de vouloir arrêter le partage dans ${item.name}?`"
                label="retirer"
                icon="trash"
                variant="ghost-danger"
                size="sm"
                :show-button-label="false"
              />
              <div v-else></div>
            </template>
          </paginated-table>
          <paginated-table
            ref="loanablesTable"
            data-dusk="loanables-table"
            label="véhicules"
            :endpoint="`/libraries/${library.id}/loanables`"
            :columns="loanablesColumns"
            :show-generate-csv="false"
            @row-clicked="(item) => $router.push(`/loanables/${item.id}`)"
          >
            <template #head-buttons>
              <relation-input
                v-if="canManageLoanables"
                ref="loanableInput"
                placeholder="Ajouter un véhicule"
                :value="null"
                reset-after-select
                :min-search-length="0"
                :query="{
                  slug: 'loanables',
                  value: 'id',
                  text: 'name',
                  params: {
                    for: 'owner',
                    '!library.id': library.id,
                    fields:
                      'merged_user_roles.role,merged_user_roles.user.id,merged_user_roles.user.full_name',
                  },
                }"
                name="add-loanable"
                data-dusk="add-loanable-input"
                @input="addLoanable"
              />
            </template>
            <template #cell(actions)="{ item }">
              <icon-button
                role="edit"
                size="sm"
                :to="loanableLink(item)"
                data-dusk="edit-loanable-button"
              />
              <validated-button
                v-if="canManageLoanables"
                :action="async () => removeLoanable(item)"
                :question="`Êtes-vous sûr de vouloir arrêter de retirer ${item.name} de la flotte?`"
                label="retirer"
                icon="trash"
                variant="ghost-danger"
                :show-button-label="false"
                size="sm"
              />

              <div v-else></div>
            </template>
          </paginated-table>
        </template>

        <form-buttons>
          <icon-button
            role="save"
            data-dusk="save-fleet-button"
            :onclick="async () => passes(save)"
            :disabled="loading || !changed"
            >Enregistrer</icon-button
          >
        </form-buttons>
      </b-form>
    </validation-observer>
  </div>
  <div v-else>Erreur de chargement</div>
</template>

<style scoped lang="scss">
.forms-relation-input {
  min-width: 15rem;
}
</style>
