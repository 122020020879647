<script>
import DropdownSelect from "@/components/DropdownSelect.vue";
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import ConditionalContext from "@/components/shared/ConditionalContext.vue";
import IconButton from "@/components/shared/IconButton.vue";
import MarkdownEditor from "@/components/shared/MarkdownEditor.vue";
import { extractErrors } from "@/helpers";
import { put } from "@/requests/server";
import router from "@/router";
import axios from "axios";
import dayjs from "dayjs";
import Vue from "vue";

export default Vue.extend({
  name: "CommunityInvitation",
  components: {
    ConditionalContext,
    MarkdownEditor,
    DropdownSelect,
    IconButton,
    FormsValidatedInput,
  },
  data() {
    const queryParams = router.currentRoute.query;
    const communityId = queryParams.community_id ? parseInt(queryParams.community_id) : null;

    if (Object.keys(queryParams).length > 0) {
      router.replace({ query: {} });
    }

    return {
      saving: false,
      communityId,
      communityName: "",
      reason: null,
      message: null,
      autoApprove: false,
      template: "full",
      emails: queryParams.emails ?? "",
      // By default, show community selection only if not set initially.
      showCommunityId: !communityId,
      templateOptions: [
        { label: "Sans message", value: null },
        { label: "Avec en-tête", value: "full" },
        { label: "Minimal", value: "partial" },
      ],
    };
  },
  computed: {
    invitationCount() {
      return this.emails.split(",").length;
    },
  },
  methods: {
    dayjs,
    resetData() {
      this.saving = false;
      this.reason = null;
      this.autoApprove = false;
      this.emails = "";
    },
    onTemplateSelect(template) {
      this.template = template;
      if (this.template === null) {
        this.message = null;
      }
    },
    async sendSampleInvitation() {
      this.saving = true;
      await put(
        "/invitations/sample",
        {
          emails: this.emails,
          reason: this.reason,
          message: this.message,
          community_id: this.communityId,
          auto_approve: this.autoApprove,
          template: this.template,
        },
        {
          cleanupCallback: () => (this.saving = false),
          notifications: { action: "Envoi d'exemple d'invitation", onSuccess: true },
        }
      );
    },
    async sendInvitations(resetValidationCallback) {
      try {
        this.saving = true;
        await axios.put("/invitations/batch", {
          emails: this.emails,
          reason: this.reason,
          message: this.message,
          community_id: this.communityId,
          auto_approve: this.autoApprove,
          template: this.template,
        });

        // Reset to default after creating invitations
        this.resetData();

        this.$store.commit("addNotification", {
          content: "Les invitations ont été envoyées avec succès.",
          title: "Invitations envoyées",
          variant: "success",
        });

        resetValidationCallback();
      } catch (e) {
        const content = extractErrors(e.response?.data).join(", ") ?? "";

        this.$store.commit("addNotification", {
          content,
          title: "Erreur lors de la création des invitations",
          variant: "danger",
        });
        throw e;
      } finally {
        this.saving = false;
      }
    },
    setCommunityId(community) {
      this.communityId = community?.id ?? null;
    },
    setCommunityName(community) {
      this.communityName = community?.name ?? "";
    },
  },
});
</script>

<template>
  <validation-observer v-slot="{ passes, reset }">
    <b-form class="form">
      <h2 class="mb-4">Inviter à rejoindre LocoMotion {{ communityName }}</h2>
      <forms-validated-input
        v-show="showCommunityId"
        :value="communityId"
        type="relation"
        name="community_id"
        label="Communauté"
        :rules="{
          required: true,
        }"
        :query="{
          slug: 'communities',
          value: 'id',
          text: 'name',
          params: {
            for: 'admin',
          },
        }"
        @relation="setCommunityId"
        @search-object-updated="setCommunityName"
      />

      <forms-validated-input
        v-model="emails"
        label="courriels"
        type="multiple-emails"
        placeholder="la.voisine@example.com"
        name="emails"
        :rules="{
          required: true,
        }"
      >
        <template #label>
          Faites découvrir les options de transport actif et partagé de votre communauté à plus de
          personnes!
        </template>
      </forms-validated-input>

      <dropdown-select
        v-model="template"
        :options="templateOptions"
        label="Modèle de courriel"
        :onselect="onTemplateSelect"
        class="mb-2"
      />

      <conditional-context
        :show="template === 'full' || template === 'partial'"
        label="Message d'invitation personnalisé"
        class="mx-0 mt-2"
      >
        <forms-validated-input
          v-if="template === 'full'"
          v-model="message"
          placeholder="Salut Mathieu!

On s’est vus à la distribution de fleurs samedi passé.
Fais-moi signe si tu as besoin d’aide pour ton inscription.

Nicole au (438) 555-0123"
          type="textarea"
          :rules="{
            required: false,
          }"
          name="message"
          :rows="3"
          :max-rows="10"
          class="mb-3"
        />
        <markdown-editor
          v-if="template === 'partial'"
          v-model="message"
          class="mb-3"
          :placeholder="`## Rejoignez LocoMotion!

Jean vous a invité à rejoindre la communauté MaCommunauté sur [locomotion.app](https://info.locomotion.app)!

Pour plus d'information, contactez jean@macommunaute.org`"
          :send-sample="async () => passes(sendSampleInvitation)"
        />
      </conditional-context>

      <forms-validated-input
        v-model="autoApprove"
        type="checkbox"
        name="auto_approve"
        :label="
          invitationCount > 1
            ? 'J’ai vu les preuves d’adresse et/ou d’identité de ces personnes, je pré-approuve leurs profils.'
            : 'J’ai vu les preuves d’adresse et/ou d’identité de cette personne, je pré-approuve leur profil.'
        "
      >
      </forms-validated-input>

      <forms-validated-input
        v-if="autoApprove"
        v-model="reason"
        label="Note pour le comité"
        placeholder="ex.: preuve d’adresse vue au kiosque du 21 mai 2024"
        type="textarea"
        :rules="{
          required: true,
        }"
        name="message"
        :rows="2"
        :max-rows="4"
        description="Cette note sera utilisée comme raison d'approbation de ces personnes dans la communauté."
      />

      <div class="button-list">
        <icon-button
          role="send"
          :onclick="async () => passes(() => sendInvitations(reset))"
          :disabled="saving"
        >
          Envoyer
        </icon-button>
      </div>
      <div>
        <small class="text-muted"
          >À noter: les invitations ne sont valides que pour 30 jours.</small
        >
      </div>
    </b-form>
  </validation-observer>
</template>

<style lang="scss"></style>
