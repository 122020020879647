export default {
  fr: {
    roles: {
      owner: "propriétaire",
      manager: "gestionnaire",
    },
    fields: {
      name: "nom",
      avatar: "logo",
      phone_number: "numéro de téléphone (optionnel)",
    },
  },
};
