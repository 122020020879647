<script>
import PricingForm from "@/components/Pricing/PricingForm.vue";
import PricingLoanableTypes from "@/components/Pricing/PricingLoanableTypes.vue";
import IconButton from "@/components/shared/IconButton.vue";
import PaginatedTable from "@/components/shared/PaginatedTable.vue";
import { Column, Filter } from "@/components/shared/PaginatedTableColumns";
import { date } from "@/helpers/filters";
import locales from "@/locales";
import Vue from "vue";

export default Vue.extend({
  name: "PricingTable",
  components: { IconButton, PricingLoanableTypes, PaginatedTable, PricingForm },
  props: {
    communityId: {
      type: String,
      default: undefined,
    },
    availableLoanableTypes: {
      type: Array,
      default: () => ["car", "bike", "trailer"],
    },
    exemptFromContributions: {
      type: Boolean,
      default: false,
    },
    isPrivateCommunity: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let pricingsFetchParams = {};
    let initialFilters = {
      expired: false,
    };

    if (this.communityId) {
      pricingsFetchParams.forCommunity = this.communityId;
      pricingsFetchParams.sortForCommunity = true;
    } else {
      initialFilters.is_global = true;
    }

    return {
      newPricing: null,
      pricingsFetchParams,
      initialFilters,
      extraFilters: [
        new Filter("expired", "Expirées?", "select", [
          { value: null, label: "tous" },
          { value: false, label: "En cours ou futures" },
          { value: true, label: "Expirées" },
        ]),
      ],
      pricingsExtraData: [
        "is_global, rule",
        "community_id",
        "loanable_ownership_type",
        "yearly_target_per_user",
        "is_mandatory",
        "description",
      ],
    };
  },
  computed: {
    columns() {
      const loanableTypeOptions = [{ value: null, label: this.$t("pricings.loanableTypes.null") }];
      if (this.availableLoanableTypes.includes("bike")) {
        loanableTypeOptions.push(
          { value: "bike_regular", label: this.$t("pricings.loanableTypes.bike_regular") },
          { value: "bike_electric", label: this.$t("pricings.loanableTypes.bike_electric") },
          {
            value: "bike_cargo_regular",
            label: this.$t("pricings.loanableTypes.bike_cargo_regular"),
          },
          {
            value: "bike_cargo_electric",
            label: this.$t("pricings.loanableTypes.bike_cargo_electric"),
          }
        );
      }
      if (this.availableLoanableTypes.includes("trailer")) {
        loanableTypeOptions.push({
          value: "trailer",
          label: this.$t("pricings.loanableTypes.trailer"),
        });
      }
      if (this.availableLoanableTypes.includes("car")) {
        loanableTypeOptions.push(
          { value: "car_small", label: this.$t("pricings.loanableTypes.car_small") },
          { value: "car_large", label: this.$t("pricings.loanableTypes.car_large") },
          {
            value: "car_small_electric",
            label: this.$t("pricings.loanableTypes.car_small_electric"),
          },
          {
            value: "car_large_electric",
            label: this.$t("pricings.loanableTypes.car_large_electric"),
          }
        );
      }

      let columns = [
        new Column("id", "ID", "id", { showByDefault: false, sortable: false }),
        new Column("name", "Nom", "text", { sortable: false }),
        new Column("start_date", "Date de début", "date", {
          showByDefault: false,
          formatter: (d) => date(d),
        }),
        new Column("end_date", "Date de fin", "date", {
          showByDefault: false,
          formatter: (d) => date(d),
        }),
        Column.withSelect(
          "pricing_type",
          "Type",
          [
            { value: null, label: this.$t("pricings.loanableTypes.null") },
            {
              value: "contribution",
              label: this.$t("pricings.types.contribution"),
              variant: "success",
            },
            { value: "price", label: this.$t("pricings.types.price"), variant: "primary" },
            {
              value: "insurance",
              label: this.$t("pricings.types.insurance"),
              variant: "secondary",
            },
          ],
          { sortable: false }
        ),
        Column.withSelect("pricing_loanable_types", "Type de véhicule", loanableTypeOptions, {
          sortable: false,
        }),
        Column.withSelect(
          "loanable_ownership_type",
          "Véhicules partagés par",
          [
            {
              value: "all",
              label: this.$t("pricings.loanable_ownership_type.all"),
              variant: "primary",
            },
            {
              value: "fleet",
              label: this.$t("pricings.loanable_ownership_type.fleet"),
              variant: "success",
            },
            {
              value: "non_fleet",
              label: this.$t("pricings.loanable_ownership_type.non_fleet"),
              variant: "warning",
            },
          ],
          {
            sortable: false,
          }
        ),
      ];

      if (!this.communityId) {
        columns.push(
          Column.withSelect(
            "is_global",
            "Globale ou de communauté",
            [
              {
                value: null,
                label: "toutes",
              },
              {
                value: true,
                label: "Globale",
                variant: "success",
              },
              {
                value: false,
                label: "De communauté",
                variant: "warning",
              },
            ],
            {
              sortable: false,
              showByDefault: false,
            }
          )
        );

        columns.push(
          Column.withRelation(
            "community.name",
            "Communauté",
            { relation: "communities", label: "name", field: "id", params: { for: "admin" } },
            "community_id"
          )
        );
      }

      return columns;
    },
  },
  watch: {
    communityId(id) {
      if (id) {
        this.pricingsFetchParams.forCommunity = id;
        this.pricingsFetchParams.sortForCommunity = true;
      } else {
        this.pricingsFetchParams.sortForCommunity = false;
        this.initialFilters.is_global = true;
      }
    },
  },
  methods: {
    addPricing() {
      if (!this.newPricing) {
        this.newPricing = {
          _showDetails: true,
          _changed: false,
          pricing_loanable_types: [],
          loanable_ownership_type: "all",
        };

        if (this.communityId) {
          this.newPricing.community_id = this.communityId;
        }
      }
    },
    pricingCreated() {
      this.newPricing = null;
    },
    pricingChanged(pricing) {
      if (!pricing.id) {
        this.newPricing = pricing;
      }
    },
  },

  i18n: {
    messages: {
      en: {
        ...locales.en.forms,
        pricings: locales.en.pricings,
      },
      fr: {
        ...locales.fr.forms,
        pricings: locales.fr.pricings,
      },
    },
  },
});
</script>

<template>
  <div>
    <paginated-table
      ref="pricingsTable"
      class="mt-4"
      label="Tarifications"
      endpoint="pricings"
      :columns="columns"
      :fetch-params="pricingsFetchParams"
      :initial-filters="initialFilters"
      :extra-data="pricingsExtraData"
      has-row-details
      :new-item="newPricing"
      :row-disabled="(item) => communityId && item && item.is_global"
      editable-items
      :extra-filters="extraFilters"
      :show-generate-csv="false"
    >
      <template #head-buttons>
        <icon-button role="add" @click="addPricing"> Ajouter </icon-button>
      </template>
      <template #cell(pricing_loanable_types)="{ item }">
        <pricing-loanable-types :pricing="item" />
      </template>
      <template #cell(name)="{ item }">
        <b-badge v-if="communityId && !item.community_id" variant="secondary">Global</b-badge>
        <b-badge v-if="!item.id" variant="warning">Nouveau</b-badge>
        <b-badge v-else-if="item._changed" variant="warning">Non enregistré</b-badge>
        {{ item.name }}
      </template>
      <template #row-details="{ item, initialItem, updateItem }">
        <pricing-form
          :pricing="item"
          :initial-pricing="initialItem"
          :on-pricing-saved="updateItem"
          :available-loanable-types="availableLoanableTypes"
          :exempt-from-contributions="exemptFromContributions"
          :is-private-community="isPrivateCommunity"
          @change="pricingChanged"
          @created="pricingCreated"
          @deleted="$refs.pricingsTable.refresh"
          @cancel-create="newPricing = null"
        />
      </template>
    </paginated-table>
  </div>
</template>

<style scoped lang="scss"></style>
