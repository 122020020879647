import { del, post } from "@/requests/server";
import RestModule from "../RestModule";

export default new RestModule(
  "communities",
  {
    exportFields: ["id", "name", "type", "center", "area"],
  },
  {
    async addUser({ commit }, { communityId, userId }) {
      const response = await post(
        `/communities/${communityId}/users`,
        { id: userId },
        {
          axiosRequestConfig: {
            params: {
              fields: "*,communities.*",
            },
          },
          requestOptions: { cancelId: "add-user" },
          notifications: { action: "ajout de membre" },
        }
      );
      commit("users/addData", [response.data], { root: true });
    },
    async removeUser(store, { id, userId }) {
      await del(`/communities/${id}/users/${userId}`, {
        requestOptions: { cancelId: "remove-user" },
        notifications: { action: "retrait de membre" },
      });
    },
  }
);
