<script>
import DropdownSelect from "@/components/DropdownSelect.vue";
import IconButton from "@/components/shared/IconButton.vue";

export default {
  name: "DurationInput",
  components: { DropdownSelect, IconButton },
  props: {
    value: {
      type: [Number, null],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      default: null,
    },
  },
  data: function () {
    let unit = this.guessUnit();
    return {
      unit,
      amount: this.getDurationInUnit(unit),
      unitOptions: [
        { label: "minutes", value: "minutes" },
        { label: "heures", value: "hours" },
        { label: "jours", value: "days" },
        { label: "semaines", value: "weeks" },
      ],
    };
  },
  methods: {
    getDurationInUnit(unit) {
      if (!this.value) {
        return this.value;
      }

      if (unit === "hours") {
        return this.value / 60;
      }

      if (unit === "days") {
        return this.value / 60 / 24;
      }

      if (unit === "weeks") {
        return this.value / 60 / 24 / 7;
      }

      return this.value;
    },
    guessUnit() {
      if (!this.value) {
        return "days";
      }

      // Use higher units only if value is above 1 of that unit and value is cleanly divisible by
      // 1 unit.
      if (this.value < 60 || this.value % 60 > 0) {
        return "minutes";
      }

      if (this.value < 24 * 60 || this.value % (24 * 60) > 0) {
        return "hours";
      }

      if (this.value < 24 * 60 * 7 || this.value % (24 * 60 * 7) > 0) {
        return "days";
      }

      return "weeks";
    },
    setAmount(amount) {
      this.amount = amount;
      this.emit();
    },
    setUnit(unit) {
      this.unit = unit;
      this.emit();
    },
    clear() {
      this.amount = 0;
      this.unit = "days";
      this.$emit("input", null);
    },
    emit() {
      let newValue = this.amount;
      if (this.unit === "hours") {
        newValue = this.amount * 60;
      }
      if (this.unit === "days") {
        newValue = this.amount * 60 * 24;
      }
      if (this.unit === "weeks") {
        newValue = this.amount * 60 * 24 * 7;
      }
      this.$emit("input", newValue);
    },
  },
};
</script>

<template>
  <icon-button
    v-if="value === null"
    :disabled="disabled"
    role="add"
    size="sm"
    @click="() => setAmount(1)"
  >
    Ajouter
  </icon-button>
  <div v-else class="duration-input">
    <b-input-group @blur="$emit('blur')">
      <b-input
        :state="state"
        :disabled="disabled"
        :value="amount"
        type="number"
        @input="setAmount"
      ></b-input>
      <b-input-group-append>
        <dropdown-select
          :disable="disabled"
          :value="unit"
          :options="unitOptions"
          @select="setUnit"
        />
      </b-input-group-append>
    </b-input-group>
    <icon-button variant="transparent-secondary" icon="x" @click="clear" />
  </div>
</template>

<style scoped lang="scss">
.duration-input {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  .input-group {
    width: auto;
  }
  input {
    max-width: 5rem;
  }
}
</style>
