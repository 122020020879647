import { render, staticRenderFns } from "./InfoBox.vue?vue&type=template&id=3e38e81b"
import script from "./InfoBox.vue?vue&type=script&lang=js"
export * from "./InfoBox.vue?vue&type=script&lang=js"
import style0 from "./InfoBox.vue?vue&type=style&index=0&id=3e38e81b&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports