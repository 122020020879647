<script>
import Bike from "@/assets/svg/bike.svg";
import BikeCargo from "@/assets/svg/bike_cargo.svg";
import BikeCargoElectric from "@/assets/svg/bike_cargo_electric.svg";
import BikeElectric from "@/assets/svg/bike_electric.svg";
import CarLargeElectric from "@/assets/svg/car_large_electric.svg";
import CarSmallElectric from "@/assets/svg/car_small_electric.svg";
import CarLarge from "@/assets/svg/car_large.svg";
import CarSmall from "@/assets/svg/car_small.svg";
import Trailer from "@/assets/svg/trailer.svg";
import TinyInvoice from "@/components/Invoice/TinyInvoice.vue";
import PricingLoanableTypes from "@/components/Pricing/PricingLoanableTypes.vue";
import IconButton from "@/components/shared/IconButton.vue";
import PaymentDialog from "@/components/User/PaymentDialog.vue";
import { capitalize, date } from "@/helpers/filters";
import { put } from "@/requests/server";
import dayjs from "dayjs";
import Vue from "vue";

/**
 * @typedef {{
 *     "created_at": string,
 *     "updated_at": string,
 *     "id": number,
 *     "start_date": string,
 *     "end_date": string,
 *     "type": string,
 *     "reason": string,
 *     "community_id": number,
 *     "loanable_types": [string],
 * }} subscription
 *
 */

export default Vue.extend({
  name: "ProfileCommunitySubscriptions",
  components: {
    IconButton,
    TinyInvoice,
    PricingLoanableTypes,
    PaymentDialog,
    bike_regular: Bike,
    bike_electric: BikeElectric,
    bike_cargo_regular: BikeCargo,
    bike_cargo_electric: BikeCargoElectric,
    car_small: CarSmall,
    car_large: CarLarge,
    car_small_electric: CarSmallElectric,
    car_large_electric: CarLargeElectric,
    trailer: Trailer,
  },
  props: {
    community: {
      type: Object,
      required: true,
    },
    /** @type [subscription] */
    subscriptions: {
      type: Array,
      required: true,
    },
    pricingsPerLoanableType: {
      type: Object,
      required: true,
    },
  },
  data() {
    const isModifying = this.$route.query.community_id == this.community.id;
    let selectedPricingIds = [];

    if (isModifying && this.$route.query.loanable_type) {
      if (this.pricingsPerLoanableType[this.$route.query.loanable_type]) {
        selectedPricingIds = this.pricingsPerLoanableType[this.$route.query.loanable_type].map(
          (p) => p.id
        );
      }
    }

    return {
      selectedPricingIds,
      estimation: null,
      estimationLoading: false,
      paying: false,
      isModifying,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    paidSubscription() {
      const paymentSubscriptions = this.subscriptions.filter((s) => s.type === "paid");
      if (paymentSubscriptions.length === 0) {
        return null;
      }

      return paymentSubscriptions[0];
    },
    activeGrantedSubscription() {
      const paymentSubscriptions = this.subscriptions.filter((s) => s.type === "granted");
      if (paymentSubscriptions.length === 0) {
        return null;
      }

      return paymentSubscriptions[0];
    },
    unsubscribedLoanableTypes() {
      const unsubscribedLoanableTypes = {};
      for (const loanableType in this.pricingsPerLoanableType) {
        if (!this.subscriptionLoanableTypes.includes(loanableType)) {
          unsubscribedLoanableTypes[loanableType] = this.pricingsPerLoanableType[loanableType];
        }
      }
      return unsubscribedLoanableTypes;
    },
    subscriptionLoanableTypes() {
      const subscriptionLoanableTypes = new Set();
      for (const subscription of this.subscriptions) {
        for (const loanableType of subscription.loanable_types) {
          subscriptionLoanableTypes.add(loanableType);
        }
      }
      return [...subscriptionLoanableTypes.values()];
    },
    allPricings() {
      const allPricingIds = new Set();
      const allPricings = [];
      for (const pricings of Object.values(this.pricingsPerLoanableType)) {
        for (const pricing of pricings) {
          if (!allPricingIds.has(pricing.id)) {
            allPricings.push(pricing);
            allPricingIds.add(pricing.id);
          }
        }
      }
      return allPricings;
    },
    selectedLoanableTypes() {
      if (this.selectedPricingIds.length === 0) {
        return [];
      }

      const selectedLoanableTypes = [];
      for (const [loanableType, pricings] of Object.entries(this.pricingsPerLoanableType)) {
        let allSelected = true;
        for (const pricing of pricings) {
          if (!this.selectedPricingIds.includes(pricing.id)) {
            allSelected = false;
            break;
          }
        }
        if (allSelected) {
          selectedLoanableTypes.push(loanableType);
        }
      }

      return selectedLoanableTypes;
    },
    estimationInvoice() {
      if (!this.estimation) {
        return null;
      }
      const invoiceItems = [];
      const reinbursments = [];
      for (const item of this.estimation.items) {
        const selectedPricing = this.allPricings.find((p) => p.id === item.meta.pricing_id);
        const itemData = {
          item_type: item.item_type,
          label: item.label,
          total: -item.total,
          subtext: selectedPricing && item.total < 0 ? selectedPricing.description : null,
        };

        if (item.total < 0) {
          invoiceItems.push(itemData);
        } else {
          reinbursments.push(itemData);
        }
      }

      if (reinbursments.length > 0) {
        invoiceItems.push({
          label: "Compensation pour la dernière contribution",
          items: reinbursments,
        });
      }

      return {
        items: [
          ...invoiceItems,
          {
            label: "Total",
            class: "invoice-total",
            total: this.estimationTotal,
          },
        ],
      };
    },

    inAYear() {
      return dayjs().add(1, "year");
    },
    invoiceTitle() {
      return `Contributions jusqu'au ${date(this.inAYear)}`;
    },
    provisionInvoice() {
      if (!this.estimationTotal) {
        return null;
      }

      let invoice = {
        title: "Paiement",
        items: [],
      };

      invoice.items.push({
        label: "Solde actuel",
        total: this.user.balance,
      });

      if (this.estimationTotal > this.user.balance) {
        invoice.items.push({
          class: "invoice-total",
          label: "Total à payer",
          total: this.estimationTotal - this.user.balance,
        });
      } else {
        invoice.items.push({
          class: "invoice-total",
          label: "Solde après paiement",
          total: this.user.balance - this.estimationTotal,
        });
      }

      return invoice;
    },
    estimationTotal() {
      if (!this.estimation) {
        return null;
      }

      let total = 0;
      for (const item of this.estimation.items) {
        total -= item.total;
      }
      return total;
    },
    loanableTypesForNewSubscription() {
      return [...this.selectedLoanableTypes, ...this.subscriptionLoanableTypes];
    },
  },
  watch: {
    selectedLoanableTypes() {
      if (this.selectedLoanableTypes.length > 0) {
        this.loadEstimation();
      } else {
        this.estimation = null;
      }
    },
  },
  mounted() {
    if (this.selectedPricingIds.length > 0) {
      this.loadEstimation();
    }
  },
  methods: {
    dayjs,
    capitalize,
    async paySubscription() {
      this.paying = true;
      await put(
        "/subscriptions/pay",
        {
          community_id: this.community.id,
          pricing_loanable_types: this.loanableTypesForNewSubscription,
        },
        {
          cleanupCallback: () => (this.paying = false),
          notifications: {
            action: "paiement de la contribution",
            onSuccess: "contribution payée!",
          },
        }
      );
      // refresh the user's balance
      await this.$store.dispatch("loadUser");
      this.$emit("paid");
      this.cancelEdit();
    },
    toggleSelectedPricings(pricings, loanableType) {
      const selectedPricingIds = new Set(this.selectedPricingIds);
      if (this.selectedLoanableTypes.includes(loanableType)) {
        // deselect all pricings for the current loanable type
        // potential improvement: deselect only the pricings which are least shared with other
        // loanable types.
        for (const pricingId of pricings.map((p) => p.id)) {
          selectedPricingIds.delete(pricingId);
        }

        if (selectedPricingIds.size === 0) {
          this.selectedPricingIds = [];
          return;
        }

        // check if any loanable type would still be selected, if not deselect everything
        for (const pricings of Object.values(this.pricingsPerLoanableType)) {
          let allPricingsSelected = true;
          for (const pricing of pricings) {
            if (!selectedPricingIds.has(pricing.id)) {
              allPricingsSelected = false;
              break;
            }
          }
          if (allPricingsSelected) {
            this.selectedPricingIds = [...selectedPricingIds.values()];
            return;
          }
        }
        this.selectedPricingIds = [];
        return;
      }
      // add all pricings to selected
      for (const pricingId of pricings.map((p) => p.id)) {
        selectedPricingIds.add(pricingId);
      }

      this.selectedPricingIds = [...selectedPricingIds.values()];
    },
    async loadEstimation() {
      this.estimationLoading = true;
      const { data } = await put(
        "/subscriptions/estimate",
        {
          community_id: this.community.id,
          pricing_loanable_types: this.loanableTypesForNewSubscription,
        },
        {
          cleanupCallback: () => (this.estimationLoading = false),
          notifications: { action: "estimation de la contribution" },
          requestOptions: {
            cancelId: `subscriptions-estimate-${this.community.id}`,
          },
        }
      );
      this.estimation = data;
    },
    cancelEdit() {
      this.isModifying = false;
      this.selectedPricingIds = [];
      if (this.$route.query.community_id || this.$route.query.loanable_type) {
        this.$router.replace({ query: {} });
      }
    },
  },
});
</script>

<template>
  <div class="community-subscription">
    <b-alert v-if="activeGrantedSubscription" class="my-3" variant="success" show>
      {{ activeGrantedSubscription.granted_by_user.full_name }} vous a accordé une
      <strong>gratuité</strong> jusqu'au {{ activeGrantedSubscription.end_date | date
      }}<span v-if="activeGrantedSubscription.reason">
        avec comme raison&nbsp;: "{{ activeGrantedSubscription.reason }}"</span
      >. Vous n'avez donc pas à payer de contributions pour vos emprunts jusqu'à cette date!
    </b-alert>
    <div v-if="paidSubscription && !isModifying">
      <h2>
        Votre contribution pour
        <span class="font-weight-bold text-primary">{{ community.name }}</span>
      </h2>
      <p>
        Payée le {{ paidSubscription.start_date | date }}.<br />
        Active jusqu'au {{ paidSubscription.end_date | date }}.
      </p>

      <div class="loanable-types-selection">
        <div
          v-for="loanableType in subscriptionLoanableTypes"
          :key="loanableType"
          class="loanable-type paid"
        >
          <component :is="loanableType" class="mb-1 loanable-svg" />
          <div>{{ $t(`pricings.loanableTypes.${loanableType}`) | capitalize }}</div>
        </div>

        <icon-button
          v-if="unsubscribedLoanableTypes && Object.keys(unsubscribedLoanableTypes).length > 0"
          variant="ghost-secondary"
          icon="plus-circle"
          @click="isModifying = true"
        >
          Ajouter
        </icon-button>
      </div>
    </div>
    <div v-if="isModifying || !paidSubscription">
      <h2>
        Qu’est-ce qui aiderait le plus
        <span class="font-weight-bold text-primary">{{ community.name }}</span
        >?
      </h2>

      <p>
        LocoMotion se veut démocratique et transparent autant que possible. C’est pourquoi, comme
        membre, vous avez le choix de financer les options de transport les plus pertinentes pour
        vous et votre communauté. Pour investir dans tout le projet, sélectionnez l’ensemble des
        types de véhicules.
      </p>
      <div class="loanable-types-selection">
        <icon-button
          v-for="loanableType in subscriptionLoanableTypes"
          :key="loanableType"
          variant="ghost-secondary"
          pressed
          disabled
          class="loanable-type selection-btn"
        >
          <div>
            <component :is="loanableType" class="mb-1 loanable-svg" />
            <div>{{ $t(`pricings.loanableTypes.${loanableType}`) | capitalize }}</div>
          </div>
          <b-icon class="implicitly-selected-check" icon="check-circle-fill" />
        </icon-button>
        <icon-button
          v-for="(pricings, loanableType) in unsubscribedLoanableTypes"
          :key="loanableType"
          :variant="selectedLoanableTypes.includes(loanableType) ? 'success' : 'ghost-secondary'"
          :pressed="selectedLoanableTypes.includes(loanableType)"
          :disabled="paying"
          class="loanable-type selection-btn"
          @click="() => toggleSelectedPricings(pricings, loanableType)"
        >
          <div>
            <component :is="loanableType" class="mb-1 loanable-svg" />
            <div>{{ $t(`pricings.loanableTypes.${loanableType}`) | capitalize }}</div>
          </div>
          <b-icon
            v-if="selectedLoanableTypes.includes(loanableType)"
            class="implicitly-selected-check"
            icon="check-circle-fill"
          />
          <b-icon v-else class="implicitly-selected-check" icon="circle" />
        </icon-button>
      </div>
      <div v-if="estimation || estimationLoading" class="mt-3">
        <tiny-invoice class="invoice" :invoice="estimationInvoice">
          <template #title>
            <h3>
              {{ invoiceTitle }}
              <layout-loading v-if="estimationLoading" with-text />
            </h3>
          </template>
        </tiny-invoice>
        <tiny-invoice v-if="provisionInvoice" class="invoice mt-2" :invoice="provisionInvoice" />
        <payment-dialog
          v-if="estimationTotal"
          class="mt-3"
          :price="estimationTotal"
          :user="user"
          minimum-only
          action-name="payer"
          :loading="paying"
          :disabled="estimationLoading"
          :show-cancel="!!paidSubscription"
          @complete="paySubscription"
          @cancel="cancelEdit"
        />
      </div>
      <div v-if="!estimationTotal && paidSubscription" class="text-center mt-3">
        <icon-button role="cancel" @click="cancelEdit">Annuler</icon-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.community-subscription {
  h2 {
    font-size: $h3-font-size;
    line-height: $h3-line-height;
  }

  h3 {
    font-weight: 400;
    font-size: $h4-font-size;
    line-height: $h4-line-height;
  }

  .loanable-types-selection {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(7rem, 1fr));

    .loanable-svg {
      width: 2.5rem;
    }

    .paid {
      border: 1px solid transparent;
      padding: 0.375rem 0.75rem;
      border-radius: 0.25rem;
      background-color: $success;
      color: white;
    }

    .paid svg path {
      fill: white;
    }

    .loanable-type {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      font-size: 0.8rem;
      height: 4.5rem;

      &.btn {
        .loanable-svg path {
          fill: currentColor;
        }
        &.active {
          background-color: $success;
          color: white;
          .loanable-svg path {
            fill: white;
          }
        }
      }

      &.selection-btn {
        position: relative;
        .implicitly-selected-check {
          position: absolute;
          background: white;
          border-radius: 50%;
          border: 1px solid white;
          color: $success;
          top: 0.25rem;
          right: 0.25rem;
        }
      }
    }
  }
}
</style>
