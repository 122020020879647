import * as Sentry from "@sentry/vue";
import axios from "axios";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import { strtotime } from "locutus/php/datetime";

import { extend, localize, ValidationObserver, ValidationProvider } from "vee-validate";

import fr from "vee-validate/dist/locale/fr.json";
import * as rules from "vee-validate/dist/rules";
import Vue from "vue";
import VueAxios from "vue-axios";
import VueGtag from "vue-gtag";
import vueHeadful from "vue-headful";
import VueScrollTo from "vue-scrollto";
import VueTheMask from "vue-the-mask";

import App from "./App.vue";
import LayoutFooter from "./components/Layout/Footer.vue";

import LayoutHeader from "./components/Layout/Header.vue";
import LayoutLoading from "./components/Layout/Loading.vue";
import LayoutPage from "./components/Layout/Page.vue";

import { filters } from "./helpers";
import dayjs from "./helpers/dayjs";

import "@/assets/scss/main.scss";
import i18n from "./i18n";
import router from "./router";
import store from "./store";
import "vue-cal/dist/vuecal.css";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    Vue,
    release: process.env.VUE_APP_VERSION,
    environment: process.env.VUE_APP_STRIPE_ENV || process.env.NODE_ENV,
    dsn: "https://8c4800c46daed053dbd29cbe5fa1bdfd@o4506230293463040.ingest.sentry.io/4506230295494656",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 0.01,
    // Only capture replays for errors
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  });
}

Vue.config.productionTip = false;

Vue.use(VueTheMask);
Vue.use(VueScrollTo, { duration: 500, offset: -20 });
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

// Google Analytics configuration
if (process.env.VUE_APP_GA_MEASUREMENT_ID) {
  Vue.use(
    VueGtag,
    {
      config: { id: process.env.VUE_APP_GA_MEASUREMENT_ID },
    },
    router
  );
}

// Google Tag Manager configuration
if (process.env.VUE_APP_GTM_MEASUREMENT_ID) {
  Vue.use(
    VueGtag,
    {
      config: { id: process.env.VUE_APP_GTM_MEASUREMENT_ID },
    },
    router
  );
}

Vue.component("LayoutFooter", LayoutFooter);
Vue.component("LayoutHeader", LayoutHeader);
Vue.component("LayoutLoading", LayoutLoading);
Vue.component("LayoutPage", LayoutPage);

// Install VeeValidate rules and localization
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

extend("url", {
  message: (field) => "Le champ " + field + " doit être une URL valide.",
  validate: (value) => {
    try {
      new URL(value);
      return true;
    } catch (e) {
      return false;
    }
  },
});
extend("nullable", () => true); // More of a backend concern, just skip through
extend("date", (v) => {
  const parsedDate = new Date(v);
  return !Number.isNaN(parsedDate.getTime());
});
extend("present", (v) => v !== null && v !== undefined);
extend("boolean", (v) => typeof v === "boolean");
extend("accepted", {
  validate: (v) => v === true,
  message: "Vous devez accepter la condition.",
});
extend("before", {
  validate: (v, args) => {
    const parsedDate = new Date(strtotime(args[0]) * 1000);

    if (!Number.isNaN(parsedDate.getTime())) {
      return new Date(v) <= parsedDate;
    }

    return false;
  },
  message: (field, args) => {
    const parsedDate = new Date(strtotime(args[0]) * 1000);
    return `Le champ ${field} devrait être avant le ${dayjs(parsedDate).format("DD MMMM YYYY")}.`;
  },
});
extend("after", {
  validate: (v, args) => {
    const parsedDate = new Date(strtotime(args[0]) * 1000);

    if (!Number.isNaN(parsedDate.getTime())) {
      return new Date(v) >= parsedDate;
    }

    return false;
  },
  message: (field, args) => {
    const parsedDate = new Date(strtotime(args[0]) * 1000);
    return `Le champ ${field} devrait être après le ${dayjs(parsedDate).format("DD MMMM YYYY")}.`;
  },
});
extend("is", {
  message: (field) => {
    return `${field} n'est pas identique`;
  },
});

localize("fr", fr);

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

Vue.component("VueHeadful", vueHeadful);

Object.keys(filters).forEach((f) => Vue.filter(f, filters[f]));
Vue.prototype.$filters = filters;

axios.defaults.baseURL = `/api/v1`;
axios.interceptors.request.use((config) => {
  if (store.state.token) {
    // eslint-disable-next-line
    config.headers.Authorization = `Bearer ${store.state.token}`;
  }
  return config;
});

axios.interceptors.response.use((response) => {
  // set client version in store from header
  if (
    response.headers &&
    typeof response.headers === "object" &&
    Object.prototype.hasOwnProperty.call(response.headers, "x-client-version")
  ) {
    store.commit("setClientVersionFromBackend", response.headers["x-client-version"]);
  }
  return response;
});

Vue.use(VueAxios, axios);

/**
 * Global clock. This allows us to use `this.$second` in any vue component
 * either as a watch or a reactive property to depend on in computed attributes.
 *
 * Using this ensures all time-based UI (disabling buttons before time etc.) updates
 * at the same time.
 */
const clock = Vue.observable({ second: dayjs() });
Object.defineProperties(Vue.prototype, {
  $dayjs: {
    get() {
      return dayjs;
    },
  },
  $second: {
    get() {
      return clock.second;
    },
    set(value) {
      clock.second = value;
    },
  },
});
Vue.dayjs = dayjs;

window.setInterval(() => {
  Vue.prototype.$second = dayjs();
}, 1000);

// Allow indirect reference to store and router through the Vue instance.
// This allows use of these in helper modules without creating a dependency on the router
// which itself essentially depends on all views. This is meaningful mainly for tests, where
// we might not want to load the whole app to test a single component.
// At time of creation, these indirect references are used in the requests/server.js file
// which is pulled in many views and components.
Vue.$store = store;
Vue.$router = router;

export const app = new Vue({
  i18n,
  render: (h) => h(App),
  router,
  store,
});

app.$mount("#app");
