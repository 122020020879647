<template>
  <paginated-table
    id="admin-libraries-table"
    ref="table"
    endpoint="libraries"
    :fetch-params="{
      for: 'admin',
    }"
    :label="$t('titles.libraries')"
    :columns="columns"
    :extra-filters="extraFilters"
    :show-generate-csv="false"
    sync-filters-with-url
    row-link
  >
    <template #head-buttons>
      <icon-button
        v-if="canCreateLibrary"
        to="/admin/libraries/new"
        role="add"
        data-dusk="add-fleet-button"
      >
        Créer une flotte
      </icon-button>
    </template>
    <template #cell(actions)="{ item }">
      <validated-button
        v-if="canDeleteLibrary"
        :action="async () => deleteLibrary(item)"
        :question="`Êtes-vous sûr de vouloir retirer ${item.name}?`"
        label="retirer"
        icon="trash"
        size="sm"
        variant="ghost-danger"
        :show-button-label="false"
      />
    </template>
  </paginated-table>
</template>

<script>
import ValidatedButton from "@/components/Admin/ValidatedButton.vue";
import IconButton from "@/components/shared/IconButton.vue";
import PaginatedTable from "@/components/shared/PaginatedTable.vue";
import { Column, Filter } from "@/components/shared/PaginatedTableColumns";
import { capitalize } from "@/helpers/filters";
import { userLink } from "@/helpers/links";
import { isGlobalAdmin } from "@/helpers/permissions/users";
import { del } from "@/requests/server";

export default {
  name: "AdminLibraries",
  components: {
    ValidatedButton,
    IconButton,
    PaginatedTable,
  },
  data() {
    return {
      extraFilters: [
        new Filter("sharedInCommunity", "Communauté", "relation", {
          relation: "communities",
          label: "name",
          field: "id",
          params: { for: "admin" },
        }),
      ],

      columns: [
        new Column("id", "ID", "id"),
        new Column("name", "Nom", "text"),
        Column.withRelation(
          "owner_user.full_name",
          "Propriétaire",
          {
            relation: "users",
            label: "full_name",
            field: "id",
            params: { for: "admin" },
          },
          "owner_user_id",
          {
            urlFct: (library) => userLink(library.owner_user),
            sortable: false,
          }
        ),
        Column.withoutFilter("communities_count", "Nombre de communautés", "number", {
          sortable: false,
        }),
        Column.withoutFilter("loanables_count", "Nombre de véhicules", "number", {
          sortable: false,
        }),
      ],
    };
  },
  computed: {
    isGlobalAdmin() {
      return isGlobalAdmin(this.$store.state.user);
    },
    canCreateLibrary() {
      return this.isGlobalAdmin;
    },
    canDeleteLibrary() {
      return this.isGlobalAdmin;
    },
  },
  methods: {
    capitalize,
    async deleteLibrary(library) {
      await del(`/libraries/${library.id}`, {
        notifications: {
          action: "suppression de la flotte",
          resourceName: "flotte",
          onSuccess: true,
        },
      });
      this.$refs.table.refresh();
    },
  },
};
</script>

<style lang="scss"></style>
