import i18n from "@/i18n.js";
import dayjs from "./dayjs";

const capitalize = (value) => {
  if (!value) {
    return "";
  }
  const string = value.toString();
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// For calculations
const normalizeCurrency = (value) => {
  // Rounding to get rid of floating point errors
  const amount = Math.round(parseFloat(value) * 100) / 100;
  return !isNaN(amount) && amount > 0 ? amount : 0;
};

// Filters
const currency = (value) => {
  const floatVal = parseFloat(value);

  if (Number.isNaN(floatVal)) {
    return "";
  }
  return i18n.n(floatVal, "currency_cad");
};

const percent = (value) => {
  const floatVal = parseFloat(value);

  if (Number.isNaN(floatVal)) {
    return "";
  }

  return i18n.n(floatVal, "percent");
};

const formatDate = (value, format) => {
  if (!value) {
    return "";
  }

  return dayjs(value).format(format);
};
const datetime = (value) => formatDate(value, "D MMMM YYYY HH:mm");

const date = (value) => formatDate(value, "D MMMM YYYY");

const shortDate = (value) => {
  if (!value) {
    return "";
  }

  const date = dayjs(value);
  if (date.year === dayjs().year) {
    return date.format("ddd. D MMM.");
  }

  return date.format("ddd. D MMM. YYYY");
};

const durationInHours = (valueInMinutes) => {
  if (valueInMinutes < 60) {
    return `${valueInMinutes} minutes`;
  }
  let hours = Math.floor(valueInMinutes / 60);
  let minutes = valueInMinutes - hours * 60;
  return `${hours}h ${minutes}m`;
};

const duration = (valueInMinutes) => {
  if (valueInMinutes < 60) {
    return `${valueInMinutes} minutes`;
  }
  let hours = Math.floor(valueInMinutes / 60);
  let minutes = valueInMinutes - hours * 60;

  if (hours < 24) {
    if (minutes === 0) {
      return `${hours} heures`;
    }
    return `${hours}h ${minutes}m`;
  }

  let days = Math.floor(hours / 24);
  hours = hours - days * 24;

  if (days < 7) {
    if (minutes === 0 && hours === 0) {
      return `${days} jours`;
    }
    return `${days}j ${hours}h ${minutes}m`;
  }

  let weeks = Math.floor(days / 7);

  if (minutes === 0 && hours === 0 && days === 0) {
    return `${weeks} semaines`;
  }
  return `${days}j ${hours}h ${minutes}m`;
};

const day = (value) => formatDate(value, "dddd");

// Matches phone numbers like (123) 456 7890 or 123-456-7890
const phoneRegex = /^\(?([1-9][0-9]{2})([- ]*|\) ?)?([1-9][0-9]{2})[- ]?([0-9]{4})$/;

const phone = (value) => {
  if (!value) {
    return "";
  }

  const m = value.toString().match(phoneRegex);

  if (m) {
    return `(${m[1]}) ${m[3]}-${m[4]}`;
  }

  return "";
};

const numberOnlyPhone = (value) => {
  if (!value) {
    return "";
  }

  const m = value.toString().match(phoneRegex);

  if (m) {
    // Extract 123456789
    return `${m[1]}${m[3]}${m[4]}`;
  }

  return "";
};

const time = (value) => formatDate(value, "HH:mm");

const titleize = (value) => {
  if (!value) {
    return "";
  }

  const parts = value.toString().split(/ /);

  return parts.map(capitalize).join(" ");
};

const integer = (value) => {
  return value ? parseInt(value.replace(/\D+/g, "")) : null;
};

const slugify = (str) => {
  return str
    .toString()
    .trim()
    .normalize("NFD") // separate accent from letter
    .replace(/[\u0300-\u036f]/g, "") // remove all separated accents
    .replace(/\s+/g, "-") // replace spaces with -
    .replace(/&/g, "-and-") // replace & with 'and'
    .replace(/[^\w-]+/g, "") // remove all non-word chars
    .replace(/--+/g, "-"); // replace multiple '-' with single '-'
};

export {
  capitalize,
  currency,
  date,
  datetime,
  day,
  duration,
  durationInHours,
  normalizeCurrency,
  percent,
  phone,
  numberOnlyPhone,
  time,
  titleize,
  shortDate,
  integer,
  formatDate,
  slugify,
};
