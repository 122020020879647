import { getAdminCommunities, isAdminOfCommunity } from "@/helpers/permissions/communities";
import { isGlobalAdmin } from "@/helpers/permissions/users";

export function getOwner(loanable) {
  return loanable?.merged_user_roles?.find((r) => r.role === "owner")?.user;
}

export function getLoanableUserRoles(loanable, user) {
  return (loanable?.merged_user_roles || [])
    .filter((r) => r.user_id === user.id)
    .map((r) => r.role);
}

export function isOwner(user, loanable) {
  return hasLoanableRole(user, loanable, "owner");
}

export function hasSomeLoanableRole(user, loanable, roles) {
  return !!loanable?.merged_user_roles?.find(
    (r) => roles.includes(r.role) && r.user_id === user.id
  );
}

export function hasLoanableRole(user, loanable, role) {
  return hasSomeLoanableRole(user, loanable, [role]);
}

export function hasSomeLibraryRole(user, library, roles) {
  return !!library?.user_roles?.find((r) => r.user_id === user.id && roles.includes(r.role));
}

export function hasLibraryRole(user, library, role) {
  return hasSomeLibraryRole(user, library, [role]);
}

export function isCoownerOrOwner(user, loanable) {
  return hasSomeLoanableRole(user, loanable, ["owner", "manager", "coowner"]);
}

export function canCreateLoanable(user) {
  if (getAdminCommunities(user).length > 0) {
    return true;
  }

  return isGlobalAdmin(user);
}

export function isAdminOfLoanable(user, loanable) {
  return (
    isGlobalAdmin(user) ||
    (loanable.community_ids || []).find((id) => isAdminOfCommunity(user, { id })) !== undefined
  );
}

export function canEditLoanable(user, loanable) {
  return (
    isGlobalAdmin(user) || isAdminOfLoanable(user, loanable) || isCoownerOrOwner(user, loanable)
  );
}

export function canDeleteLoanable(user, loanable) {
  return hasLoanableRole(user, loanable, "owner") || isGlobalAdmin(user);
}

export function canEditLoanableRole(user, loanable, role) {
  // Editing your own role is acceptable
  if (isGlobalAdmin(user) || role.user_id === user.id) {
    return true;
  }

  const userRoles = getLoanableUserRoles(loanable, user);

  // Owners can edit all roles
  if (userRoles.includes("owner")) {
    return true;
  }

  // Managers can edit non-manager, non-owner roles
  if (role.role === "manager" || role.role === "owner") {
    return false;
  }
  if (userRoles.includes("manager")) {
    return true;
  }

  // Coowners can edit non-manager, non-owner, non-coowner roles
  if (role.role === "coowner") {
    return false;
  }

  return userRoles.includes("coowner");
}

export function canAddLoanableRole(user, loanable) {
  return !!(hasSomeLoanableRole(user, loanable, ["owner", "manager"]) || isGlobalAdmin(user));
}

export function canDeleteLoanableRole(user, loanable, role) {
  if (role.role === "owner") {
    return false;
  }
  if (isGlobalAdmin(user)) {
    return true;
  }

  // Removing your own privileges is acceptable
  if (role.user_id === user.id) {
    return true;
  }

  const userRoles = getLoanableUserRoles(loanable, user);

  // Owners can remove all non-owner
  if (userRoles.includes("owner")) {
    return true;
  }

  // Managers can remove non-manager, non-owner roles
  if (role.role === "manager") {
    return false;
  }
  if (userRoles.includes("manager")) {
    return true;
  }

  // Coowners can remove non-manager, non-owner, non-coowner roles
  if (role.role === "coowner") {
    return false;
  }

  return userRoles.includes("coowner");
}

export function canEditRoleUser(user, role) {
  // Global admin can change owner user. Can also set user when creating new role.
  return (isGlobalAdmin(user) && role.role === "owner") || !role.id;
}

export function canEditRolePaidAmounts(user, loanableOrLibrary) {
  if (isGlobalAdmin(user)) {
    return true;
  }

  // Owners can change parameters for other roles
  return (
    hasLoanableRole(user, loanableOrLibrary, "owner") ||
    hasLibraryRole(user, loanableOrLibrary, "owner")
  );
}
