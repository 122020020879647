<template>
  <div class="info-box">
    <b-card v-if="skeleton" class="p-3 info-box__skeleton" bg="white" no-body>
      <b-row class="flex-fill">
        <b-col cols="12" sm="auto" class="info-box__image pr-0 bottom-right">
          <div class="position-relative d-inline-block">
            <b-skeleton-img class="info-box__image__large" />
            <b-skeleton type="avatar" class="info-box__image__small"></b-skeleton>
          </div>
        </b-col>
        <b-col class="info-box__content pt-3 pt-sm-0">
          <div class="flex-fill d-flex flex-column justify-content-between">
            <b-skeleton width="20%" height="1rem"></b-skeleton>
            <b-skeleton width="50%" height="2rem"></b-skeleton>
            <div class="d-flex justify-content-between align-items-end">
              <b-skeleton width="20%" height="1rem"></b-skeleton>
              <b-skeleton width="5rem" height="1.5rem" />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <router-link v-else :to="link">
      <b-card class="p-3" bg="white" no-body :class="{ disabled: loading }">
        <b-row class="flex-fill">
          <b-col
            v-if="bigImg"
            cols="12"
            sm="auto"
            class="info-box__image pr-0"
            :class="[avatar ? imageCorner : '']"
          >
            <div class="position-relative d-inline-block">
              <safe-image
                height-bounded
                no-zoom
                :image="bigImg"
                :alt="title"
                class="info-box__image__large"
              />
              <generic-avatar
                v-if="avatar"
                size="sm"
                variant="cut-out"
                :avatar="avatar"
                :label="avatarFallback"
                class="info-box__image__small"
              />
            </div>
          </b-col>
          <b-col class="info-box__content pt-3 pt-sm-0">
            <slot />
          </b-col>
        </b-row>
      </b-card>
    </router-link>
  </div>
</template>

<script>
import GenericAvatar from "@/components/shared/GenericAvatar.vue";
import SafeImage from "@/components/shared/SafeImage.vue";

export default {
  name: "InfoBox",
  components: { GenericAvatar, SafeImage },
  props: {
    link: {
      required: false,
      type: String,
      default: undefined,
    },
    bigImg: {
      type: Object,
      default: undefined,
    },
    avatar: {
      type: Object,
      default: undefined,
    },
    avatarFallback: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    imageCorner: {
      type: String,
      default: "top-right",
    },
    skeleton: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";

.info-box {
  @include media-breakpoint-up(xl) {
    .info-box__image {
      flex: 0;
    }
    .info-box__content {
      flex: 1;
    }
  }

  &__skeleton {
    opacity: 0.5;
    pointer-events: none;

    .info-box__image__large {
      min-width: 5rem;
    }
  }

  .card {
    // arbitrary value that looks good
    min-height: 105px;
  }

  .info-box__content.col {
    align-content: space-between;
    display: flex;
  }

  .info-box__image {
    text-align: center;
    &__large {
      width: 96px;
      height: 96px;
      object-fit: cover;
      border-radius: 5px;
      @include media-breakpoint-down(xs) {
        width: unset;
        max-width: 100%;
      }
    }

    &__small {
      position: absolute;
    }

    &.top-right {
      .info-box__image__large {
        // 0.5 * avatar backround size: 0.5 * 2.75rem
        border-top-right-radius: 1.375rem;
      }
      .info-box__image__small {
        top: 0;
        right: 0;
      }
    }

    &.bottom-right {
      .info-box__image__large {
        border-bottom-right-radius: 1.375rem;
      }
      .info-box__image__small {
        bottom: 0;
        right: 0;
      }
    }
  }

  a:hover,
  a:active,
  a:focus {
    text-decoration: none;
  }

  .card {
    box-shadow: $small-shadow;
    transition-duration: 0.15s;
  }

  .card:hover {
    box-shadow: $medium-shadow;
  }

  &__name {
    color: $black;
    font-size: 1.25rem;
    position: unset;
  }

  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>
