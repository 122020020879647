<template>
  <div
    class="description"
    :class="{ 'no-comment': !loanable.comments, 'small-description': small }"
  >
    <div v-if="loanable.comments && loanable.comments !== ''" class="description-comment">
      <h3 class="description-comment-title">Description</h3>
      <markdown-content :content="loanable.comments" class="description-item-value" />
    </div>
    <template v-if="loanable.type === 'bike'">
      <div class="description-list">
        <div class="description-item">
          <div class="description-item-title">
            <loanable-icon :loanable="loanable" />
          </div>
          <div class="description-item-value">
            <dd>{{ $t(`bike_types.${loanable.details.bike_type}`) | capitalize }}</dd>
          </div>
        </div>

        <div
          v-if="loanable.details.model && loanable.details.model !== ''"
          class="description-item"
        >
          <div v-b-tooltip="'Modèle'" class="description-item-title">
            <b-icon icon="tags" />
          </div>
          <div class="description-item-value">
            {{ loanable.details.model }}
          </div>
        </div>

        <div v-if="loanable.details.size && loanable.details.size !== ''" class="description-item">
          <div v-b-tooltip="'Taille'" class="description-item-title">
            <b-icon icon="arrows-angle-expand" />
          </div>
          <div class="description-item-value">
            {{ $t(`sizes.${loanable.details.size}`) | capitalize }}
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="loanable.type === 'trailer'">
      <div class="description-list">
        <div
          v-if="loanable.details.maximum_charge && loanable.details.maximum_charge !== ''"
          class="description-item"
        >
          <div v-b-tooltip="'Charge maximale'" class="description-item-title">
            <weight-hanging />
          </div>
          <div class="description-item-value">
            {{ loanable.details.maximum_charge }}
          </div>
        </div>
        <div
          v-if="loanable.details.dimensions && loanable.details.dimensions !== ''"
          class="description-item"
        >
          <div v-b-tooltip="'Dimensions'" class="description-item-title">
            <b-icon icon="arrows-angle-expand" />
          </div>
          <div class="description-item-value">
            {{ loanable.details.dimensions }}
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="loanable.type === 'car'">
      <div class="description-list">
        <div class="description-item">
          <div class="description-item-title">
            <loanable-icon :loanable="loanable" />
          </div>
          <div class="description-item-value">
            <span>{{ loanable.details.brand }}</span
            ><span> {{ loanable.details.model }}</span>
            <span v-if="!small"> {{ loanable.details.year_of_circulation }}</span>
          </div>
        </div>

        <div v-if="small" class="description-item">
          <div class="description-item-title">
            <b-icon icon="calendar3" />
          </div>
          <div class="description-item-value">
            {{ loanable.details.year_of_circulation }}
          </div>
        </div>

        <div class="description-item">
          <div class="description-item-title">
            <transmission />
          </div>
          <div class="description-item-value">
            {{ $t(`transmission_modes.${loanable.details.transmission_mode}`) | capitalize }}
          </div>
        </div>

        <div class="description-item">
          <div class="description-item-title"><fuel /></div>
          <div class="description-item-value">
            {{ $t(`engines.${loanable.details.engine}`) | capitalize }}
          </div>
        </div>

        <div v-if="!small" class="description-item">
          <div class="description-item-title">
            <documents />
          </div>
          <div class="description-item-value">
            Papiers {{ $t(`papers_locations.${loanable.details.papers_location}`) }}
          </div>
        </div>
        <template v-if="loanable.details.report && !small">
          <div class="description-item">
            <div class="description-item-title"><magnifier-bug /></div>
            <div class="description-item-value">
              <safe-file :file="loanable.details.report"
                ><b-icon class="mr-1" icon="download" />{{ $t("fields.report") | capitalize }}
              </safe-file>
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import Documents from "@/assets/svg/documents.svg";
import Fuel from "@/assets/svg/fuel.svg";
import MagnifierBug from "@/assets/svg/magnifier-bug.svg";
import Transmission from "@/assets/svg/transmission.svg";
import WeightHanging from "@/assets/svg/weight-hanging.svg";
import LoanableIcon from "@/components/Loanable/LoanableIcon.vue";
import MarkdownContent from "@/components/shared/MarkdownContent.vue";
import SafeFile from "@/components/shared/SafeFile.vue";
import { isAdminOfLoanable } from "@/helpers/permissions/loanables";
import locales from "@/locales";

export default {
  name: "PrettyDescription",
  components: {
    MarkdownContent,
    LoanableIcon,
    SafeFile,
    fuel: Fuel,
    magnifierBug: MagnifierBug,
    transmission: Transmission,
    documents: Documents,
    weightHanging: WeightHanging,
  },
  props: {
    loanable: {
      type: Object,
      required: false,
      default: undefined,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isAdminOfLoanable() {
      return isAdminOfLoanable(this.$store.state.user, this.loanable);
    },
  },
  i18n: {
    messages: {
      en: {
        ...locales.en.loanables,
      },
      fr: {
        ...locales.fr.loanables,
      },
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";
.description {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .description-list {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
  }

  .description-comment {
    margin: 0 1rem;
    .description-item-value {
      text-align: left;
      padding: 1rem;
      border-radius: 1rem;
      background: rgba(87, 97, 148, 0.1);
      margin: 0;
    }
  }

  .description-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    flex: 1;
    padding: 0 1rem;
    min-width: 8rem;
    text-align: center;
    + .description-item {
      border-left: 1px solid $light-grey;
    }
  }

  .description-item-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    svg {
      height: 1.5rem;
      width: 100%;
      fill: $secondary;
      path {
        fill: $secondary;
      }
    }
  }

  .description-item-value {
    font-weight: 400;
    overflow: hidden;
  }
  &.small-description {
    flex-direction: column-reverse;
    gap: 0.5rem;
    .description-item {
      min-width: 5rem;
      font-size: 0.75rem;
    }
    .description-comment {
      margin: 0 0.5rem 1rem;
    }
    .description-comment .description-item-value {
      padding: 0.5rem;
    }
  }
}

.description-comment-title {
  font-size: 0.8rem;
  font-weight: 400;
  margin: 0.5rem 0 0.25rem;
  line-height: 1.25;
}

@include media-breakpoint-down(xs) {
  .description {
    display: flex;
    flex-direction: column;
    .description-item {
      &.description-comment {
        flex-direction: column;
        align-items: center;
        .description-item-title span {
          display: none;
        }
      }
    }
  }
}
</style>
