<template>
  <library-form :id="id" />
</template>

<script>
import LibraryForm from "@/components/LibraryForm.vue";

export default {
  name: "ProfileLibrary",
  components: { LibraryForm },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss"></style>
