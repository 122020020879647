<template>
  <div>
    <b-alert v-if="!hasOwner" variant="info" show>
      Ajoutez un propriétaire avant de configurer les droits de gestion.
    </b-alert>
    <b-alert v-if="hasNoVisibleContacts" variant="warning" show>
      Personne n'est présentement affiché comme contact lors des emprunts.
    </b-alert>

    <paginated-table
      ref="userRolesTable"
      :endpoint="`/loanables/${loanable.id}/roles`"
      :columns="userRolesColumns"
      :show-generate-csv="false"
      :fetch-params="{
        '!role': 'trusted_borrower',
      }"
      :row-disabled="(item) => item.ressource_type === 'library'"
    >
      <template #head-buttons>
        <icon-button v-if="canAddRole" role="add" @click="() => beginEditRole()"
          >Accorder un rôle</icon-button
        >
      </template>
      <template #cell(actions)="{ item }">
        <icon-button
          v-if="canEditRole(item)"
          role="edit"
          size="sm"
          @click="() => beginEditRole(item)"
        >
        </icon-button>
        <validated-button
          v-if="canDeleteRole(item)"
          :action="async () => removeRole(item)"
          :question="`Êtes-vous sûr de vouloir retirer ${item.user.full_name}?`"
          label="retirer"
          icon="trash"
          size="sm"
          variant="ghost-danger"
          :show-button-label="false"
        />
      </template>
    </paginated-table>
    <loanable-user-role-form
      ref="editRoleModal"
      :disabled="disabled"
      :role="editedRole"
      :loanable="loanable"
      @saved="onSaved"
    />
  </div>
</template>

<script>
import ValidatedButton from "@/components/Admin/ValidatedButton.vue";
import LoanableUserRoleForm from "@/components/Loanable/UserRoleForm.vue";
import IconButton from "@/components/shared/IconButton.vue";
import PaginatedTable from "@/components/shared/PaginatedTable.vue";
import { Column } from "@/components/shared/PaginatedTableColumns";
import { userLink } from "@/helpers/links";
import {
  canAddLoanableRole,
  canDeleteLoanableRole,
  canEditLoanableRole,
  getOwner,
} from "@/helpers/permissions/loanables";
import loanables from "@/locales/models/loanables";
import { del } from "@/requests/server";

export default {
  name: "LoanableUserRolesForm",
  components: { ValidatedButton, PaginatedTable, IconButton, LoanableUserRoleForm },
  props: {
    disabled: { type: Boolean, default: false },
    loanable: { type: Object, required: true },
    showAdminLinks: { type: Boolean, default: false },
  },
  data() {
    return {
      editedRole: null,
      userRolesColumns: [
        Column.withRelation(
          "user.full_name",
          "Membre",
          {
            relation: "users",
            label: "full_name",
            field: "id",
            params: {
              "library_roles.ressource_id": this.id,
            },
          },
          "user_id",
          {
            urlFct: (userRole) => userLink(userRole.user),
          }
        ),
        new Column("title", "Titre"),
        Column.withSelect("role", "Rôle", [
          { value: null, label: "Tous" },
          { value: "owner", label: this.$t("roles.owner"), variant: "success" },
          { value: "manager", label: this.$t("roles.manager"), variant: "primary" },
          { value: "coowner", label: this.$t("roles.coowner"), variant: "warning" },
        ]),
        Column.withRelation(
          "granted_by_user.full_name",
          "Accordé par",
          {
            relation: "users",
            label: "full_name",
            field: "id",
            params: {
              for: "profile",
            },
          },
          "granted_by_user_id",
          {
            showByDefault: false,
            urlFct: (userRole) => userLink(userRole.granted_by_user),
          }
        ),
        new Column("show_as_contact", "Contact", "boolean"),
        new Column("pays_loan_price", "Paie le tarif", "boolean", { showByDefault: false }),
        new Column("pays_loan_insurance", "Paie l'assurance", "boolean", { showByDefault: false }),
      ],
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    hasOwner() {
      return getOwner(this.loanable) !== null;
    },
    hasNoVisibleContacts() {
      return !this.loanable.merged_user_roles.find((r) => r.show_as_contact);
    },
    canAddRole() {
      return canAddLoanableRole(this.user, this.loanable);
    },
  },
  methods: {
    canEditRole(role) {
      return canEditLoanableRole(this.user, this.loanable, role);
    },
    canDeleteRole(role) {
      return canDeleteLoanableRole(this.user, this.loanable, role);
    },
    onSaved(role) {
      // Add or update role in loanable.merged_user_roles to make sure contact warning is synced
      let existingRole = null;
      let newRoles = this.loanable.merged_user_roles;
      if (role.id) {
        existingRole = this.loanable.merged_user_roles.find((r) => r.id === role.id);
      }
      if (existingRole) {
        newRoles = newRoles.filter((r) => r.id !== role.id);
        let updatedRole = {
          ...existingRole,
          ...role,
        };
        newRoles.push(updatedRole);
      } else {
        newRoles.push(role);
      }

      this.loanable.merged_user_roles = newRoles;
      this.$refs.userRolesTable.refresh();
    },
    beginEditRole(role = null) {
      if (!role) {
        this.editedRole = {
          user_id: undefined,
          user: {},
          title: "",
          role: "coowner",
          show_as_contact: false,
          pays_loan_price: true,
          pays_loan_insurance: true,
        };
      } else {
        this.editedRole = role;
      }

      this.$refs.editRoleModal.show();
    },
    async removeRole(role) {
      const isRemovingSelf = role.user_id === this.user.id;

      if (
        !isRemovingSelf ||
        confirm("Êtes-vous certains de vouloir vous enlever en tant que copropriétaire?")
      ) {
        await del(`/loanables/roles/${role.id}`, {
          notifications: {
            action: "retrait d'unl rôle",
            onSuccess: "Role retiré-e!",
          },
        });

        if (isRemovingSelf) {
          await this.$router.push("/app");
        } else {
          this.$refs.userRolesTable.refresh();
          this.loanable.merged_user_roles = this.loanable.merged_user_roles.filter(
            (r) => r.id !== role.id
          );
        }
      }
    },
  },
  i18n: {
    messages: {
      fr: {
        ...loanables.fr,
      },
    },
  },
};
</script>

<style scoped>
.loading {
  opacity: 0.5;
}
.action-buttons {
  gap: 0.5rem;
}
</style>
