import dayjs from "@/helpers/dayjs";
import { get } from "@/requests/server";

const getInitialState = () => ({
  loans: {
    future: {
      loans: [],
      total: 0,
    },
    started: {
      loans: [],
      total: 0,
    },
    contested: {
      loans: [],
      total: 0,
    },
    waiting: {
      loans: [],
      total: 0,
    },
    completed: {
      loans: [],
      total: 0,
    },
    need_approval: {
      loans: [],
      total: 0,
    },
  },
  libraries: {
    data: [],
    total: 0,
  },
  loanables: {
    owned: {
      data: [],
      total: 0,
    },
    coowned: {
      data: [],
      total: 0,
    },
  },
  loaded: false,
  loansLoaded: false,
  loansLoading: false,
  loanablesLoaded: false,
  loanablesLoading: false,
  loansLastLoadTime: null,
  loanablesLastLoadTime: null,
});

const actions = {
  async reload({ dispatch }) {
    dispatch("loadLoans");
    dispatch("loadLoanables");
  },
  async loadLoans({ state, commit }) {
    if (state.loansLoading) {
      return;
    }

    commit("loadLoans");
    const { data: loans } = await get("/loans/dashboard", {
      notifications: { action: "chargement de vos emprunts" },
      cleanupCallback: () => commit("loansLoading", false),
    });
    commit("loansLoaded", loans);
  },
  async loadLoanables({ state, commit }) {
    if (state.loanablesLoading) {
      return;
    }
    commit("loadLoanables");
    const { data } = await get("/loanables/dashboard", {
      notifications: { action: "chargement de vos véhicules" },
      cleanupCallback: () => commit("loanablesLoading", false),
    });
    commit("loanablesLoaded", data);
  },
};

const mutations = {
  loadLoans(state) {
    state.loansLoaded = false;
    state.loansLoading = true;
  },
  loansLoading(state, loading) {
    state.loansLoading = loading;
  },
  loadLoanables(state) {
    state.loanablesLoading = true;
    state.loanablesLoaded = false;
  },
  loansLoaded(state, loans) {
    state.loans = loans;
    state.loansLoaded = true;
    state.loansLoading = false;
    state.loansLastLoadTime = dayjs();
  },
  loanablesLoaded(state, data) {
    state.loanablesLoaded = true;
    state.loanables = data.loanables;
    state.libraries = data.libraries;
    state.loanablesLoading = false;
    state.loanablesLastLoadTime = dayjs();
  },
  loanablesLoading(state, loading) {
    state.loanablesLoading = loading;
  },
  setLoanables(state, loanables) {
    state.loanables = loanables;
  },
  reset(state) {
    Object.assign(state, getInitialState());
  },
};

export default {
  namespaced: true,
  state: getInitialState(),
  actions,
  mutations,
};
