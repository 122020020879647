<template>
  <generic-avatar
    :variant="variant"
    :size="size"
    :avatar="user.avatar"
    :label="capitalize(user.full_name)"
  >
  </generic-avatar>
</template>

<script>
import GenericAvatar from "@/components/shared/GenericAvatar.vue";
import { capitalize } from "@/helpers/filters";

export default {
  name: "UserAvatar",
  components: {
    GenericAvatar,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    variant: {
      type: String,
      default: undefined,
    },
    size: {
      type: String,
      default: "md",
    },
  },
  methods: { capitalize },
};
</script>

<style lang="scss"></style>
