import {
  canSeeDeletedCommunity,
  isAdminOfCommunity,
  isAdminOfSomeCommunity,
} from "@/helpers/permissions/communities";
import { getOwner, hasSomeLibraryRole, hasSomeLoanableRole } from "@/helpers/permissions/loanables";
import { canSeeDeletedUser, isGlobalAdmin } from "@/helpers/permissions/users";
import store from "../store";

function isAdmin() {
  return isGlobalAdmin(store.state.user) || isAdminOfSomeCommunity(store.state.user);
}

function libraryLink(library) {
  if (hasSomeLibraryRole(store.state.user, library, ["owner", "manager"]) || isAdmin()) {
    return `/libraries/${library.id}`;
  }
  return null;
}

function loanableLink(loanable) {
  if (hasSomeLoanableRole(store.state.user, loanable, ["owner", "coowner", "manager"])) {
    return "/profile/loanables/" + loanable.id;
  }

  if (!isAdmin()) {
    return null;
  }

  if (loanable.deleted_at) {
    return `/admin/loanables?id=${loanable.id}&is_deleted=true`;
  }
  return `/admin/loanables/${loanable.id}`;
}

function userLink(user) {
  if (!isAdmin()) {
    return null;
  }

  if (user.deleted_at) {
    if (canSeeDeletedUser(store.state.user)) {
      return `/admin/users?id=${user.id}&is_deleted=true`;
    }
    return null;
  }
  return `/admin/users/${user.id}`;
}

function communityLink(community) {
  if (!isAdmin()) {
    return null;
  }

  if (community.deleted_at) {
    if (canSeeDeletedCommunity(store.state.user)) {
      return `/admin/communities?id=${community.id}&is_deleted=true`;
    }
    return null;
  }

  if (isGlobalAdmin(store.state.user) || isAdminOfCommunity(store.state.user, community)) {
    return `/admin/communities/${community.id}`;
  }
  return null;
}

function loanLoanableLink(loan) {
  return loanableLink(loan.loanable);
}

function loanOwnerLink(loan) {
  return userLink(getOwner(loan.loanable));
}

function loanBorrowerLink(loan) {
  return userLink(loan.borrower_user);
}

function loanCommunityLink(loan) {
  return communityLink(loan.community);
}

export {
  communityLink,
  libraryLink,
  loanBorrowerLink,
  loanCommunityLink,
  loanLoanableLink,
  loanOwnerLink,
  loanableLink,
  userLink,
};
