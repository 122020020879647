<template>
  <vue-cal
    :class="classList"
    :hide-view-selector="true"
    :disable-views="['years', 'year']"
    :default-view="defaultView"
    :snap-to-time="15"
    :editable-events="!!editableEvents && editableEvents.length > 0"
    :time-step="60"
    :time-cell-height="variant === 'small' ? 24 : 32"
    :events="vueCalEvents"
    locale="fr"
    start-week-on-sunday
    events-on-month-view
    click-to-navigate
    :selected-date="initialDate"
    :xsmall="variant === 'small'"
    :on-event-click="onEventClick"
    @ready="$emit('ready', $event)"
    @view-change="$emit('view-change', $event)"
    @event-change="$emit('event-change', $event)"
    @event-mouse-enter="eventEnter"
    @event-mouse-leave="eventLeave"
  >
    <template #title="{ view }">
      <div class="d-flex align-items-center position-relative">
        <span v-if="view.id === 'years'">Years</span>
        <span v-else-if="view.id === 'year'">{{ view.startDate.format("YYYY") }}</span>
        <span v-else-if="view.id === 'month'">{{ view.startDate.format("MMMM YYYY") }}</span>
        <span v-else-if="view.id === 'week'">{{ view.startDate.format("MMMM YYYY") }}</span>
        <span v-else-if="view.id === 'day'">{{ view.startDate.format("dddd D MMMM (YYYY)") }}</span>
        <b-spinner v-if="loading" class="loanable-calendar__loading-spinner" small />
      </div>
    </template>

    <template #time-cell="{ hours, minutes }">
      <div
        :class="{
          'loanable-calendar__time-step--hours': !minutes,
          'loanable-calendar__time-step--minutes': minutes,
        }"
      >
        <span class="line"></span>
        <template v-if="!minutes">
          <span
            >{{ hours < 10 ? "0" + hours : hours }}:{{
              minutes < 10 ? "0" + minutes : minutes
            }}</span
          >
        </template>
      </div>
    </template>

    <template v-if="!monthDayDetail" #cell-content="cellData">
      <calendar-month-cell-content
        v-if="cellData.view.id === 'month'"
        :availability="getMonthDayAvailability(cellData.events, cellData.cell)"
        :current="cellData.cell.today"
      >
        {{ cellData.cell.content }}
      </calendar-month-cell-content>

      <span v-else class="vuecal__cell-date">
        {{ cellData.cell.content }}
      </span>
    </template>

    <template v-if="monthDayDetail" #event="{ event }">
      <div class="hover-target"></div>
      <b-icon v-if="event.data.available === false" icon="calendar2-x" class="mr-1" />
      <b-icon v-if="event.data.available === true" icon="calendar2-check" class="mr-1" />
      <div v-if="event.title" class="vuecal__event-title">
        {{ event.title }}
      </div>

      <small class="vuecal__event-time">
        <span class="vuecal__event-time__start">
          <loan-status-icon
            v-if="event.data.status"
            :status="event.data.status"
            :action-required="event.data.action_required"
          />
          {{ formatEventTimeStart(event) }}</span
        >
        <span class="vuecal__event-time__end">{{ formatEventTimeEnd(event) }} </span>
        <span class="vuecal__event-time__span">{{ formatEventTimeSpan(event) }}</span>
      </small>
    </template>
    <template v-else #event>
      <div></div>
    </template>
  </vue-cal>
</template>

<script>
import LoanStatusIcon from "@/components/Loan/Status/LoanStatusIcon.vue";
import CalendarMonthCellContent from "@/components/Loanable/CalendarMonthCellContent.vue";
import dayjs from "dayjs";
import VueCal from "vue-cal";
import "vue-cal/dist/i18n/fr";

export default {
  name: "LoanableCalendar",
  components: {
    LoanStatusIcon,
    VueCal,
    "calendar-month-cell-content": CalendarMonthCellContent,
  },
  props: {
    defaultView: {
      type: String,
      default: "week",
    },
    events: {
      type: Array,
      default: () => [],
    },
    editableEvents: {
      type: Array,
      default: () => [],
    },
    variant: {
      type: String,
      required: false,
      default: undefined,
    },
    initialDate: {
      type: [String, Date],
      default: "",
    },
    monthDayDetail: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classList: function () {
      let classList = {
        "loanable-calendar": true,
      };
      if (this.variant) {
        classList["loanable-calendar--" + this.variant] = true;
      }
      return classList;
    },
    vueCalEvents: function () {
      let immtableEvent = {
        resizable: false,
        draggable: false,
        titleEditable: false,
        deletable: false,
      };

      let vueCalEvents = this.events.map((e) => ({ ...immtableEvent, ...e }));

      vueCalEvents.push(...this.editableEvents);

      let baseEvent = {
        deletable: false,
        class: ["loanable-calendar__event"],
      };
      return vueCalEvents.map((e) => {
        e = { ...baseEvent, ...e, class: [...(e.class || [])] };

        e.class.push("loanable-calendar__event");

        if (e.type === "availability" || e.type === "availability_rule") {
          // Availability events go in the background.
          e.background = true;
          if (e.data.available) {
            e.class.push("loanable-calendar__event--availability");
          } else {
            e.class.push("loanable-calendar__event--unavailability");
          }
        } else if (e.type === "loan") {
          // Loans don't go in the background.
          e.background = false;

          if (e.data.action_required) {
            e.class.push("loan-status-action-required");
          } else {
            e.class.push("loan-status-" + e.data.status);
          }

          if (!e.data.accepted) {
            e.class.push("loanable-calendar__event--loan_needs_approval");
          }
        }

        if (this.eventClickable(e)) {
          e.class.push("loanable-calendar__event--clickable");
        }

        // Pass class as a string to vue-cal.
        e.class = e.class.join(" ");

        return e;
      });
    },
  },
  methods: {
    eventClickable(event) {
      return event.uri && this.$route.path !== event.uri;
    },
    eventEnter(e) {
      const loanId = e.data.loan_id;
      for (const event of this.$el.getElementsByClassName(`vuecal__event loan_id_${loanId}`)) {
        let parentWidth = event.style.width;
        let parentLeft = event.style.left;
        let hoverTarget = event.getElementsByClassName("hover-target")[0];
        hoverTarget.setAttribute("style", `width: ${parentWidth};left:${parentLeft};`);
        event.classList.add("hovered");
        setTimeout(() => event.classList.add("no-pointer"), 100);
      }
    },
    eventLeave() {
      for (const event of this.$el.getElementsByClassName("vuecal__event")) {
        event.classList.remove(["hovered"]);
        setTimeout(() => {
          event.classList.remove("no-pointer");
        }, 100);
      }
    },
    formatEventTimeEnd(event) {
      const endDate = dayjs(event.endDate);

      if (endDate.endOf("day").isSame(endDate, "second")) {
        return "24:00";
      }
      return endDate.format("HH:mm");
    },
    formatEventTimeStart(event) {
      const startDate = dayjs(event.startDate);
      return startDate.format("HH:mm");
    },
    formatEventTimeSpan(event) {
      const startDate = dayjs(event.startDate);
      const endDate = dayjs(event.endDate);

      if (!startDate.isSame(endDate, "day")) {
        return " +" + endDate.diff(startDate, "day") + "j";
      }

      return "";
    },
    onEventClick(event, e) {
      // Stop propagation to avoid changing calendar view.
      e.stopPropagation();
      if (this.eventClickable(event)) {
        this.$router.push(event.uri);
      }
    },
    getMonthDayAvailability(events, cell) {
      const cellStartTime = this.$dayjs(cell.startDate).startOfDay();
      const cellEndTime = cellStartTime.add(1, "day");

      if (events.length == 0) {
        return "unavailable";
      }

      if (
        events.find(
          (e) =>
            cellStartTime.isSameOrAfter(e.start, "seconds") &&
            cellEndTime.isSameOrBefore(e.end, "seconds")
        )
      ) {
        return "available";
      }

      return "partially-available";
    },
  },
};
</script>

<style lang="scss">
.loanable-calendar {
  background-color: $white;
  font-variant-numeric: tabular-nums;

  &__loading-spinner.spinner-border {
    position: absolute;
    right: -1.5rem;
    border-width: 2px;
    border-color: $grey;
    border-top-color: transparent;
  }

  // Hide now
  .vuecal__now-line {
    display: none;
  }

  &.vuecal {
    box-shadow: none;
  }

  .vuecal__title-bar {
    background-color: transparent;
  }

  &.loanable-calendar--small {
    font-size: 13px;
    .vuecal__title-bar,
    .vuecal__weekdays-headings {
      background-color: transparent;
      // Needs to be repeated for title-bar to overload .vuecal--xsmall .vuecal__title-bar
      font-size: 13px;
    }
    .vuecal__heading {
      height: inherit;
      min-height: 2em;
      line-height: 1.3;
    }
  }

  .vuecal__cell--selected {
    background-color: transparent;
  }
  .vuecal__cell--current,
  .vuecal__cell--today {
    background-color: rgba(240, 240, 255, 0.4);
  }
  .vuecal__event--focus,
  .vuecal__event:focus {
    box-shadow: none;
  }

  .vuecal__no-event {
    display: none;
  }

  .vuecal__cells.month-view,
  .vuecal__cells.week-view {
    .vuecal__cell {
      transition-duration: 0s;
      &:hover {
        background: transparentize($primary, 0.92);
      }
    }
  }

  // Month view.
  .vuecal__cells.month-view {
    .vuecal__cell {
      border-radius: 0.5rem;
      min-height: 3rem;

      &::before {
        border: none;
      }
    }

    .vuecal__cell--today {
      background-color: transparent;

      .loanable-calendar__cell-date-background svg {
        stroke: currentColor;
      }
      .loanable-calendar__cell-date--unavailable svg rect {
        fill: $beige;
      }
    }
  }

  &.loanable-calendar--small .vuecal__cells.month-view .vuecal__cell {
    min-height: 2rem;
  }

  .vuecal__cell--out-of-scope {
    // Disabled buttons have opacity: 0.65, but it does not feel clear enough here, hence 0.4.
    opacity: 0.4;
    .vuecal__event-time {
      // Do not display time on out of scope events since the multi-day events don't get the
      // start, middle and end classes we expect.
      display: none;
    }
  }

  .loanable-calendar__cell-date {
    position: relative;
    width: 100%;
    height: 100%;

    .loanable-calendar__cell-date-background {
      position: absolute;

      top: 0;
      left: 0;

      height: 100%;
      width: 100%;

      svg {
        height: 100%;
        width: 100%;
      }
    }

    .loanable-calendar__cell-date-content {
      position: absolute;
      top: 0;
      left: 0;

      height: 100%;
      width: 100%;

      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      justify-content: center;
    }

    &.loanable-calendar__cell-date--available {
      color: $content-alert-positive;

      svg rect {
        fill: $background-alert-positive;
      }
    }
    &.loanable-calendar__cell-date--partially-available {
      color: $content-alert-warning;

      svg path {
        fill: $background-alert-warning;
      }
    }
    &.loanable-calendar__cell-date--unavailable {
      color: $content-neutral-secondary;

      // Only fill for "today" so as not to introduce visual clutter.
      svg rect {
        fill: none;
      }
      .loanable-calendar__cell-date-content {
        text-decoration: line-through;
      }
    }
  }

  // Week and day views
  // Styling the time axis.
  .loanable-calendar__time-step--minutes {
    font-size: 0.7em;
  }

  .loanable-calendar__event.vuecal__event--background {
    opacity: 0.8;
    // Leave background events in the background.
    z-index: 0;
    &:hover,
    &:focus,
    &.vuecal__event--focus {
      z-index: 0;
    }
  }
  .loanable-calendar__event:not(.vuecal__event--background) {
    z-index: 3;
  }
  .loanable-calendar__event--availability {
    background-color: $background-alert-positive;
  }
  .loanable-calendar__event--unavailability {
    background-color: $background-alert-negative;
  }

  .loan-status-potential {
    color: $primary;
    background-color: rgba($primary, 0.2);
    border: 2px dashed $primary;
  }

  .vuecal__event:not(.vuecal__event--multiple-days) .vuecal__event-time__end::before {
    content: "-";
  }
  .vuecal__event--multiple-days.event-start {
    .vuecal__event-time__end {
      display: none;
    }
    .vuecal__event-time__span::before {
      content: "..";
    }
  }

  .vuecal__event--multiple-days.event-middle {
    .vuecal__event-time {
      display: none;
    }
  }
  .vuecal__event--multiple-days.event-end {
    .vuecal__event-time__start,
    .vuecal__event-time__span {
      display: none;
    }

    .vuecal__event-time__end::before {
      content: "..";
    }
  }
}

.styled-calendar-card {
  margin-top: 1rem;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: $small-shadow;
  font-size: 0.8rem;
  font-weight: 600;

  .vuecal__event {
    border-radius: 0.25rem;
    padding: 0.125rem;
  }

  .vuecal__cells.month-view {
    .vuecal__flex.vuecal__weekdays-headings {
      gap: 0.25rem;
      padding: 0 0.5rem;
    }
    .vuecal__cells {
      padding: 0.5rem;
      > .vuecal__flex {
        gap: 0.25rem;
      }
    }
    .vuecal__flex.vuecal__cell-content {
      gap: 0.25rem;
      justify-content: start;
      padding: 0.5rem 0 0.25rem;
    }

    .vuecal__event {
      margin: 0 0.125rem;
      width: unset;
    }

    .vuecal__event + .vuecal__event {
      margin-top: 0.25rem;
    }

    .vuecal__cell {
      width: unset;
      flex-basis: 13%;
      min-width: 13%;
      flex-grow: 1;
      //box-shadow: 0 0 1px rgb(163, 175, 184);
    }
  }

  .vuecal__event-title {
    font-weight: 600;
    font-size: 0.85rem;
  }
  .vuecal__event-time {
    font-size: 0.75rem;

    .circular-progress,
    .loan-status-icon {
      min-width: 0.7rem;
      font-size: 0.7rem;
      --stroke-width: 2px;
    }
  }
}
</style>
