<template>
  <div>
    <paginated-table
      id="admin-loans-table"
      :columns="columns"
      :extra-filters="extraFilters"
      :extra-data="[
        'loanable.active_incidents.status',
        'loanable.active_incidents.is_blocking',
        'community.deleted_at',
        'loanable.timezone',
        'extension_duration_in_minutes',
        'borrower_must_pay_compensation',
        'borrower_must_pay_insurance',
        'borrower_may_contribute',
        'borrower_validated_at',
        'owner_validated_at',
        'loanable.merged_user_roles.*',
        'loanable.merged_user_roles.id',
        'loanable.merged_user_roles.full_name',
      ]"
      endpoint="loans"
      :fetch-params="{ for: 'admin' }"
      :label="$tc('model_name', 2)"
      sync-filters-with-url
      :show-action-column="false"
      row-link
    >
      <template #cell(status)="{ item }">
        <loan-status :item="item" small></loan-status>
      </template>
    </paginated-table>
  </div>
</template>

<script>
import LoanStatus from "@/components/Loan/Status/LoanStatusBadge.vue";
import PaginatedTable from "@/components/shared/PaginatedTable.vue";
import { Column, Filter } from "@/components/shared/PaginatedTableColumns";
import { loanBorrowerLink, loanCommunityLink, loanLoanableLink, userLink } from "@/helpers/links";
import { capitalize } from "@/helpers/filters";
import { canSeeDeletedUser } from "@/helpers/permissions/users";
import locales from "@/locales";
import dayjs from "dayjs";

export default {
  name: "AdminLoans",
  components: {
    PaginatedTable,
    LoanStatus,
  },
  data() {
    const user = this.$store.state.user;
    return {
      columns: [
        new Column("id", "ID", "id"),
        new Column("departure_at", "Départ", "date", {
          formatter: (value, key, item) => {
            return dayjs.atTz(value, item.loanable.timezone).format("D MMMM YYYY HH:mm");
          },
        }),
        new Column("actual_return_at", "Retour", "date", {
          showByDefault: false,
          formatter: (value, key, item) =>
            dayjs.atTz(value, item.loanable.timezone).format("D MMMM YYYY HH:mm"),
        }),
        Column.withRelation(
          "borrower_user.full_name",
          "Emprunteur",
          {
            relation: "users",
            label: "full_name",
            field: "id",
            params: { for: "admin", with_deleted: canSeeDeletedUser(user) },
          },
          "borrower_user.id",
          {
            urlFct: loanBorrowerLink,
          }
        ),
        Column.withRelation(
          "loanable.owner_user.full_name",
          "Propriétaire",
          {
            relation: "users",
            label: "full_name",
            field: "id",
            params: { for: "admin", with_deleted: canSeeDeletedUser(user) },
          },
          "loanable.owner_user_id",
          {
            urlFct: (loan) => userLink(loan.loanable.owner_user),
            sortable: false,
          }
        ),
        Column.withRelation(
          "community.name",
          "Communauté",
          { relation: "communities", label: "name", field: "id", params: { for: "admin" } },
          "id",
          {
            urlFct: loanCommunityLink,
          }
        ),
        Column.withRelation(
          "loanable.name",
          "Véhicule",
          {
            relation: "loanables",
            label: "name",
            field: "id",
            params: { for: "admin", with_deleted: true },
          },
          "loanable.id",
          {
            urlFct: loanLoanableLink,
          }
        ),
        Column.withSelect("status", "Statut", [
          { value: null, label: "Tous" },
          { value: "requested", label: this.$t("statuses.requested.label") },
          { value: "accepted", label: this.$t("statuses.accepted.label") },
          { value: "confirmed", label: this.$t("statuses.confirmed.label") },
          { value: "ongoing", label: this.$t("statuses.ongoing.label") },
          { value: "ended", label: this.$t("statuses.ended.label") },
          { value: "validated", label: this.$t("statuses.validated.label") },
          { value: "completed", label: this.$t("statuses.completed.label") },
          { value: "rejected", label: this.$t("statuses.rejected.label") },
          { value: "canceled", label: this.$t("statuses.canceled.label") },
        ]),
        new Column("duration_in_minutes", "Durée (minutes)", "number", {
          showByDefault: false,
        }),
        new Column("calendar_days", "Nombre de jours", "number", { showByDefault: false }),
        Column.withSelect(
          "loanable.type",
          "Type de véhicule",
          [
            { value: null, label: "Tous" },
            { value: "car", label: "auto" },
            { value: "trailer", label: "Remorque" },
            { value: "bike", label: "vélo" },
          ],
          { showByDefault: false }
        ),

        Column.withSelect(
          "is_self_service",
          capitalize(this.$t("self_service.is_self_service")),
          [
            { value: null, label: capitalize(this.$t("self_service.all")) },
            { value: true, label: capitalize(this.$t("self_service.is_self_service")) },
            { value: false, label: capitalize(this.$t("self_service.on_demand")) },
          ],
          { showByDefault: false }
        ),
        Column.withSelect(
          "alternative_to",
          "Mode de transport remplacé",
          [
            { value: null, label: "Tous" },
            { value: "delivery", label: "Livraison", variant: "success" },
            { value: "car", label: "Auto", variant: "success" },
            { value: "public_transit", label: "Transport en commun", variant: "success" },
            { value: "bike", label: "Vélo", variant: "primary" },
            { value: "walking", label: "Marche", variant: "warning" },
            { value: "other", label: "Autre", variant: "secondary" },
          ],
          { showByDefault: false }
        ),
      ],
      extraFilters: [
        new Filter("incidents.status", "Incidents", "select", [
          { value: null, label: "Tous" },
          { value: "in_process", label: "En cours" },
          { value: "completed", label: "Résolu" },
          { value: "canceled", label: "Annulé" },
        ]),
        new Filter("extension_requested", "Demande de prolongation", "select", [
          { value: null, label: "Tous" },
          { value: true, label: "En cours" },
          { value: false, label: "Sans demande" },
        ]),
      ],
    };
  },
  i18n: {
    messages: {
      en: {
        ...locales.en.loans,
        ...locales.en.forms,
      },
      fr: {
        ...locales.fr.loans,
        ...locales.fr.forms,
      },
    },
  },
};
</script>

<style lang="scss"></style>
