<template>
  <div class="login-box">
    <invitation-header v-if="hasInvitation" :invitation="invitation" />
    <h2 v-else class="text-center">{{ $t("login") }}</h2>

    <div class="google-login">
      <google-auth-button :label="$t('google')" />
    </div>

    <div class="form__separator">
      <span class="form__separator__text">{{ $t("or") }}</span>
    </div>

    <b-form class="login-box__form" novalidate @submit.prevent="login">
      <b-form-group :label="$t('email')">
        <b-form-input
          v-model="email"
          name="email"
          autofocus
          type="email"
          required
          :placeholder="$t('email')"
        />
      </b-form-group>

      <b-form-group :label="$t('password')">
        <b-form-input
          v-model="password"
          name="password"
          type="password"
          required
          :placeholder="$t('password')"
        />
      </b-form-group>

      <b-form-group>
        <b-form-checkbox v-model="rememberMe" inline>
          {{ $t("remember_me") }}
        </b-form-checkbox>
      </b-form-group>

      <b-alert v-if="hasInvitation && !invitation.accountExists" show variant="warning">
        <p>
          L'invitation reçue ne correspond pas à un compte existant. Inscrivez-vous avec l'adresse
          courriel où l'invitation à été envoyée.
        </p>

        <icon-button variant="success" to="/register/1" replace>S'inscrire</icon-button>&nbsp;
        <icon-button variant="link" :disabled="loading" @click="clearInvitationAndLogin">
          Se connecter <strong>sans l'invitation</strong>
        </icon-button>
      </b-alert>

      <b-form-group v-else class="mb-1">
        <icon-button type="submit" :loading="loading" variant="success" block>
          {{ $t("login_submit") }}
        </icon-button>
      </b-form-group>
    </b-form>

    <div class="text-right">
      <router-link to="/password/request">
        <small>{{ $t("forgot_password") }}</small>
      </router-link>
    </div>
  </div>
</template>

<script>
import InvitationHeader from "@/components/Invitation/InvitationHeader.vue";
import GoogleAuthButton from "@/components/Misc/GoogleAuthButton.vue";
import IconButton from "@/components/shared/IconButton.vue";
import locales from "@/locales";

export default {
  name: "LoginBox",
  components: {
    IconButton,
    InvitationHeader,
    GoogleAuthButton,
  },
  data() {
    return {
      password: "",
    };
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    authUrl() {
      return `${process.env.VUE_APP_BACKEND_URL}/auth/google`;
    },
    email: {
      get() {
        return this.$store.state.login.email;
      },
      set(value) {
        return this.$store.commit("login/email", value);
      },
    },
    rememberMe: {
      get() {
        return this.$store.state.login.rememberMe;
      },
      set(value) {
        return this.$store.commit("login/rememberMe", value);
      },
    },
    invitation() {
      return this.$store.state.invitation;
    },
    hasInvitation() {
      return this.invitation.id && this.invitation.code;
    },
  },
  methods: {
    clearInvitationAndLogin() {
      this.$store.commit("invitation/clear");
      this.login();
    },
    async login() {
      try {
        await this.$store.dispatch("login", {
          email: this.email,
          password: this.password,
        });

        // We do not fully validate the invitation here prior to logging in, like we do on
        // registration, since blocking connection (when a user knows their credentials) creates
        // unneeded friction. During registration, invitation validation allows us to avoid wrong
        // accounts from being created.
        if (this.hasInvitation) {
          this.$router.replace({ path: "/invitation/accept" });
          return;
        }

        if (this.$route.query.r) {
          // Catch redirect exception since redirect is fully expected.
          this.$router.replace(this.$route.query.r).catch(() => {});
          return;
        }

        if (this.$store.state.user.role === "admin") {
          this.$router.replace("/admin/dashboard");
          return;
        }

        this.$router.replace("/app");
      } catch (e) {
        if (e.request?.status === 401) {
          this.$store.commit("addNotification", {
            content: "Nom d'utilisateur ou mot de passe invalide.",
            title: "Erreur de connexion.",
            variant: "danger",
            type: "login",
          });
        }
      }
    },
  },
  i18n: {
    messages: {
      fr: {
        ...locales.fr.components.login.box,
      },
      en: {
        ...locales.en.components.login.box,
      },
    },
  },
};
</script>

<style lang="scss">
.login-box {
  .login-box__form {
    margin-top: 2rem;
  }

  .google-login {
    margin-top: 2rem;
    text-align: center;
  }
}
</style>
