<template>
  <div class="loan-card">
    <validation-observer ref="observer" v-slot="{ passes }">
      <b-form :novalidate="true">
        <b-row>
          <b-col lg="6">
            <forms-validated-input
              name="departure_at"
              :label="$t('fields.departure_at') | capitalize"
              type="datetime"
              :disabled-dates-fct="disabledBeforeToday"
              :disabled-times-fct="disabledBeforeNow"
              :value="departureAt"
              :timezone="loanableTimezone"
              :clearable="false"
              :description="
                zonedTimes.differsFromCurrentTimezone
                  ? `Heure dans le fuseau horaire du véhicule: ${zonedTimes.timezoneAbbrev}`
                  : null
              "
              @input="changeSearchDate"
            >
              <template #footer>
                <button
                  class="mx-btn mx-btn-text"
                  @click="departureAt = getEarliestDeparture().toISOString()"
                >
                  Maintenant
                </button>
              </template>
            </forms-validated-input>
          </b-col>

          <b-col lg="6">
            <forms-validated-input
              v-model="returnAt"
              name="return_at"
              :label="$t('fields.return_at') | capitalize"
              type="datetime"
              :clearable="false"
              :disabled-dates-fct="disabledBeforeDepartureDay"
              :disabled-times-fct="disabledBeforeDeparture"
              :timezone="loanableTimezone"
              @input="changeSearchDuration"
            >
              <template #footer>
                <button class="mx-btn mx-btn-text" @click="() => setDuration(60)">1 heure</button>
                <button class="mx-btn mx-btn-text" @click="() => setDuration(60 * 3)">
                  3 heures
                </button>
                <button class="mx-btn mx-btn-text" @click="() => setDuration(60 * 24)">
                  1 jour
                </button>
              </template>
            </forms-validated-input>
          </b-col>
        </b-row>

        <b-row v-if="invalidDuration">
          <b-col>
            <b-alert show variant="danger">
              La durée de l'emprunt doit être supérieure ou égale à 15 minutes.
            </b-alert>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" :md="alternativeTo === 'other' ? 6 : 12">
            <forms-validated-input
              v-model="alternativeTo"
              name="alternative_to"
              :label="$t('fields.alternative_to') | capitalize"
              :rules="{
                required: true,
              }"
              type="select"
              :options="alternativeToOptions"
              @input="
                (value) => {
                  if (value !== 'other') {
                    alternativeToOther = null;
                  }
                }
              "
            />
          </b-col>
          <b-col v-if="alternativeTo === 'other'">
            <forms-validated-input
              v-model="alternativeToOther"
              name="alternative_to_other"
              :label="$t('fields.alternative_to_other') | capitalize"
              :rules="{
                required: true,
              }"
              type="text"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <forms-validated-input
              v-model="estimatedDistance"
              name="estimated_distance"
              :label="$t('fields.estimated_distance') | capitalize"
              type="text"
              :min="0"
              :rules="{ min_value: 0, required: true }"
              mask="#######"
              :formatter="integer"
            />
          </b-col>
        </b-row>

        <!-- No message for owner if the loanable is self service. -->
        <forms-validated-input
          v-if="!loanWillBeSelfService"
          v-model="messageForOwner"
          name="message_for_owner"
          :label="(`${$t('fields.message_for_owner')} ` + `(${$t('optional')})`) | capitalize"
          type="textarea"
          :rows="3"
        />
        <div class="text-center">
          <template v-if="!available">
            <div
              v-if="maxDurationInMinutes !== null && durationInMinutes > maxDurationInMinutes"
              class="muted small mb-2"
            >
              La durée maximale pour un emprunt de ce véhicule est de
              {{ maxDurationInMinutes | duration }}.
            </div>
            <icon-button v-if="!available" variant="primary" disabled type="submit">
              Indisponible
            </icon-button>
          </template>
          <icon-button
            v-else
            :onclick="async () => passes(createLoan)"
            variant="success"
            :disabled="estimating || invalidDuration || hasBlockingIncidents"
          >
            {{ loanWillBeSelfService ? "Réserver" : "Envoyer la demande d'emprunt" }}
          </icon-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import IconButton from "@/components/shared/IconButton.vue";
import { integer } from "@/helpers/filters";
import { getEarliestDeparture } from "@/helpers/loanDepartures";
import { getZonedTimes } from "@/helpers/loanTimezones";

import locales from "@/locales";

import { post } from "@/requests/server";
import dayjs from "dayjs";

export default {
  name: "NewLoanForm",
  components: {
    IconButton,
    FormsValidatedInput,
  },
  props: {
    loan: {
      type: Object,
      required: true,
    },
    loanableId: {
      type: [Number, String],
      required: true,
    },
    loanableTimezone: {
      type: String,
      required: true,
    },
    estimating: {
      type: Boolean,
      default: false,
    },
    available: {
      type: Boolean,
      default: true,
    },
    hasBlockingIncidents: {
      type: Boolean,
      default: false,
    },
    maxDurationInMinutes: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      departureAt: this.loan?.departure_at ?? dayjs().add(1, "hour").startOf("hour").toISOString(),
      durationInMinutes: this.loan?.duration_in_minutes ?? 15,
      estimatedDistance: this.loan?.estimated_distance ?? null,
      messageForOwner: null,
      alternativeTo: null,
      alternativeToOther: null,
      alternativeToOptions: [
        {
          text: "Livraison",
          value: "delivery",
        },
        {
          text: "Auto  (tout confondu : auto perso, location, autopartage, taxi, ...)",
          value: "car",
        },
        {
          text: "Transport en commun",
          value: "public_transit",
        },
        {
          text: "Vélo",
          value: "bike",
        },
        {
          text: "Marche",
          value: "walking",
        },
        {
          text: "Autre",
          value: "other",
        },
      ],
    };
  },
  computed: {
    returnAt: {
      get() {
        return dayjs(this.departureAt).add(this.durationInMinutes, "minute").toISOString();
      },
      set(val) {
        this.durationInMinutes = dayjs(val).diff(this.departureAt, "minute");
      },
    },
    zonedTimes() {
      return getZonedTimes(this.loan);
    },
    invalidDuration() {
      return this.durationInMinutes < 15;
    },
    loanWillBeSelfService() {
      return (
        this.loan.loanable.sharing_mode === "self_service" ||
        (this.loan.loanable.sharing_mode === "hybrid" &&
          this.$store.state.user?.trusted_for_loanables?.includes(this.loan.loanable.id))
      );
    },
  },
  methods: {
    getEarliestDeparture,
    dayjs,
    integer,
    async createLoan() {
      const { data } = await post(
        "/loans",
        {
          departure_at: this.departureAt,
          duration_in_minutes: this.durationInMinutes,
          estimated_distance: this.estimatedDistance,
          message_for_owner: this.messageForOwner,
          alternative_to: this.alternativeTo,
          alternative_to_other: this.alternativeToOther,
          loanable_id: this.loanableId,
          borrower_user_id: this.$store.state.user.id,
        },
        {
          notifications: { action: "demande d'emprunt" },
        }
      );

      this.$emit("input", data);
      this.$emit("estimate"); // reset estimation
    },
    disabledBeforeToday(date) {
      return dayjs().isAfter(dayjs(date).tz(this.loanableTimezone, true), "day");
    },
    disabledBeforeNow(date) {
      return dayjs().isAfter(dayjs(date).tz(this.loanableTimezone, true), "minutes");
    },
    disabledBeforeDeparture(date) {
      return dayjs(date).tz(this.loanableTimezone, true).isSameOrBefore(this.departureAt, "minute");
    },
    disabledBeforeDepartureDay(date) {
      return dayjs(date).tz(this.loanableTimezone, true).isBefore(this.departureAt, "day");
    },
    changeSearchDate(datetime) {
      if (this.invalidDuration) {
        // If duration is negative, we allow user to move each date independently
        const newDuration = dayjs(this.returnAt).diff(datetime, "minute");
        this.departureAt = datetime;
        this.durationInMinutes = newDuration;
      } else {
        this.departureAt = datetime;
      }

      this.$store.commit(
        "loanable.search/lastSearchDate",
        dayjs.atTz(datetime, this.loanableTimezone).format("YYYY-MM-DD HH:mm")
      );
      this.estimateLoan();
    },
    changeSearchDuration() {
      this.$store.commit("loanable.search/lastSearchDuration", this.durationInMinutes);
      this.estimateLoan();
    },
    setDuration(amount) {
      this.durationInMinutes = amount;
      this.changeSearchDuration();
    },
    async estimateLoan() {
      this.$emit("estimate", {
        departure_at: this.departureAt,
        duration_in_minutes: this.durationInMinutes,
        estimated_distance: this.estimatedDistance,
      });
    },
  },
  i18n: {
    messages: {
      en: {
        ...locales.en.loans,
        ...locales.en.forms,
      },
      fr: {
        ...locales.fr.loans,
        ...locales.fr.forms,
      },
    },
  },
};
</script>

<style lang="scss"></style>
