<script>
import DropdownSelect from "@/components/DropdownSelect.vue";
import FormsMapInput from "@/components/Forms/MapInput.vue";
import LoanIncidents from "@/components/Incident/LoanIncidents.vue";
import LoanButtons from "@/components/Loan/LoanButtons.vue";
import LoanContacts from "@/components/Loan/LoanContacts.vue";
import LoanDatesBox from "@/components/Loan/LoanDatesBox.vue";
import LoanFactorsBox from "@/components/Loan/LoanFactorsBox.vue";
import LoanPaymentBox from "@/components/Loan/LoanPaymentBox.vue";
import LoanPriceSummary from "@/components/Loan/LoanPriceSummary.vue";
import NewLoanForm from "@/components/Loan/NewLoanForm.vue";
import LoanStatusAlert from "@/components/Loan/Status/Alerts/LoanStatusAlert.vue";
import LoanableIcon from "@/components/Loanable/LoanableIcon.vue";
import PrettyDescription from "@/components/Loanable/PrettyDescription.vue";
import IconButton from "@/components/shared/IconButton.vue";
import ImageCarousel from "@/components/shared/ImageCarousel.vue";
import MarkdownContent from "@/components/shared/MarkdownContent.vue";
import MarkdownEditor from "@/components/shared/MarkdownEditor.vue";
import TabLayout from "@/components/shared/TabLayout.vue";
import UserComment from "@/components/shared/UserComment.vue";
import { debounce } from "@/helpers/debounce";
import { date, datetime, time } from "@/helpers/filters";
import { getEarliestDeparture } from "@/helpers/loanDepartures";
import { isCoownerOrOwner } from "@/helpers/permissions/loanables";
import {
  canViewLoanInstructions,
  isBorrower,
  isLoanAdmin,
  loanBlockedByIncident,
} from "@/helpers/permissions/loans";
import { isGlobalAdmin } from "@/helpers/permissions/users";
import locales from "@/locales";
import { estimate, test } from "@/requests/loanRequests";
import { del, get, post, put } from "@/requests/server";
import { isCancel } from "axios";
import dayjs from "dayjs";

export default {
  name: "FullLoan",
  components: {
    MarkdownContent,
    MarkdownEditor,
    LoanButtons,
    LoanContacts,
    LoanStatusAlert,
    LoanIncidents,
    DropdownSelect,
    NewLoanForm,
    LoanPriceSummary,
    TabLayout,
    LoanableIcon,
    LoanDatesBox,
    LoanFactorsBox,
    IconButton,
    PrettyDescription,
    ImageCarousel,
    UserComment,
    LoanPaymentBox,
    FormsMapInput,
  },
  props: {
    loanId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      loan: null,
      isEstimating: false,
      estimation: null,
      loanLoadedAt: null,
      failedLoadingLoanable: false,
      estimationParams: {
        departure_at: null,
        duration_in_minutes: null,
        estimated_distance: null,
        expenses_amount: null,
        mileage_end: null,
        mileage_start: null,
        platform_tip: null,
      },
      notificationOptions: [
        {
          value: "all",
          label: "toutes",
        },
        {
          value: "messages_only",
          label: "que les messages",
        },
        {
          value: "none",
          label: "aucune",
        },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    isBorrower() {
      return isBorrower(this.user, this.loan);
    },
    isCoownerOrOwner() {
      return isCoownerOrOwner(this.user, this.loan.loanable);
    },
    isGlobalAdmin() {
      return isGlobalAdmin(this.user);
    },
    isLoanAdmin() {
      return isLoanAdmin(this.user, this.loan);
    },
    isAvailable() {
      return !this.estimation || this.estimation.available;
    },
    borrowerInvoice() {
      return this.estimation ? this.estimation.borrower_invoice : this.loan.borrower_invoice;
    },
    ownerInvoice() {
      return this.estimation ? this.estimation.owner_invoice : this.loan.owner_invoice;
    },
    nextloan() {
      return this.estimation ? this.estimation.blocking_loan : null;
    },
    desiredContribution() {
      return this.estimation
        ? this.estimation.desired_contribution
        : this.loan.desired_contribution;
    },
    isNewLoan() {
      return this.loanId === "new";
    },
    tabs() {
      const tabs = [
        {
          key: "loan",
          label: "emprunt",
          activeIcon: "calendar2-check-fill",
          icon: "calendar2-check",
        },
      ];

      if (!this.isNewLoan) {
        tabs.push({
          key: "messages",
          label: "messages",
          activeIcon: "chat-dots-fill",
          icon: "chat-dots",
          badge: this.unreadMessagesCount || null,
        });

        let contributionLabel = "contribution";

        if ((this.isCoownerOrOwner && !this.isBorrower) || !this.loan.borrower_may_contribute) {
          contributionLabel = "compensation";
        }

        if (
          this.loan.borrower_must_pay_insurance ||
          this.loan.borrower_must_pay_compensation ||
          this.loan.borrower_may_contribute
        ) {
          tabs.push({
            key: "contribution",
            label: contributionLabel,
            icon: "currency-dollar",
            // The contribution tab content's will set the platform_tip to the desired_platform_tip
            // if no tip is present. By making it lazy, this only happens when navigating to that tab
            lazy: true,
          });
        }
      }

      tabs.push({
        key: "loanable",
        label: this.$t(`loanables.fields.types.${this.loan.loanable.type}`),
      });
      return tabs;
    },
    contributionEstimated() {
      return (
        this.estimationParams.platform_tip !== undefined &&
        this.estimationParams.platform_tip !== null
      );
    },
    timesEstimated() {
      return !!(this.estimationParams.duration_in_minutes || this.estimationParams.departure_at);
    },
    distanceEstimated() {
      return !!(
        this.estimationParams.mileage_start ||
        this.estimationParams.mileage_end ||
        this.estimationParams.expenses_amount ||
        this.estimationParams.estimated_distance
      );
    },

    meaningfulIncidents() {
      // If loan is in process all blocking incidents are meaningful.
      // All incidents having this loan as source are meaningful (blocking or not).
      return (
        this.loan.loanable.active_incidents?.filter(
          (incident) =>
            (incident.loan_id && incident.loan_id === this.loan.id) ||
            (this.loan.status !== "completed" && loanBlockedByIncident(this.loan, incident))
        ) ?? []
      );
    },
    unreadMessagesCount() {
      return this.loan.comments.filter(
        (c) =>
          (this.user.id !== c.author.id && !this.loan.notification_last_seen) ||
          dayjs(this.loan.notification_last_seen).isBefore(c.created_at)
      ).length;
    },
    showInstructions() {
      return (
        canViewLoanInstructions(this.user, this.loan) &&
        ["confirmed", "ongoing", "ended", "validated"].includes(this.loan.status)
      );
    },
    instructions() {
      if (this.loan.is_borrower_trusted && this.loan.loanable.trusted_borrower_instructions) {
        return this.loan.loanable.trusted_borrower_instructions;
      }
      return this.loan.loanable.instructions;
    },
  },
  created() {
    this.debouncedEstimate = debounce(this.estimate);
    this.loadLoan();
  },
  methods: {
    time,
    date,
    datetime,
    async loadLoan() {
      if (this.loanId === "new") {
        this.loan = this.$store.state.loans.item || {};
        if (!this.loan.borrower_user) {
          this.loan.borrower_user = this.$store.state.user;
        }

        this.loan.borrower_invoice = {};
        this.estimation = {
          borrower_invoice: {},
        };

        if (!this.loan.loanable) {
          if (!this.$route.query.loanable_id) {
            await this.$router.push("/search/map");
            return;
          }

          try {
            const { data } = await get(`/loanables/${this.$route.query.loanable_id}?ressource=1`, {
              // no notifications, we handle it with UI
            });
            this.loan.loanable = data;
          } catch (error) {
            this.failedLoadingLoanable = true;
            return;
          }
        } else if (this.$route.query.loanable_id !== this.loan.loanable.id) {
          // Place the loanable id to the url, so we can refresh the page without losing context
          await this.$router.replace({
            query: {
              loanable_id: this.loan.loanable.id,
            },
          });
        }

        if (!this.loan.departure_at) {
          this.loan.departure_at = this.$store.state["loanable.search"].lastSearchDate;
        }

        const minimumTime = getEarliestDeparture();
        if (!this.loan.departure_at || minimumTime.isAfter(this.loan.departure_at)) {
          this.loan.departure_at = minimumTime.format("YYYY-MM-DD HH:mm");
        }
        // Make sure date is in ISO format in the loanable timezone.
        this.loan.departure_at = dayjs
          .atTz(this.loan.departure_at, this.loan.loanable.timezone)
          .toISOString();

        if (!this.loan.duration_in_minutes) {
          this.loan.duration_in_minutes = this.$store.state["loanable.search"].lastSearchDuration;
        }

        if (!this.loan.duration_in_minutes) {
          this.loan.duration_in_minutes = 15;
        }

        this.setNewLoanEstimation(this.loan);
        this.loading = false;
        return;
      }

      this.loading = true;

      const { data } = await get(`/loans/${this.loanId}`, {
        cleanupCallback: () => (this.loading = false),
        notifications: { action: "chargement de l'emprunt" },
      });

      this.setLoan(data, {
        refreshDashboard: false,
        freshlyLoaded: true,
      });
    },
    async reloadSilently() {
      const { data } = await get(`/loans/${this.loanId}`, {
        cleanupCallback: () => (this.loading = false),
        // no notifications for this silent reload
      });

      this.setLoan(data);
    },
    flashSection(element) {
      element.classList.add("flash");
      setTimeout(() => element.classList.remove("flash"), 1500);
    },
    async addComment(text) {
      const { data } = await post(
        `/loans/${this.loan.id}/comment`,
        {
          text,
        },
        {
          notifications: { action: "envoi du message" },
        }
      );
      this.loan.comments.push(data);
      this.loan.notification_last_seen = dayjs().toISOString();

      if (!this.loan.notification_subscription || this.loan.notification_subscription === "none") {
        this.loan.notification_subscription = "messages_only";
      }
    },
    async deleteComment(comment) {
      const { data } = await del(`/loans/${this.loan.id}/comment/${comment.id}`, {
        notifications: { action: "suppression du message" },
      });
      this.loan.comments = this.loan.comments.map((c) => {
        if (c.id === comment.id) {
          return data;
        }
        return c;
      });
    },
    jump(target) {
      let open = true;

      if (typeof target === "object") {
        open = target.open;
        target = target.target;
      }
      target = `loan-section-${target}`;

      if (target === "loan-section-instructions" || target === "loan-section-return-instructions") {
        this.$refs.tabs.jumpToTab("loanable");
      } else if (target === "loan-section-contribution") {
        this.$refs.tabs.jumpToTab("contribution");
      } else if (target === "loan-section-messages" || target === "loan-section-contacts") {
        this.$refs.tabs.jumpToTab("messages");
      } else if (
        target === "loan-section-dates" ||
        target === "loan-section-info" ||
        target === "loan-section-loan" ||
        target === "loan-section-incidents"
      ) {
        this.$refs.tabs.jumpToTab("loan");
      }

      // Timeout since tab might not yet be rendered (e.g. lazy loaded contribution tab)
      setTimeout(() => {
        let destinationElement = null;
        const elements = document.getElementsByClassName(target);
        for (const element of elements) {
          if (getComputedStyle(element).display !== "none") {
            destinationElement = element;
            break;
          }
        }
        if (!destinationElement) {
          return;
        }

        setTimeout(() => {
          let rect = destinationElement.getBoundingClientRect();

          let isFullyInView =
            rect.top >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight);

          if (!isFullyInView) {
            this.$scrollTo(destinationElement, { offset: -70 });
          }
        }, 50);

        this.flashSection(destinationElement);
        if (open) {
          if (target === "loan-section-info") {
            this.$refs.infoForm.open();
          }
          if (target === "loan-section-dates") {
            this.$refs.datesForm.open();
          }
        }
      }, 30);
    },
    setNewLoanEstimation(newLoanData = null) {
      this.estimationParams.departure_at = newLoanData?.departure_at;
      this.estimationParams.duration_in_minutes = newLoanData?.duration_in_minutes;
      this.estimationParams.estimated_distance = newLoanData?.estimated_distance;
      this.estimationParams.borrower_user_id = newLoanData ? this.user.id : undefined;
      this.estimationParams.loanable_id = newLoanData ? this.loan.loanable.id : undefined;

      if (newLoanData) {
        // for new loans, we can update the displayed loan to match the form
        this.loan = {
          ...this.loan,
          ...this.estimationParams,
        };
      }

      this.estimate();
    },
    setEstimationTip(platformTip = null) {
      this.estimationParams.platform_tip = platformTip;
      this.estimate();
    },
    setEstimationTimes({ departureAt, durationInMinutes } = {}) {
      this.estimationParams.departure_at = departureAt;
      this.estimationParams.duration_in_minutes = durationInMinutes;
      this.estimate();
    },
    setEstimationDistanceAndExpense({
      mileageStart,
      mileageEnd,
      expensesAmount,
      estimatedDistance,
    } = {}) {
      this.estimationParams.estimated_distance = estimatedDistance;
      this.estimationParams.mileage_start = mileageStart;
      this.estimationParams.mileage_end = mileageEnd;
      this.estimationParams.expenses_amount = expensesAmount;
      this.estimate();
    },

    async estimate() {
      let nonNullParams = {};
      for (const paramKey in this.estimationParams) {
        if (
          this.estimationParams[paramKey] !== null &&
          this.estimationParams[paramKey] !== undefined
        ) {
          nonNullParams[paramKey] = this.estimationParams[paramKey];
        }
      }

      // All estimation params have been reset
      if (Object.keys(nonNullParams).length === 0) {
        this.estimation = null;
        this.isEstimating = false;
        return;
      }

      this.isEstimating = true;
      try {
        let estimation = null;
        if (this.isNewLoan) {
          estimation = await test(this.estimationParams);
        } else {
          estimation = await estimate({
            ...this.loan,
            ...nonNullParams,
          });
        }
        // Avoid setting estimation if we're no longer estimating (e.g. the loan has been updated)
        if (this.isEstimating) {
          this.estimation = estimation;
        }
        this.isEstimating = false;
      } catch (e) {
        if (!isCancel(e)) {
          this.isEstimating = false;
        }
      }
    },
    setLoan(loan, { refreshDashboard = true, freshlyLoaded = true } = {}) {
      this.loan = loan;
      if (freshlyLoaded) {
        this.loanLoadedAt = dayjs().toISOString();
      }

      if (refreshDashboard) {
        this.$store.dispatch("dashboard/loadLoans");
      }

      // If confirmed or ongoing, reload loans when possible
      if (loan.status === "confirmed") {
        const loanStartInMs = dayjs(loan.departure_at).diff(dayjs(), "ms");
        if (loanStartInMs < 1000 * 60 * 90 && loanStartInMs > 0) {
          setTimeout(() => this.reloadSilently(), loanStartInMs + 30 * 1000);
        }
      } else if (loan.status === "ongoing") {
        const loanEndInMs = dayjs(loan.actual_return_at).diff(dayjs(), "ms");
        if (loanEndInMs < 1000 * 60 * 60 && loanEndInMs > 0) {
          setTimeout(() => this.reloadSilently(), loanEndInMs + 30 * 1000);
        }
      }

      if (this.isNewLoan) {
        this.$router.replace(`/loans/${loan.id}`);

        // When creating a self-service loan, jump to contribution if possible
        if (
          (this.loan.status === "confirmed" || this.loan.status === "accepted") &&
          this.loan.borrower_may_contribute
        ) {
          // Timeout to give time for the new tabs to render
          setTimeout(() => this.jump("contribution"), 250);
        }
      }
    },
    async updateNotificationSubscription(level) {
      await put(
        `/loans/${this.loan.id}/notifications`,
        { level },
        {
          notifications: {
            action: "modification des notifications",
          },
        }
      );
      this.loan.notification_subscription = level;
    },
    onTabActivated(tab) {
      if (tab === "messages") {
        this.markNotificationSeen();
      }
    },
    async markNotificationSeen() {
      await put(
        `/loans/${this.loan.id}/notifications/seen`,
        {
          seen_at: this.loanLoadedAt,
        },
        {
          // no notifications for this action
        }
      );
      this.loan.notification_last_seen = dayjs().toISOString();
    },
  },
  i18n: {
    messages: {
      en: {
        loanables: locales.en.loanables,
        bill_items: locales.en.bill_items,
        ...locales.en.loans,
      },
      fr: {
        loanables: locales.fr.loanables,
        bill_items: locales.fr.bill_items,
        ...locales.fr.loans,
      },
    },
  },
};
</script>

<template>
  <div v-if="failedLoadingLoanable" class="text-center">
    <p>
      Erreur de chargement du véhicule pour cet emprunt. Il se peut que vous n'y ayez pas accès.
    </p>
    <icon-button variant="primary" icon="search" @click="() => $router.push('/search/map')"
      >Emprunter un autre véhicule</icon-button
    >
  </div>
  <layout-loading v-else-if="loading"></layout-loading>
  <div v-else-if="loan" class="loan-page">
    <b-row class="main-row">
      <b-col cols="12" lg="4" class="first-col">
        <div class="loan-context">
          <image-carousel
            v-if="loan.loanable && loan.loanable.images && loan.loanable.images.length > 0"
            class="carousel"
            :images="loan.loanable.images"
            aspect-ratio="16 / 10"
            :preferred-sizes="['loan', 'thumbnail', 'original']"
          />
          <loanable-icon v-else :loanable="loan.loanable" class="loanable-image-default" />
          <div class="loan-context-overlay">
            <div class="loan-context-label">
              <b-link
                v-if="isLoanAdmin || isCoownerOrOwner"
                :to="`/loanables/${loan.loanable.id}`"
                class="loan-context-loanable-name"
              >
                {{ loan.loanable.name }}
              </b-link>
              <span v-else class="loan-context-loanable-name">
                {{ loan.loanable.name }}
              </span>
              <br />
              <span class="loan-context-time">{{ loan.departure_at | datetime }}</span>
            </div>
          </div>
        </div>
        <loan-price-summary
          class="d-none d-lg-block"
          :owner-invoice="ownerInvoice"
          :borrower-invoice="borrowerInvoice"
          :loan="loan"
          :is-new-loan="isNewLoan"
          :estimating="isEstimating"
          :estimated-contribution="contributionEstimated"
          :estimated-distance="distanceEstimated"
          :estimated-duration="timesEstimated"
          @jump="jump"
        />
        <loan-buttons class="d-none d-lg-flex" :loan="loan" @input="setLoan" @jump="jump" />
        <loan-contacts
          :loan="loan"
          class="loan-section-contacts d-none d-lg-block"
          @input="setLoan"
        />
      </b-col>
      <b-col cols="12" lg="8">
        <tab-layout ref="tabs" :tabs="tabs" @tab-activated="onTabActivated">
          <template #icon-loanable>
            <loanable-icon :loanable="loan.loanable" />
          </template>

          <template #tab-loan>
            <div class="loan-section-incidents">
              <!--               Todo: move meaningful incidents-->
              <loan-incidents
                v-if="meaningfulIncidents && meaningfulIncidents.length > 0"
                :loan="loan"
                :incidents="meaningfulIncidents"
              />
            </div>
            <loan-status-alert :loan="loan" @jump="jump" @input="setLoan" />
            <new-loan-form
              v-if="isNewLoan"
              :estimating="isEstimating"
              :loanable-id="loan.loanable.id"
              :available="isAvailable"
              :loan="loan"
              :loanable-timezone="loan.loanable.timezone"
              :max-duration-in-minutes="loan.loanable.max_loan_duration_in_minutes"
              @estimate="setNewLoanEstimation"
              @input="setLoan"
            />
            <template v-else>
              <div class="loan-section-dates">
                <loan-dates-box
                  ref="datesForm"
                  class="loan-dates-form loan-section"
                  :loan="loan"
                  :is-estimating="isEstimating"
                  :available="isAvailable"
                  :next-loan="nextloan"
                  :max-duration-in-minutes="loan.loanable.max_loan_duration_in_minutes"
                  @estimate="setEstimationTimes"
                  @input="setLoan"
                  @jump="jump"
                />
              </div>
              <div v-if="loan.can_add_expenses || loan.requires_mileage" class="loan-section-info">
                <loan-factors-box
                  ref="infoForm"
                  :loan="loan"
                  :borrower-invoice="borrowerInvoice"
                  :owner-invoice="ownerInvoice"
                  @input="setLoan"
                  @estimate="setEstimationDistanceAndExpense"
                />
              </div>
            </template>
            <loan-price-summary
              class="d-lg-none"
              :owner-invoice="ownerInvoice"
              :borrower-invoice="borrowerInvoice"
              :loan="loan"
              :is-new-loan="isNewLoan"
              :estimating="isEstimating"
              :estimated-contribution="contributionEstimated"
              :estimated-distance="distanceEstimated"
              :estimated-duration="timesEstimated"
              @jump="jump"
            />

            <loan-buttons class="d-lg-none" :loan="loan" @input="setLoan" @jump="jump" />

            <div v-if="!isNewLoan" class="text-center text-muted">
              <small
                >Que pensez-vous de la nouvelle interface d'emprunt?
                <b-link href="https://forms.gle/6KaJxFgXvmJvQ5xF7" target="_blank"
                  >Partagez-nous vos commentaires ici.</b-link
                >
              </small>
            </div>
          </template>

          <template #tab-contribution>
            <div class="loan-section-contribution">
              <loan-payment-box
                :loan="loan"
                :owner-invoice="ownerInvoice"
                :borrower-invoice="borrowerInvoice"
                :is-changing-distance="distanceEstimated"
                :is-changing-duration="timesEstimated"
                :desired-contribution="desiredContribution"
                @estimate="setEstimationTip"
                @input="setLoan"
                @jump="jump"
              />
            </div>
          </template>

          <template #tab-loanable>
            <div v-if="showInstructions && instructions" class="loan-section-instructions">
              <div class="card">
                <h3><b-icon icon="info-circle-fill" />&nbsp;Instructions</h3>
                <markdown-content :content="instructions" />
              </div>
            </div>
            <div
              v-if="showInstructions && loan.loanable.return_instructions"
              class="loan-section-return-instructions"
            >
              <div class="card">
                <h3><b-icon icon="skip-start-circle-fill" />&nbsp;Instructions au retour</h3>
                <markdown-content :content="loan.loanable.return_instructions" />
              </div>
            </div>
            <div>
              <h3>Emplacement</h3>
              <div>
                <p>
                  <template v-if="loan.loanable.location_description">{{
                    loan.loanable.location_description
                  }}</template>
                </p>
                <div class="text-center">
                  <icon-button
                    :href="`https://www.google.com/maps/search/?api=1&query=${loan.loanable.position[0]}%2C${loan.loanable.position[1]}`"
                    target="_blank"
                    variant="ghost-secondary"
                    size="sm"
                    icon="map"
                    >Repérer le véhicule (Google Maps)</icon-button
                  >
                </div>

                <forms-map-input
                  v-if="loan.loanable.position"
                  class="loan-loanable-map"
                  :loanable="loan.loanable"
                  :value="loan.loanable.position"
                  disabled
                  bounded
                  allow-fullscreen
                  @touchstart.native.stop
                />
              </div>
            </div>
            <pretty-description class="loan-informative-card" :loanable="loan.loanable" />
          </template>

          <template #tab-messages>
            <loan-contacts :loan="loan" class="loan-section-contacts d-lg-none" @input="setLoan" />
            <div class="loan-section-messages">
              <h3 class="d-lg-none">Messages</h3>
              <div class="loan-messages-card">
                <div class="messages-list">
                  <transition-group
                    v-if="loan.comments && loan.comments.length > 0"
                    name="comment-list"
                    tag="div"
                  >
                    <user-comment
                      v-for="comment of loan.comments"
                      :key="comment.id"
                      class="loan-comment"
                      :text="comment.text"
                      :date="comment.created_at"
                      :updated-date="comment.updated_at"
                      :user="comment.author"
                      :deleted="!!comment.deleted_at"
                      :can-destroy="comment.author.id === user.id || isGlobalAdmin"
                      :destroy="async () => deleteComment(comment)"
                    />
                  </transition-group>

                  <markdown-editor
                    :save="addComment"
                    :placeholder="
                      isBorrower
                        ? 'Écrire aux responsables...'
                        : isCoownerOrOwner
                        ? `Écrire à ${loan.borrower_user.name}...`
                        : 'Envoyer un message'
                    "
                  />
                </div>

                <div class="text-right">
                  <dropdown-select
                    class="mt-2"
                    :icon="loan.notification_subscription === 'none' ? 'bell-slash' : 'bell'"
                    label="Notifications"
                    size="sm"
                    :value="loan.notification_subscription"
                    :options="notificationOptions"
                    :onselect="updateNotificationSubscription"
                  />
                </div>
              </div>
            </div>
          </template>
        </tab-layout>
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";
.loan-page {
  h3 {
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin-bottom: 0.5rem;
    font-weight: 400;
  }

  .alert {
    margin-bottom: 0;
  }

  .loan-card {
    border-radius: 1rem;
    background: $white;
    padding: 1rem;
    box-shadow: 0 0 0 0 transparent;
    transition: all 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
    border: 1px solid $light-grey;
    &.is-editing {
      border: 1px solid $primary;
      box-shadow: $small-shadow;
    }

    .messages-list {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }
  }

  .loan-messages-card {
    padding: 1rem;
    background: $white;
    border: 1px solid $light-grey;
    border-radius: 1rem;
  }

  .loan-informative-card {
    margin: 1rem -15px 0;
    padding: 0;
  }

  .card {
    transition: all 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
  }

  .flash .loan-card,
  .flash .card {
    border: 1px solid $primary;
    box-shadow:
      0 0 0.25rem $primary,
      0 0 0 0.5rem transparentize($primary, 0.75);
  }

  .tab-layout-content > * {
    margin-top: 1rem;
  }
}
</style>

<style scoped lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";

.loan-page {
  padding-bottom: 3rem;

  @include media-breakpoint-down(md) {
    max-width: 400px;
    margin: 0 auto;
  }

  .first-col {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .loanable-image-default {
    background: $locomotion-green;
    fill: $locomotion-dark-green;
  }

  .loan-section-instructions,
  .loan-section-return-instructions {
    .card {
      border: 1px solid $primary;
      background: mix($primary, $white, 2%);
      padding: 1rem;
      border-radius: 1rem;

      h3 {
        font-weight: 600;
        color: $primary;
      }
    }
  }

  .loan-context {
    display: block;
    view-transition-name: loanable-image;

    @include media-breakpoint-down(md) {
      margin: 0 -15px 0;
    }

    position: relative;
    .loan-context-overlay {
      position: absolute;
      pointer-events: none;

      @include media-breakpoint-up(lg) {
        border-radius: 1rem;
        overflow: hidden;
      }
      max-width: 100%;
      padding: 1rem;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      height: 100%;
      margin: auto;
      background: transparentize($main-bg, 0.3);
      display: flex;
      background: linear-gradient(
        5deg,
        hsla(170, 8.11%, 29.02%, 0.73) 0%,
        hsla(170.77, 8.84%, 28.82%, 0.722) 8.1%,
        hsla(171.43, 9.72%, 28.24%, 0.701) 15.5%,
        hsla(170, 12.86%, 27.45%, 0.669) 22.5%,
        hsla(168.57, 15.56%, 26.47%, 0.627) 29%,
        hsla(170, 18.75%, 25.1%, 0.577) 35.3%,
        hsla(168.89, 22.31%, 23.73%, 0.522) 41.2%,
        hsla(168, 26.32%, 22.35%, 0.464) 47.1%,
        hsla(168.75, 30.19%, 20.78%, 0.406) 52.9%,
        hsla(169.41, 34.69%, 19.22%, 0.348) 58.8%,
        hsla(168, 38.46%, 17.84%, 0.293) 64.7%,
        hsla(168.33, 42.86%, 16.47%, 0.243) 71%,
        hsla(168, 44.3%, 15.49%, 0.201) 77.5%,
        hsla(168, 46.67%, 14.71%, 0.169) 84.5%,
        hsla(168.33, 50%, 14.12%, 0.148) 91.9%,
        hsla(168, 49.3%, 13.92%, 0.14) 100%
      );

      .loan-context-label {
        color: white;
        align-self: flex-end;
        pointer-events: all;
        a {
          color: white;
          text-decoration: underline;
        }
      }
      .loan-context-time {
        font-size: 1.2rem;
        font-weight: bold;
      }
    }
  }
}

.loan-comment {
  transition:
    opacity 0.3s,
    transform 0.3s;
}

.comment-list-enter {
  opacity: 0;
  transform: translateY(2rem);
}

@include media-breakpoint-up(lg) {
  .main-row {
    flex-direction: row-reverse;
  }
  .carousel,
  .loanable-image-default {
    border-radius: 1rem;
    box-shadow: $small-shadow;
    overflow: hidden;
  }
  .loanable-image-default {
    padding: 2rem;
    aspect-ratio: 16 / 10;
  }
}

.loan-loanable-overview {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.loan-loanable-map {
  box-shadow: $small-shadow;
  border-radius: 1rem;
  border: none;
  margin-top: 0.5rem;
}

@include media-breakpoint-down(sm) {
  .loan-loanable-overview {
    display: flex;
    flex-direction: column;
    .loanable-picture {
      align-self: center;
    }
    .carousel {
      width: 100%;
    }
  }
}
</style>
