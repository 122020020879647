<template>
  <div v-if="itemLoaded && routeDataLoaded">
    <b-row>
      <b-col>
        <h1 v-if="item.full_name">{{ item.full_name }}</h1>
        <h1 v-else>
          <em>{{ $tc("model_name", 1) | capitalize }}</em>
        </h1>
        <icon-button
          v-if="isGlobalAdmin && item.id"
          variant="outline-warning"
          size="sm"
          icon="person-badge"
          @click="mandate"
          >Connexion</icon-button
        >
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <validation-observer ref="observer" v-slot="{ passes }">
          <b-form class="form" @submit.prevent="checkInvalidThenSubmit(passes)">
            <div class="form__section">
              <h2>Informations générales</h2>

              <div>
                <label>Compte créé le</label>
                {{ item.created_at | date }}
              </div>
              <hr />

              <forms-builder
                v-model="item"
                :disabled="!canEditUser"
                :definition="form.general"
                entity="users"
                :hide-fields="
                  canEditUser
                    ? []
                    : [
                        'bank_transit_number',
                        'bank_institution_number',
                        'bank_account_number',
                        'accept_conditions',
                        'is_smart_phone',
                        'other_phone',
                      ]
                "
              />
            </div>

            <div v-if="canManageGlobalAdmins" class="form__section">
              <h2>Administrateur global</h2>

              <b-alert show variant="danger">
                Attention! N'accordez pas le rôle d'administration globale à la légère: un-e
                administrateur-rice global-e peut <strong>tout</strong> faire avec un minimum de
                validations.
              </b-alert>

              <b-form-select
                id="role"
                v-model="item.role"
                name="role"
                :options="[
                  { value: null, text: 'Régulier' },
                  { value: 'admin', text: 'Admin global' },
                ]"
              />
            </div>

            <div v-if="canChangeUserPassword" class="form__section">
              <h2>Mot de passe</h2>

              <user-password-form
                ref="passwordForm"
                :loading="loading"
                :required="false"
                :must-validate-current="loggedInUserIsMe"
                :user="item"
                @updated="resetPasswordFormAndShowModal"
              />

              <b-modal
                id="password-change-modal"
                size="md"
                header-bg-variant="success"
                title-class="font-weight-bold"
                ok-only
                no-close-on-backdrop
                no-close-on-esc
                hide-header-close
                :title="$t('password_change.title')"
              >
                <!-- eslint-disable-next-line vue/no-v-html-->
                <div v-html="$t('password_change.content')" />
              </b-modal>
            </div>

            <div v-if="canEditDriversProfile" class="form__section">
              <a id="borrower" />
              <h2>Dossier de conduite</h2>

              <p><strong>Statut:</strong> {{ borrowerStatus }}</p>

              <p v-if="item.borrower" class="button-list align-items-start">
                <icon-button
                  v-if="!item.borrower.approved_at"
                  variant="outline-success"
                  :disabled="loading"
                  :onclick="approveBorrower"
                  icon="check2-square"
                >
                  {{ $t("approve") | capitalize }}
                </icon-button>
                <icon-button
                  v-else-if="!item.borrower.suspended_at"
                  variant="outline-warning"
                  :disabled="loading"
                  :onclick="suspendBorrower"
                  icon="pause-circle"
                >
                  {{ $t("suspend") | capitalize }}
                </icon-button>
                <icon-button
                  v-else
                  variant="outline-success"
                  :disabled="loading"
                  :onclick="unsuspendBorrower"
                  icon="play-circle"
                >
                  {{ $t("reinstate") | capitalize }}
                </icon-button>

                <template v-if="item.borrower.submitted_at">
                  <div
                    v-if="!item.borrower.approved_at"
                    class="d-flex flex-column align-items-center"
                  >
                    <icon-button variant="ghost-danger" icon="person-x" :onclick="resetBorrower">
                      Dossier non valide
                    </icon-button>
                    <small class="text-muted">(n'envoie pas de courriel)</small>
                  </div>
                  <icon-button v-else role="reset" :disabled="loading" :onclick="resetBorrower">
                    {{ $t("reset") | capitalize }}
                  </icon-button>
                </template>
              </p>

              <forms-builder
                v-model="item.borrower"
                :definition="form.borrower"
                entity="borrowers"
              />
            </div>

            <div v-if="!!item.id" class="form__section">
              <paginated-table
                id="admin-user-loans-table"
                endpoint="loans"
                :fetch-params="{ forUser: item.id, for: 'admin' }"
                :columns="loanColumns"
                :extra-filters="loansExtraFilters"
                :extra-data="[
                  'incidents.status',
                  'extensions.status',
                  'loanable.timezone',
                  'loanable.merged_user_roles.*',
                  'loanable.merged_user_roles.user.id',
                  'loanable.merged_user_roles.user.full_name',
                ]"
                row-link
                :show-action-column="false"
              >
                <template #head>
                  <h2>Emprunts</h2>
                </template>

                <template #cell(status)="{ item }">
                  <loan-status :show-self-service="false" :item="item"></loan-status>
                </template>
              </paginated-table>
            </div>

            <div v-if="!!item.id" class="form__section">
              <paginated-table
                id="admin-user-loanables-table"
                endpoint="loanables"
                :columns="loanableColumns"
                :fetch-params="{ owner_user_id: item.id, for: 'admin' }"
                label="Véhicules"
                :show-action-column="false"
                row-link
                @row-selected="loanableRowSelected"
              />
            </div>

            <div v-if="!!item.id" id="communities" class="form__section">
              <community-users-list
                id="admin-user-communities-table"
                ref="communityTable"
                :fetch-params="{ 'user.id': item.id, for: 'admin' }"
                :user-id="item.id"
                :hide-fields="['user_id', 'user.full_name', 'updated_at', 'user.phone']"
                class="mb-3"
                label="communautés"
              />

              <forms-validated-input
                v-if="canEditUser"
                type="relation"
                name="community"
                label="Ajouter une communauté"
                :value="null"
                reset-after-select
                :query="{
                  slug: 'communities',
                  value: 'id',
                  text: 'name',
                  params: {
                    '!users.id': item.id,
                    for: 'admin',
                  },
                }"
                @relation="addCommunity"
              />
            </div>

            <div v-if="!!item.id && canSeeUserBills" class="form__section">
              <paginated-table
                id="admin-user-invoices-table"
                endpoint="invoices"
                :fetch-params="{ user_id: item.id }"
                :columns="invoiceColumns"
                :show-generate-csv="false"
                :show-column-selection="false"
                :show-action-column="false"
                row-link
              >
                <template #head>
                  <h2>Compte</h2>
                  <p><strong>Balance:</strong> {{ item.balance | currency }}</p>
                </template>

                <template #head-buttons>
                  <icon-button role="add" :to="`/admin/invoices/new?user_id=${item.id}`">
                    Ajouter une facture
                  </icon-button>
                </template>
                <template #cell(total)="{ item }">
                  {{ getSignedAmount(item.type, item.total) | currency }}
                </template>
                <template #cell(total_with_taxes)="{ item }">
                  {{ getSignedAmount(item.type, item.total_with_taxes) | currency }}
                </template>
              </paginated-table>
            </div>

            <div v-if="!!item.id && canSeeUserBills" class="form__section">
              <paginated-table
                endpoint="payment_methods"
                :columns="paymentMethodColumns"
                :extra-data="['credit_card_type', 'four_last_digits', 'external_id']"
                :fetch-params="{ user_id: item.id }"
                :show-generate-csv="false"
                :show-action-column="false"
                :show-column-selection="false"
              >
                <template #head>
                  <h2>Modes de paiement</h2>
                </template>

                <template #cell(type)="{ item }">
                  {{ $t(`payment_methods.types.${item.type}`) | capitalize }}
                </template>

                <template #cell(informations)="{ item }">
                  {{ item.credit_card_type }} se terminant par {{ item.four_last_digits }} ({{
                    item.external_id
                  }})
                </template>
              </paginated-table>
            </div>

            <form-buttons v-if="canEditUser" :changed="changed" :saving="loading" @reset="reset" />
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </div>
  <layout-loading v-else />
</template>

<script>
import CommunityUsersList from "@/components/Community/CommunityUsersList.vue";
import FormsBuilder from "@/components/Forms/Builder.vue";
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import LoanStatus from "@/components/Loan/Status/LoanStatusBadge.vue";
import FormButtons from "@/components/shared/FormButtons.vue";
import IconButton from "@/components/shared/IconButton.vue";
import PaginatedTable from "@/components/shared/PaginatedTable.vue";
import { Column, Filter } from "@/components/shared/PaginatedTableColumns";
import UserPasswordForm from "@/components/User/PasswordForm.vue";

import { filters } from "@/helpers";
import { loanableLink, loanBorrowerLink, loanLoanableLink, userLink } from "@/helpers/links";
import {
  canAssignFleet,
  canChangeUserPassword,
  canEditDriversProfile,
  canEditUser,
  canManageGlobalAdmins,
  canSeeDeletedUser,
  canSeeUserBills,
  isGlobalAdmin,
  userIsSame,
} from "@/helpers/permissions/users";

import locales from "@/locales";

import DataRouteGuards from "@/mixins/DataRouteGuards";
import FormMixin from "@/mixins/FormMixin";
import dayjs from "dayjs";

const { capitalize } = filters;

export default {
  name: "AdminUser",
  components: {
    IconButton,
    FormButtons,
    LoanStatus,
    CommunityUsersList,
    PaginatedTable,
    FormsBuilder,
    FormsValidatedInput,
    UserPasswordForm,
  },
  mixins: [DataRouteGuards, FormMixin],
  data() {
    const user = this.$store.state.user;
    return {
      invoiceColumns: [
        new Column("created_at", "Date", "date"),
        new Column("items_count", "Nb d'items"),
        new Column("total", "Total", "currency"),
        new Column("total_with_taxes", "Total avec taxes", "currency"),
      ],
      loanablesSelected: [],
      loanableColumns: [
        new Column("id", "ID", "id"),
        new Column("name", "Nom", "text", {
          urlFct: loanableLink,
        }),
        Column.withSelect("type", "Type", [
          { value: null, label: "Tous" },
          { value: "car", label: "Auto" },
          { value: "trailer", label: "Remorque" },
          { value: "bike", label: "Vélo" },
        ]),
      ],
      loansExtraFilters: [
        new Filter("incidents.status", "Incidents", "select", [
          { value: null, label: "Tous" },
          { value: "in_process", label: "En cours" },
          { value: "completed", label: "Résolu" },
          { value: "canceled", label: "Annulé" },
        ]),
        new Filter("extension_requested", "Demande de prolongation", "select", [
          { value: null, label: "Tous" },
          { value: true, label: "En cours" },
          { value: false, label: "Sans demande" },
        ]),
      ],
      loanColumns: [
        new Column("id", "ID", "id"),
        new Column("departure_at", "Départ", "date", {
          formatter: (value, key, item) =>
            dayjs.atTz(value, item.loanable.timezone).format("D MMMM YYYY HH:mm"),
        }),
        new Column("actual_return_at", "Retour", "date", {
          showByDefault: false,
          formatter: (value, key, item) =>
            dayjs.atTz(value, item.loanable.timezone).format("D MMMM YYYY HH:mm"),
        }),
        Column.withRelation(
          "loanable.name",
          "Véhicule",
          {
            relation: "loanables",
            label: "name",
            field: "id",
            params: { for: "admin", with_deleted: true },
          },
          "loanable.id",
          {
            urlFct: loanLoanableLink,
          }
        ),
        Column.withRelation(
          "borrower_user.full_name",
          "Emprunteur",
          {
            relation: "users",
            label: "full_name",
            field: "id",
            params: { for: "admin", with_deleted: canSeeDeletedUser(user) },
          },
          "borrower_user.id",
          {
            urlFct: loanBorrowerLink,
          }
        ),
        Column.withRelation(
          "loanable.owner_user.full_name",
          "Propriétaire",
          {
            relation: "users",
            label: "full_name",
            field: "id",
            params: { for: "admin", with_deleted: canSeeDeletedUser(user) },
          },
          "loanable.owner_user_id",
          {
            urlFct: (loan) => userLink(loan.loanable.owner_user),
            sortable: false,
          }
        ),

        Column.withSelect("status", "Statut", [
          { value: null, label: "Tous" },
          { value: "requested", label: this.$t("loans.statuses.requested.label") },
          { value: "accepted", label: this.$t("loans.statuses.accepted.label") },
          { value: "confirmed", label: this.$t("loans.statuses.confirmed.label") },
          { value: "ongoing", label: this.$t("loans.statuses.ongoing.label") },
          { value: "ended", label: this.$t("loans.statuses.ended.label") },
          { value: "validated", label: this.$t("loans.statuses.validated.label") },
          { value: "completed", label: this.$t("loans.statuses.completed.label") },
          { value: "rejected", label: this.$t("loans.statuses.rejected.label") },
          { value: "canceled", label: this.$t("loans.statuses.canceled.label") },
        ]),
        new Column("duration_in_minutes", "Durée", "number", { showByDefault: false }),
        new Column("calendar_days", "Nombre de jours", "number", { showByDefault: false }),
        Column.withSelect(
          "loanable.type",
          "Type de véhicule",
          [
            { value: null, label: "Tous" },
            { value: "car", label: "auto" },
            { value: "trailer", label: "Remorque" },
            { value: "bike", label: "vélo" },
          ],
          { showByDefault: false }
        ),
        Column.withSelect(
          "is_self_service",
          capitalize(this.$t("loans.self_service.is_self_service")),
          [
            { value: null, label: capitalize(this.$t("loans.self_service.all")) },
            { value: true, label: capitalize(this.$t("loans.self_service.is_self_service")) },
            { value: false, label: capitalize(this.$t("loans.self_service.on_demand")) },
          ],
          { showByDefault: false }
        ),

        Column.withSelect(
          "alternative_to",
          "Mode de transport remplacé",
          [
            { value: null, label: "Tous" },
            { value: "delivery", label: "Livraison", variant: "success" },
            { value: "car", label: "Auto", variant: "success" },
            { value: "public_transit", label: "Transport en commun", variant: "success" },
            { value: "bike", label: "Vélo", variant: "primary" },
            { value: "walking", label: "Marche", variant: "warning" },
            { value: "other", label: "Autre", variant: "secondary" },
          ],
          { showByDefault: false }
        ),
      ],
      paymentMethodColumns: [
        Column.withoutFilter("id", "ID", "id", { sortable: false }),
        Column.withoutFilter("name", "Nom", "text", { sortable: false }),
        Column.withoutFilter("informations", "Informations", "text", { sortable: false }),
      ],
    };
  },
  computed: {
    borrowerStatus() {
      if (!this.item.borrower) {
        return "L'utilisateur n'a pas encore commencé à remplir son dossier de conduite";
      } else if (!this.item.borrower.approved) {
        return "Non approuvé";
      }
      return this.item.borrower.suspended ? "Suspendu" : "Approuvé";
    },
    canAssignFleet() {
      return canAssignFleet(this.loggedInUser);
    },
    canChangeUserPassword() {
      return canChangeUserPassword(this.loggedInUser, this.item);
    },
    canEditUser() {
      return canEditUser(this.loggedInUser, this.item);
    },
    canEditDriversProfile() {
      return canEditDriversProfile(this.loggedInUser, this.item);
    },
    canManageGlobalAdmins() {
      return canManageGlobalAdmins(this.loggedInUser);
    },
    loggedInUser() {
      return this.$store.state.user;
    },
    loggedInUserIsMe() {
      return userIsSame(this.loggedInUser, this.item);
    },
    canSeeUserBills() {
      return canSeeUserBills(this.loggedInUser);
    },
    isGlobalAdmin() {
      return isGlobalAdmin(this.loggedInUser);
    },
  },
  methods: {
    async addCommunity(community) {
      if (!community) {
        return;
      }

      await this.$store.dispatch("communities/addUser", {
        userId: this.item.id,
        communityId: community.id,
      });
      this.$refs.communityTable.setFilters({ community_id: community.id });
    },
    afterSubmit() {
      this.$refs.passwordForm.reset();
    },
    async approveBorrower() {
      await this.$store.dispatch(`${this.slug}/approveBorrower`, this.item.id);
    },
    async checkInvalidThenSubmit(passes) {
      await passes(this.submit);

      const invalidItems = document.getElementsByClassName("is-invalid");
      if (invalidItems.length > 0) {
        invalidItems[0].scrollIntoView({
          behavior: "smooth",
        });
      }
    },
    loanableRowSelected(rows) {
      this.loanablesSelected = rows;
    },
    resetPasswordFormAndShowModal() {
      this.$refs.passwordForm.reset();
      this.$bvModal.show("password-change-modal");
    },
    async suspendBorrower() {
      await this.$store.dispatch(`${this.slug}/suspendBorrower`, this.item.id);
    },
    async unsuspendBorrower() {
      await this.$store.dispatch(`${this.slug}/unsuspendBorrower`, this.item.id);
    },
    async resetBorrower() {
      await this.$store.dispatch(`${this.slug}/resetBorrower`, this.item.id);
    },
    getSignedAmount(type, amount) {
      if (type === "debit") return -amount;

      return amount;
    },
    mandate() {
      this.$store.dispatch("account/mandate", { mandatedUserId: this.item.id });
    },
  },
  i18n: {
    messages: {
      en: {
        loanableTypes: locales.en.loanables.fields.types,
        ...locales.en.users,
        ...locales.en.forms,
        loans: locales.en.loans,
      },
      fr: {
        loanableTypes: locales.fr.loanables.fields.types,
        ...locales.fr.users,
        ...locales.fr.forms,
        loans: locales.fr.loans,
      },
    },
  },
};
</script>

<style lang="scss">
.borrower-button-list {
  align-items: flex-start;
}
</style>
