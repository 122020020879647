<script>
import DropdownSelect from "@/components/DropdownSelect.vue";
import RelationInput from "@/components/Forms/RelationInput.vue";
import IncidentTable from "@/components/Incident/IncidentTable.vue";
import LoanStatus from "@/components/Loan/Status/LoanStatusBadge.vue";
import LoanableCalendar from "@/components/Loanable/Calendar.vue";
import UserRoleList from "@/components/Loanable/UserRoleList.vue";
import IconButton from "@/components/shared/IconButton.vue";
import LazyLoad from "@/components/shared/Lazy.vue";
import PaginatedTable from "@/components/shared/PaginatedTable.vue";
import { Column } from "@/components/shared/PaginatedTableColumns";
import SafeImage from "@/components/shared/SafeImage.vue";
import { capitalize } from "@/helpers/filters";
import { hasSomeLibraryRole } from "@/helpers/permissions/loanables";
import { isGlobalAdmin } from "@/helpers/permissions/users";
import locales from "@/locales";

import { get } from "@/requests/server";
import LibraryLoanableOverview from "@/views/profile/LibraryLoanableOverview.vue";
import dayjs from "dayjs";
import Vue from "vue";

export default Vue.extend({
  name: "LibraryOverview",
  components: {
    RelationInput,
    DropdownSelect,
    LibraryLoanableOverview,
    LazyLoad,
    UserRoleList,
    SafeImage,
    IconButton,
    IncidentTable,
    LoanableCalendar,
    LoanStatus,
    PaginatedTable,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentView: this.$route.query.view || "month",
      currentDate: this.$route.query.date || "",
      loaded: false,
      loading: false,
      loadingAvailabilities: false,
      library: null,
      tab: this.$route.query.tab || 0,
      calendarLoading: false,
      colorBy: "loanable",
      colorByOptions: [
        { label: "Véhicule", value: "loanable" },
        { label: "Statut", value: "status" },
      ],
      calendarLoanableFilter: null,
      nextLoanableBucket: 0,
      loanableIdBuckets: {},
      events: [],
      loanColumns: [
        new Column("id", "ID", "id", { showByDefault: false }),
        new Column("departure_at", "Départ", "date", {
          formatter: (value, key, item) =>
            dayjs.atTz(value, item.loanable.timezone).format("D MMMM YYYY HH:mm"),
        }),
        new Column("actual_return_at", "Retour", "date", {
          showByDefault: false,
          formatter: (value, key, item) =>
            dayjs.atTz(value, item.loanable.timezone).format("D MMMM YYYY HH:mm"),
        }),
        Column.withRelation(
          "borrower_user.full_name",
          "Emprunteur",
          {
            relation: "users",
            label: "full_name",
            field: "id",
            params: { for: "profile" },
          },
          "borrower_user.id"
        ),
        Column.withRelation(
          "loanable.name",
          "Véhicule",
          {
            relation: "loanables",
            label: "name",
            field: "id",
            params: { for: "profile", library_id: this.id },
          },
          "loanable_id"
        ),
        Column.withRelation(
          "community.name",
          "Communauté",
          { relation: "communities", label: "name", field: "id", params: { for: "admin" } },
          "community_id",
          { showByDefault: false }
        ),
        Column.withSelect("status", "Statut", [
          { value: null, label: "Tous" },
          { value: "requested", label: this.$t("loans.statuses.requested.label") },
          { value: "accepted", label: this.$t("loans.statuses.accepted.label") },
          { value: "confirmed", label: this.$t("loans.statuses.confirmed.label") },
          { value: "ongoing", label: this.$t("loans.statuses.ongoing.label") },
          { value: "ended", label: this.$t("loans.statuses.ended.label") },
          { value: "validated", label: this.$t("loans.statuses.validated.label") },
          { value: "completed", label: this.$t("loans.statuses.completed.label") },
          { value: "rejected", label: this.$t("loans.statuses.rejected.label") },
          { value: "canceled", label: this.$t("loans.statuses.canceled.label") },
        ]),
        new Column("duration_in_minutes", "Durée (minutes)", "number", {
          showByDefault: false,
        }),

        Column.withSelect(
          "is_self_service",
          capitalize(this.$t("loans.self_service.is_self_service")),
          [
            { value: null, label: capitalize(this.$t("loans.self_service.all")) },
            { value: true, label: capitalize(this.$t("loans.self_service.is_self_service")) },
            { value: false, label: capitalize(this.$t("loans.self_service.on_demand")) },
          ],
          { showByDefault: false }
        ),
      ],
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    isAdmin() {
      return isGlobalAdmin(this.user);
    },
    canEditLibrary() {
      return this.isAdmin || hasSomeLibraryRole(this.user, this.library, ["owner", "manager"]);
    },
  },
  beforeMount() {
    this.load();
  },
  methods: {
    async load() {
      this.loading = true;
      const { data } = await get(`/libraries/${this.id}`, {
        cleanupCallback: () => (this.loading = false),
        notifications: { action: "chargement de la flotte" },
      });
      this.library = data;
      this.loaded = true;
    },

    async calendarChanged({ view, startDate }) {
      let date = dayjs(startDate).format("YYYY-MM-DD");
      if (this.$route.query.date !== date || this.$route.query.view !== view) {
        this.currentDate = date;
        this.currentView = view;
        this.$router.replace({ query: { ...this.$route.query, date, view } });
      }
      // Load 1 extra month/week/day on both side for smoother navigation
      let start = dayjs(startDate).subtract(1, view);
      let end = dayjs(start).add(2, view);

      this.loadingAvailabilities = true;
      const response = await get(`/libraries/${this.id}/events`, {
        axiosRequestConfig: {
          params: {
            start: start.format("YYYY-MM-DD HH:mm:ss"),
            end: end.format("YYYY-MM-DD HH:mm:ss"),
            responseMode: "available",
            loanable_id: this.calendarLoanableFilter,
          },
        },
        notifications: { action: "calcul des disponibilités des véhicules de la flotte" },
        requestOptions: { cancelId: `libraries/${this.id}/availability` },
        cleanupCallback: () => (this.loadingAvailabilities = false),
      });

      this.events = response.data.map((e) => {
        let title = "Emprunt";
        if (e.data.loanable_name && e.data.borrower_name) {
          title = e.data.loanable_name + " • " + e.data.borrower_name;
        }

        let loanableIdBucket = this.nextLoanableBucket;
        if (this.loanableIdBuckets[e.data.loanable_id] !== undefined) {
          loanableIdBucket = this.loanableIdBuckets[e.data.loanable_id];
        } else {
          this.loanableIdBuckets[e.data.loanable_id] = this.nextLoanableBucket;
          this.nextLoanableBucket = (this.nextLoanableBucket + 1) % 12;
        }

        return {
          ...e,
          title,
          loan_id: e.data.loan_id,
          class: [`loanable_id_bucket_${loanableIdBucket}`, `loan_id_${e.data.loan_id}`],
        };
      });
    },
    updateTabParam(tab) {
      if (this.$route.query.tab != tab) {
        let newQuery = { tab };
        if (tab == 1) {
          newQuery.view = this.currentView;
          newQuery.date = this.currentDate;
        }

        this.$router.replace({ query: newQuery });
      }
    },
    changeFilteredLoanable(loanableId) {
      this.calendarLoanableFilter = loanableId;
      this.calendarChanged({ view: this.currentView, startDate: this.currentDate });
    },
  },

  i18n: {
    messages: {
      en: {
        loanable: locales.en.loanables,
      },
      fr: {
        loans: locales.fr.loans,
        loanable: locales.fr.loanables,
      },
    },
  },
});
</script>

<template>
  <layout-page name="library" fluid="lg" class="library-page">
    <layout-loading v-if="loading" />
    <div v-else-if="!loaded" class="text-center">
      <p>Erreur de chargement de la flotte. Il se peut que vous n'y ayez pas accès.</p>
      <icon-button variant="primary" icon="arrow-clockwise" :onclick="load">Réessayer</icon-button>
    </div>
    <div v-else>
      <div class="library-overview">
        <div class="library-overview-sidebar">
          <b-card no-body>
            <safe-image
              v-if="library"
              class="card-img-top"
              :image="library.avatar"
              :preferred-sizes="['library', 'original']"
              aspect-ratio="1 / 1"
            />
            <b-card-body>
              <div class="library-sidebar-header">
                <safe-image
                  v-if="library"
                  class="library-thumbnail"
                  :image="library.avatar"
                  :preferred-sizes="['thumbnail', 'library', 'original']"
                  aspect-ratio="1 / 1"
                />

                <div>
                  <b-card-title>{{ library.name }}</b-card-title>

                  <icon-button
                    v-if="canEditLibrary"
                    size="sm"
                    role="edit"
                    :to="isAdmin ? `/admin/libraries/${id}` : `/profile/libraries/${id}`"
                    data-dusk="edit-library-button"
                    >Modifier
                  </icon-button>
                </div>
              </div>

              <hr />

              <dl class="sidebar-list mb-0">
                <dt>{{ $t("loanable.resource_people") | capitalize }}</dt>
                <dd>
                  <user-role-list
                    :user-roles="library.user_roles"
                    :show-contacts-only="!canEditLibrary"
                  />
                </dd>
              </dl>
            </b-card-body>
          </b-card>
        </div>
        <div class="library-overview-content">
          <b-tabs v-model="tab" pills @input="updateTabParam">
            <b-tab title="Vue d'ensemble">
              <lazy-load>
                <library-loanable-overview :library-id="id" />
                <incident-table :library-id="id"></incident-table>
              </lazy-load>
            </b-tab>
            <b-tab title="Calendrier">
              <lazy-load>
                <div class="library-overview-tab-settings">
                  <dropdown-select
                    :value="colorBy"
                    :options="colorByOptions"
                    label="Colorier par"
                    @select="colorBy = $event"
                  ></dropdown-select>
                  <relation-input
                    ref="relationInput"
                    class="loanable-filter-input"
                    placeholder="Filtrer par véhicule"
                    :min-search-length="0"
                    :query="{
                      slug: `loanables`,
                      value: 'id',
                      text: 'name',
                      params: {
                        library_id: id,
                      },
                    }"
                    name="loanable-id-filter"
                    :value="calendarLoanableFilter"
                    @click.native.stop.prevent
                    @input="changeFilteredLoanable"
                  />
                </div>
                <div
                  class="styled-calendar-card"
                  :class="{ 'colorize-events-by-loanable-id': colorBy === 'loanable' }"
                >
                  <loanable-calendar
                    :default-view="currentView"
                    :initial-date="currentDate"
                    :events="events"
                    month-day-detail
                    :loading="loadingAvailabilities"
                    @click.native.stop.prevent
                    @ready="calendarChanged"
                    @view-change="calendarChanged"
                  ></loanable-calendar>
                </div>
              </lazy-load>
            </b-tab>
            <b-tab title="Liste d'emprunts">
              <paginated-table
                id="profile-loanables-loans-table"
                ref="table"
                class="loans-table"
                :columns="loanColumns"
                :extra-data="[
                  'incidents.status',
                  'extension_duration_in_minutes',
                  'community.deleted_at',
                  'loanable.can_edit',
                  'loanable.timezone',
                  'loanable.merged_user_roles.*',
                  'loanable.merged_user_roles.user.*',
                ]"
                endpoint="loans"
                :fetch-params="{ for: 'profile', for_library: id }"
                sync-filters-with-url
                :show-action-column="false"
                @row-clicked="(item) => $router.push(`/loans/${item.id}`)"
              >
                <template #cell(status)="{ item }">
                  <loan-status :item="item"></loan-status>
                </template>
              </paginated-table>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </layout-page>
</template>

<style lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";
.colorize-events-by-loanable-id {
  .vuecal__event {
    $numBuckets: 12;
    @for $i from 0 through $numBuckets {
      $step: floor($numBuckets / 4);
      &.loanable_id_bucket_#{$i} {
        $cyclesCount: floor(($i * $step) / $numBuckets);
        $rotation: $step * $i - $numBuckets * $cyclesCount + $cyclesCount;

        $fg: adjust-hue($completedFg, $rotation * 360 / $numBuckets);
        $bg: adjust-hue($completedBg, $rotation * 360 / $numBuckets);

        &.hovered {
          background: $fg;
          color: $bg;
          border-color: $fg;
        }

        transition: all 0.1s;
        color: $fg;
        background: $bg;
        border: 1px solid mix($bg, $fg, 70%);
      }
    }
  }
}

.page.library {
  .page__content {
    padding-top: 45px;
    padding-bottom: 45px;
  }
}

.library-overview {
  display: flex;
  gap: 2rem;
  flex-direction: column;

  .library-overview-content {
    flex: 1;
    .tab-content {
      margin-top: 1rem;
      .library-overview-tab-settings {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: center;
        justify-content: flex-end;
        .loanable-filter-input {
          min-width: 15rem;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .library-overview-sidebar {
      .card-img-top {
        display: none;
      }

      .library-thumbnail {
        max-width: 5rem;
      }
      .library-sidebar-header {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        justify-content: flex-start;
      }
    }
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;
    .library-overview-sidebar {
      width: 20rem;
    }
    .library-thumbnail {
      display: none;
    }
  }
}
</style>
