<script>
import rehypeExternalLinks from "rehype-external-links";
import rehypeSanitize from "rehype-sanitize";
import rehypeStringify from "rehype-stringify";
import remarkBreaks from "remark-breaks";
import remarkGfm from "remark-gfm";
import remarkParse from "remark-parse";
import remarkRehype from "remark-rehype";
import { unified } from "unified";

export default {
  name: "MarkdownContent",
  props: {
    content: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      renderedMarkdown: null,
    };
  },
  beforeMount() {
    this.render();
  },
  methods: {
    async render() {
      this.renderedMarkdown = await unified()
        .use(remarkParse)
        .use(remarkBreaks)
        .use(remarkGfm)
        .use(remarkRehype)
        .use(rehypeSanitize)
        .use(rehypeExternalLinks, {
          rel: ["nofollow", "noopener", "noreferrer"],
          target: "_blank",
          protocols: ["http", "https", "mailto"],
        })
        .use(rehypeStringify)
        .process(this.content);
    },
  },
};
</script>

<template>
  <!--  eslint-disable-next-line -->
  <div class="markdown-content" v-html="renderedMarkdown"></div>
</template>

<style lang="scss">
.markdown-content {
  p {
    line-height: 1.5;
  }
  table {
    width: 100%;
    margin-bottom: 1rem;
    th,
    td {
      padding: 0.25rem 0.5rem;
    }
    thead {
      text-align: left;
    }
    th {
      text-align: revert-layer;
    }
  }

  img {
    max-width: 200px;
  }

  blockquote {
    margin-left: 0.5rem;
    padding: 0.25rem 0 0.25rem 0.5rem;
    border-left: 3px solid $light-grey;
  }
  *:last-child {
    margin-bottom: 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0 !important;
    margin-bottom: 0.5rem !important;
    line-height: 1.5 !important;
  }
  h1 {
    font-size: 1.5rem !important;
    font-weight: 400 !important;
  }
  h2 {
    font-size: 1.25rem !important;
    font-weight: 600 !important;
  }
  h3 {
    font-size: 1.25rem !important;
    font-weight: 500 !important;
  }
  h4 {
    font-size: 1rem !important;
    font-weight: bold !important;
  }
  h5,
  h6 {
    font-size: 1rem !important;
  }
}
</style>
