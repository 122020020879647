<template>
  <validation-provider
    v-slot="validationContext"
    class="forms-validated-input"
    :mode="mode"
    :name="label"
    :rules="rulesOrNothing"
  >
    <b-form-group
      v-b-tooltip.hover
      :label="type !== 'checkbox' ? label : ''"
      :label-for="name"
      :label-cols="inline"
      :description="type === 'image' || type === 'images' ? null : description"
      :title="disabled ? disabledTooltip : ''"
      :label-sr-only="hideLabel"
    >
      <template v-if="$slots.description" #description>
        <slot name="description" />
      </template>

      <template v-if="$slots.label && type !== 'checkbox'" #label>
        <slot name="label" />
      </template>

      <b-form-select
        v-if="type === 'select'"
        :id="name"
        :name="name"
        :state="getValidationState(validationContext)"
        :options="options"
        :disabled="disabled"
        :value="value"
        @change="emitInput"
      />

      <!-- For suggestions, we user the select.search data as the value we emit, as this is
            the field edited by the user. We insure the internal value and search field are
            in sync. -->
      <v-select
        v-else-if="type === 'suggest'"
        ref="select"
        class="validated-input-select"
        :class="getValidationClass(validationContext)"
        :options="options"
        :value="value"
        taggable
        :clearable="false"
        :clear-search-on-blur="() => false"
        :clear-search-on-select="false"
        :dropdown-should-open="
          ({ open, search, filteredOptions }) =>
            open && search && search.length >= 1 && filteredOptions.length > 1
        "
        @option:selecting="onSelecting"
        @search="emitInput"
      >
        <!-- This is needed to select the entered text on blur-->
        <template #search="{ attributes, events }">
          <input
            ref="selectInput"
            class="vs__search"
            v-bind="attributes"
            @blur="() => onBlur(validationContext)"
            v-on="events"
          />
        </template>
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes">&nbsp;</span>
        </template>
        <template #list-header="">
          <div class="text-secondary text-center font-italic">Suggestions:</div>
        </template>
      </v-select>
      <multiple-email-input
        v-else-if="type === 'multiple-emails'"
        :class="getValidationClass(validationContext)"
        :value="value"
        :placeholder="placeholder"
        @input="(value) => emitAndValidate(value, validationContext)"
      />

      <b-form-checkbox
        v-else-if="type === 'checkbox'"
        :id="name"
        :name="name"
        :value="true"
        :disabled="disabled"
        :unchecked-value="false"
        :state="getValidationState(validationContext)"
        :checked="value"
        @change="emitInput"
      >
        <slot name="label">
          <span>{{ label }}</span>
        </slot>
      </b-form-checkbox>
      <b-form-checkbox-group
        v-else-if="type === 'checkboxes'"
        :id="name"
        :switches="switches"
        :stacked="stacked"
        :name="name"
        :disabled="disabled"
        :options="options"
        :state="getValidationState(validationContext)"
        :checked="value"
        @change="emitInput"
      />
      <b-form-textarea
        v-else-if="type === 'textarea'"
        :id="name"
        :name="name"
        :description="description"
        :placeholder="placeholder"
        :disabled="disabled"
        :rows="rows"
        :max-rows="maxRows"
        :state="getValidationState(validationContext)"
        :value="value"
        @input="emitInput"
        @blur="$emit('blur')"
      />
      <!-- Lazy loading the editor, so that initial height computes accurately
      (which it doesn't if element is hidden) -->
      <lazy-load v-else-if="type === 'markdown'">
        <markdown-editor
          :id="name"
          :placeholder="placeholder"
          :disabled="disabled"
          :class="getValidationClass(validationContext)"
          :value="value"
          @input="emitInput"
        />
      </lazy-load>
      <forms-map-input
        v-else-if="type === 'point'"
        :bounded="bounded"
        :center="center"
        :disabled="disabled"
        :state="getValidationState(validationContext)"
        :polygons="polygons"
        :value="value"
        @input="emitInput"
        @blur="$emit('blur')"
      />
      <forms-date-picker
        v-else-if="type === 'date'"
        :disabled-dates-fct="disabledDatesFct"
        :disabled="disabled"
        :state="getValidationState(validationContext)"
        :value="value"
        :open-date="openDate"
        @input="emitInput"
        @blur="$emit('blur')"
      />
      <forms-date-time-picker
        v-else-if="type === 'datetime'"
        :name="name"
        :disabled-dates-fct="disabledDatesFct"
        :disabled-times-fct="disabledTimesFct"
        :disabled="disabled"
        :clearable="clearable"
        :value="value"
        :timezone="timezone"
        @input="emitInput"
        @blur="$emit('blur')"
      >
        <template #footer>
          <slot name="footer" />
        </template>
      </forms-date-time-picker>
      <b-form-input
        v-else-if="type === 'password'"
        :id="name"
        :name="name"
        type="password"
        :placeholder="placeholder"
        :disabled="disabled"
        :state="getValidationState(validationContext)"
        :value="value"
        @input="emitInput"
        @blur="$emit('blur')"
      />
      <forms-files-uploader
        v-else-if="type === 'files'"
        :id="name"
        no-label
        :label="label"
        :name="name"
        :field="name"
        :placeholder="placeholder"
        :disabled="disabled"
        :value="value"
        @input="emitInput"
        @blur="$emit('blur')"
      />
      <forms-file-uploader
        v-else-if="type === 'file'"
        :id="name"
        :name="name"
        :field="name"
        :placeholder="placeholder"
        :disabled="disabled"
        :state="getValidationState(validationContext)"
        :value="value"
        @input="emitInput"
        @blur="$emit('blur')"
      />
      <forms-image-uploader
        v-else-if="type === 'image'"
        :id="name"
        :name="name"
        :field="name"
        :description="description"
        :placeholder="placeholder"
        :disabled="disabled"
        :state="getValidationState(validationContext)"
        :value="value"
        :preview-aspect-ratio="previewAspectRatio"
        :alt="`Aperçu: ${label}`"
        @input="emitInput"
        @blur="$emit('blur')"
      />
      <forms-images-uploader
        v-else-if="type === 'images'"
        :id="name"
        :name="name"
        :field="name"
        :description="description"
        :placeholder="placeholder"
        :disabled="disabled"
        :state="getValidationState(validationContext)"
        :value="value"
        :preview-aspect-ratio="previewAspectRatio"
        :alt="`Aperçu: ${label}`"
        @input="emitInput"
        @blur="$emit('blur')"
      />
      <forms-relation-input
        v-else-if="type === 'relation'"
        :id="name"
        :name="name"
        :query="query"
        :placeholder="placeholder"
        :disabled="disabled"
        :class="getValidationClass(validationContext)"
        :object-value="objectValue"
        :reset-after-select="resetAfterSelect"
        :value="value"
        @input="emitRelationChange($event, validationContext)"
        @search-object-updated="$emit('search-object-updated', $event)"
      />
      <b-form-input
        v-else-if="type === 'currency'"
        :id="name"
        ref="currencyInput"
        v-currency="{
          locale: 'fr',
          currency: { suffix: ' $' },
          valueRange: { min, max },
          allowNegative: min < 0,
        }"
        :name="name"
        :disabled="disabled"
        :placeholder="placeholder"
        :state="getValidationState(validationContext)"
        :value="currencyValue"
        @blur="$emit('blur')"
        @change="
          () => {
            const numberValue = getValue($refs.currencyInput);
            if (value !== numberValue) {
              $emit('input', numberValue);
            }
          }
        "
      >
      </b-form-input>
      <b-form-input
        v-else-if="type === 'number'"
        :id="name"
        :name="name"
        number
        type="number"
        :min="min"
        :max="max"
        :step="step"
        :placeholder="placeholder"
        :disabled="disabled"
        :state="getValidationState(validationContext)"
        :formatter="formatter"
        :value="value"
        @input="emitInput"
        @blur="$emit('blur')"
      />
      <duration-input
        v-else-if="type === 'duration'"
        :id="name"
        :name="name"
        :min="min"
        :max="max"
        :disabled="disabled"
        :state="getValidationState(validationContext)"
        :value="value"
        @input="emitInput"
        @blur="$emit('blur')"
      />
      <b-form-input
        v-else-if="!!mask"
        :id="name"
        v-mask="mask"
        :name="name"
        type="text"
        masked
        :placeholder="placeholder"
        :disabled="disabled"
        :state="getValidationState(validationContext)"
        :formatter="formatter"
        :value="value"
        @input="emitInput"
        @blur="$emit('blur')"
      />
      <b-form-input
        v-else
        :id="name"
        :name="name"
        type="text"
        :placeholder="placeholder"
        :disabled="disabled"
        :state="getValidationState(validationContext)"
        :value="value"
        v-bind="$attrs"
        @input="emitInput"
        @blur="$emit('blur')"
      />
      <b-form-invalid-feedback :state="getValidationState(validationContext)">
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
import FormsDatePicker from "@/components/Forms/DatePicker.vue";
import FormsDateTimePicker from "@/components/Forms/DateTimePicker.vue";
import DurationInput from "@/components/Forms/DurationInput.vue";
import FormsFilesUploader from "@/components/Forms/FilesUploader.vue";
import FormsFileUploader from "@/components/Forms/FileUploader.vue";
import FormsImagesUploader from "@/components/Forms/ImagesUploader.vue";
import FormsImageUploader from "@/components/Forms/ImageUploader.vue";
import FormsMapInput from "@/components/Forms/MapInput.vue";
import MultipleEmailInput from "@/components/Forms/MultipleEmailInput.vue";
import FormsRelationInput from "@/components/Forms/RelationInput.vue";
import LazyLoad from "@/components/shared/Lazy.vue";
import MarkdownEditor from "@/components/shared/MarkdownEditor.vue";
import { CurrencyDirective as currency, getValue } from "vue-currency-input";
import vSelect from "vue-select";

export default {
  name: "FormsValidatedInput",
  components: {
    DurationInput,
    LazyLoad,
    MarkdownEditor,
    MultipleEmailInput,
    vSelect,
    FormsDatePicker,
    FormsDateTimePicker,
    FormsFileUploader,
    FormsFilesUploader,
    FormsImageUploader,
    FormsImagesUploader,
    FormsMapInput,
    FormsRelationInput,
  },
  directives: {
    currency,
  },
  props: {
    bounded: {
      type: Boolean,
      default: false,
    },
    center: {
      type: [Object, Array],
      required: false,
      default: undefined,
    },
    description: {
      type: String,
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabledDatesFct: {
      type: Function,
      required: false,
      default: () => false,
    },
    disabledTimesFct: {
      type: Function,
      required: false,
      default: () => false,
    },
    disabledTooltip: {
      type: String,
      required: false,
      default: "",
    },
    inline: {
      type: [String],
      required: false,
      default: undefined,
    },
    label: {
      type: String,
      required: false,
      default: undefined,
    },
    mode: {
      required: false,
      type: String,
      default: "eager",
    },
    mask: {
      required: false,
      type: String,
      default: "",
    },
    max: {
      type: Number,
      required: false,
      default: Number.MAX_SAFE_INTEGER,
    },
    maxRows: {
      type: Number,
      required: false,
      default: 6,
    },
    min: {
      type: Number,
      required: false,
      default: -Number.MAX_SAFE_INTEGER,
    },
    name: {
      type: String,
      required: true,
    },
    objectValue: {
      type: Object,
      required: false,
      default: undefined,
    },
    openDate: {
      type: Date,
      required: false,
      default() {
        return new Date();
      },
    },
    options: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    polygons: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    query: {
      type: Object,
      required: false,
      default: undefined,
    },
    resetAfterSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    rows: {
      type: Number,
      required: false,
      default: 3,
    },
    rules: {
      type: Object,
      required: false,
      default() {
        return null;
      },
    },
    stacked: {
      type: Boolean,
      required: false,
      default: true,
    },
    step: {
      type: Number,
      required: false,
      default: 1,
    },
    switches: {
      type: Boolean,
      required: false,
      default: true,
    },
    type: {
      type: String,
      required: true,
    },
    value: {
      type: [Object, String, Number, Array, Boolean],
      required: false,
      default: undefined,
    },
    formatter: {
      type: Function,
      default: undefined,
    },
    previewAspectRatio: {
      type: String,
      default: undefined,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    timezone: {
      type: String,
      default: undefined,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    rulesOrNothing() {
      if (!this.rules) {
        return "";
      }

      if (this.type === "point") {
        return {
          ...this.rules,
          length: 2,
        };
      }

      return this.rules;
    },
    currencyValue() {
      let float = parseFloat(this.value);
      if (isNaN(float)) {
        return this.value;
      }

      return float.toFixed(2).replace(".", ",") + " $";
    },
  },
  mounted() {
    if (this.$refs.select) {
      this.onSelecting(this.value);
    }
  },
  methods: {
    getValue,
    onBlur(validationContext) {
      if (this.$refs.select.search.length > 0) {
        // Select the option under the highlighted option
        this.$refs.select.typeAheadSelect();
      } else {
        // Update the select.value data to be empty
        this.$refs.select.clearSelection();
      }
      // We need to do this manually since validation-provider can't find the vue-select input
      validationContext.validate();
    },
    onSelecting(val) {
      // Updating search will trigger emitInput
      this.$refs.select.search = val || "";
      // normally search being updated keeps the dropdown open, but here
      // we definitely want to close it since we're selecting
      this.$nextTick(() => (this.$refs.select.open = false));
    },
    emitAndValidate(value, validationContext) {
      this.emitInput(value);
      if (validationContext.validated) {
        // force validation since it's not always picked up when removing options by clicking x
        this.$nextTick(validationContext.validate);
      }
    },
    emitInput(value) {
      if (!this.disabled) {
        this.$emit("input", value);
      }
    },
    emitRelationChange(value) {
      this.$emit("relation", value);
    },
    getValidationState({ dirty, validated, valid = null }) {
      if (this.rulesOrNothing === "") {
        return null;
      }

      if (dirty && !validated) {
        return null;
      }

      return validated ? valid : null;
    },
    getValidationClass(state) {
      const validationState = this.getValidationState(state);
      if (validationState === null) {
        return null;
      }
      return {
        "is-valid": validationState,
        "is-invalid": !validationState,
      };
    },
  },
};
</script>

<style lang="scss">
.validated-input-select {
  &.vs--single.vs--searching:not(.vs--open):not(.vs--loading) .vs__search {
    opacity: 1;
  }
  .vs__actions {
    padding: 0;
  }

  .vs__dropdown-menu {
    width: 50%;
    min-width: min(20rem, 100%);
    background: $eggshell;
    top: 100%;

    .vs__dropdown-option--selected {
      display: none;
    }

    .vs__dropdown-option--highlight {
      background: $background-alert-informative;
      color: $dark;
    }
  }
}
</style>
