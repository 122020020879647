<template>
  <paginated-table
    id="admin-loanables-table"
    ref="table"
    endpoint="loanables"
    :fetch-params="{
      for: 'admin',
    }"
    :label="$tc('loanable', 2)"
    :columns="columns"
    :extra-data="['active_incidents', 'user_roles.role', 'user_roles.user.deleted_at']"
    :extra-filters="extraFilters"
    :show-generate-csv="false"
    sync-filters-with-url
    row-link
  >
    <template #head-buttons>
      <icon-button v-if="canCreateLoanable" to="/admin/loanables/new" role="add">
        {{ $t("create_loanable") | capitalize }}
      </icon-button>
    </template>

    <template #cell(availability_status)="{ item }">
      <availability-status :loanable="item"
    /></template>

    <template #cell(actions)="{ item }">
      <loanable-list-actions :loanable="item" @changed="$refs.table.refresh()" />
    </template>
  </paginated-table>
</template>

<script>
import LoanableListActions from "@/components/Loanable/LoanableListActions.vue";
import AvailabilityStatus from "@/components/Loanable/AvailabilityStatus.vue";
import IconButton from "@/components/shared/IconButton.vue";
import PaginatedTable from "@/components/shared/PaginatedTable.vue";
import { Column, Filter } from "@/components/shared/PaginatedTableColumns";
import { loanBorrowerLink, userLink } from "@/helpers/links";
import { capitalize } from "@/helpers/filters";
import { canCreateLoanable } from "@/helpers/permissions/loanables";
import locales from "@/locales";

export default {
  name: "AdminLoanables",
  components: {
    AvailabilityStatus,
    IconButton,
    PaginatedTable,
    LoanableListActions,
  },
  data() {
    return {
      extraFilters: [
        new Filter("sharedInCommunity", "Communauté", "relation", {
          relation: "communities",
          label: "name",
          field: "id",
          params: { for: "admin" },
        }),
        new Filter("is_deleted", "Archivé", "boolean"),
      ],

      columns: [
        new Column("id", "ID", "id"),
        new Column("name", "Nom", "text"),
        Column.withSelect("type", "Type", [
          { value: null, label: "Tous" },
          { value: "car", label: "Auto" },
          { value: "trailer", label: "Remorque" },
          { value: "bike", label: "Vélo" },
        ]),
        Column.withRelation(
          "owner_user.full_name",
          "Propriétaire",
          {
            relation: "users",
            label: "full_name",
            field: "id",
            params: { for: "admin" },
          },
          "owner_user_id",
          {
            urlFct: (loanable) => userLink(loanable.owner_user),
            sortable: false,
          }
        ),
        new Column("current_loan.id", "Emprunt en cours", "text", {
          urlFct: (loanable) => `/admin/loans/${loanable.current_loan?.id}`,
          sortable: false,
        }),
        new Column("current_loan.borrower_user.id", "ID de l'emprunteur\u2011se", "id", {
          showByDefault: false,
        }),
        new Column("current_loan.borrower_user.full_name", "Emprunteur\u2011se", "text", {
          urlFct: (loanable) => loanBorrowerLink(loanable.current_loan),
          sortable: false,
        }),
        Column.withSelect(
          "availability_status",
          "Statut d'affichage",
          [
            { value: null, label: "Tous" },
            {
              value: "no_approved_communities",
              label: "Propriétaire sans communauté",
              variant: "warning",
            },
            { value: "wrong_type", label: "Type non partagé", variant: "danger" },
            { value: "no_availabilities", label: "Aucune disponibilité", variant: "warning" },
            { value: "unpublished", label: "Non publié", variant: "secondary" },
            { value: "has_availabilities", label: "Affiché", variant: "success" },
          ],
          { sortable: false }
        ),
        new Column("deleted_at", "Date de supression", "date", {
          showByDefault: false,
        }),
        Column.withSelect(
          "sharing_mode",
          "Mode de partage",
          [
            { value: null, label: "Tous" },
            { value: "on_demand", label: capitalize(this.$t("sharing_modes.on_demand")) },
            { value: "self_service", label: capitalize(this.$t("sharing_modes.self_service")) },
            { value: "hybrid", label: capitalize(this.$t("sharing_modes.hybrid")) },
          ],
          { showByDefault: false }
        ),
      ],
    };
  },
  computed: {
    loggedInUser() {
      return this.$store.state.user;
    },
    canCreateLoanable() {
      return canCreateLoanable(this.loggedInUser);
    },
  },
  methods: { capitalize },
  i18n: {
    messages: {
      en: {
        ...locales.en.loanables,
        ...locales.en.forms,
      },
      fr: {
        ...locales.fr.loanables,
        ...locales.fr.forms,
      },
    },
  },
};
</script>

<style lang="scss"></style>
