<template>
  <div :class="`generic-avatar generic-avatar-${size}`">
    <div v-if="variant == 'cut-out'" class="generic-avatar__background"></div>
    <safe-image
      v-if="avatar && !failedFetchingImage"
      no-zoom
      class="generic-avatar__image"
      :image="avatar"
      aspect-ratio="1 / 1"
      :preferred-sizes="['avatar', 'thumbnail', 'original']"
      :alt="label"
      @failed="failedFetchingImage = true"
    />
    <div v-else-if="$slots.fallback || fallbackLabel" class="generic-avatar__initials">
      <slot name="fallback">{{ fallbackLabel }}</slot>
    </div>
    <div v-else class="generic-avatar__icon">
      <svg-profile />
    </div>
  </div>
</template>

<script>
import Profile from "@/assets/svg/profile.svg";
import SafeImage from "@/components/shared/SafeImage.vue";
import { capitalize } from "@/helpers/filters";

export default {
  name: "GenericAvatar",
  components: {
    SafeImage,
    "svg-profile": Profile,
  },
  props: {
    avatar: {
      type: Object,
      required: false,
      default: () => null,
    },
    variant: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      default: undefined,
    },
    size: {
      type: String,
      default: "md",
    },
  },
  data() {
    return {
      failedFetchingImage: false,
    };
  },
  computed: {
    fallbackLabel() {
      if (!this.label) {
        return;
      }

      // We estimate initials:
      // First initial: first letter capitalized
      // Second initial: last capital letter following space
      //    or last letter following space, capitalized
      //    or nothing

      const label = capitalize(this.label);
      let firstLetter = label[0];

      let capitalLettersFollowingSpace = [...label.matchAll(/\s+([\p{Lu}\p{Lt}])/gu)];
      if (capitalLettersFollowingSpace.length > 0) {
        return (
          firstLetter + capitalLettersFollowingSpace[capitalLettersFollowingSpace.length - 1][1]
        );
      }

      let lettersFollowingSpace = [...label.matchAll(/\s+(\w)/gu)];

      if (lettersFollowingSpace.length > 0) {
        return firstLetter + lettersFollowingSpace[lettersFollowingSpace.length - 1][1];
      }

      return firstLetter;
    },
  },
};
</script>

<style lang="scss">
.generic-avatar {
  position: relative;

  &.generic-avatar-md {
    // Default size.
    width: 3rem;
    height: 3rem;

    .generic-avatar__background {
      top: -0.125rem;
      left: -0.125rem;

      height: 3.25rem;
      width: 3.25rem;
    }
  }

  &.generic-avatar-sm {
    // Default size.
    width: 2.5rem;
    height: 2.5rem;

    .generic-avatar__background {
      top: -0.125rem;
      left: -0.125rem;

      height: 2.75rem;
      width: 2.75rem;
    }
  }

  font-weight: 700;
  color: $white;

  // Background is just a bit wider than the avatar.
  &__background {
    position: absolute;
    background-color: $white;
    // That makes a rounded div no matter the size.
    border-radius: 99999px;
  }

  &__image,
  &__initials,
  &__icon {
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;

    // That makes a rounded div no matter the size.
    border-radius: 99999px;

    font-size: 1.25rem;
  }

  &__initials,
  &__icon {
    // Don't add to image as it will create an inelegant rim.
    background-color: $locomotion-green;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image {
    object-fit: cover;
    object-position: top;
  }
}
</style>
